import { css, cx } from '@emotion/css';
import { Colors } from '../styles/colors';
import { button1Style } from '../styles/text';

//TODO: fix font styles
interface ITabSwitchProps {
  tabs: string[];
  selectedIndex: number;
  setIndex(index: number): void;
  className?: string;
}

export const TabSwitch = (props: ITabSwitchProps): JSX.Element => {
  const { tabs, selectedIndex, setIndex, className } = props;

  return (
    <div className={cx(tabSwitchStyle, className)}>
      {tabs.map((tab, i) => {
        return (
          <div
            key={i}
            className={
              selectedIndex === i
                ? cx(button1Style, activeTabStyle)
                : cx(button1Style, tabStyle)
            }
            onClick={() => setIndex(i)}
          >
            {tab}
          </div>
        );
      })}
    </div>
  );
};

const tabSwitchStyle = css`
  display: flex;
  width: 100%;
  > div:first-child {
    border-left: 2px solid ${Colors.CAPRI_BLUE};
  }
  > div:last-child {
    border-right: 2px solid ${Colors.CAPRI_BLUE};
  }
`;

const tabStyle = css`
  border-top: 2px solid ${Colors.CAPRI_BLUE};
  border-bottom: 2px solid ${Colors.CAPRI_BLUE};
  border-left: 1px solid ${Colors.CAPRI_BLUE};
  border-right: 1px solid ${Colors.CAPRI_BLUE};
  padding: 0.75rem 1rem;

  flex-grow: 1;
  flex-basis: 0;
  color: ${Colors.CAPRI_BLUE};
  background-color: ${Colors.WHITE};

  cursor: pointer;
  text-transform: capitalize;
`;

const activeTabStyle = css`
  border-top: 2px solid ${Colors.CAPRI_BLUE};
  border-bottom: 2px solid ${Colors.CAPRI_BLUE};
  border-left: 1px solid ${Colors.CAPRI_BLUE};
  border-right: 1px solid ${Colors.CAPRI_BLUE};
  padding: 0.75rem 1rem;

  flex-grow: 1;
  flex-basis: 0;
  color: ${Colors.WHITE};
  background-color: ${Colors.CAPRI_BLUE};

  cursor: pointer;
  text-transform: capitalize;
`;
