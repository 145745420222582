import { useState } from 'react';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../shared/components/Button';
import { ChevronLeft, PowerIcon, WaterIcon } from '../../shared/assets/icons';
import { css, cx } from '@emotion/css';
import { HEADER_HEIGHT } from '../../shared/styles/css-values';
import { Colors } from '../../shared/styles/colors';
import {
  doctrineStencilStyle,
  labelStyle,
  p2Style,
} from '../../shared/styles/text';
import { Source } from '../../shared/types/server-types';
import { usePedestal } from '../../shared/services/api/pedestal';
import { SocketCard } from './source-cards/SocketCard';
import { ValveCard } from './source-cards/ValveCard';
import { SourceModal } from './source-modals/SourceModal';
import { useParams } from 'react-router-dom';
import { useCurrentUser } from '../../shared/services/api/user';
import { useNavigator } from '../../routing/navigator';
import { PedestalLoadingState } from '../../shared/components/loading-states/PedestalLoadingState';
import { Layout } from '../../shared/components/layout/Layout';
import { filterSources } from '../../shared/utils/filterSources';
import {http} from "../../shared/services/http";
import {CacheKeys} from "../../shared/services/api/cache-configuration";
import { useAsyncEffect } from 'use-async-effect'

export const PedestalPage = (): JSX.Element => {
  let { pedestalId } = useParams<{ pedestalId: string }>();
  const navigator = useNavigator();

  if (!pedestalId) {
    throw new Error();
  }
  pedestalId = pedestalId.toLowerCase();

  const {
    data: pedestal,
    refetch: refetchPedestal,
    isLoading,
    isError,
  } = usePedestal({ identifier: pedestalId });
  const {
    data: user,
    isLoading: userIsLoading,
    isError: userIsError,
    refetch: refetchUser
  } = useCurrentUser();

  useAsyncEffect(async () => {
    console.log(pedestal)
    console.log(user)
    if(pedestal && user){
      const account = user.accounts.find((account) => account.location.id === pedestal.location.id) 
      if(!account){
        await http.createAccount(pedestal.location.id)
        await refetchUser()
      }
    }
  }, [isLoading, userIsLoading])

  const [activeModalSource, setActiveModalSource] = useState<Source>(
    {} as Source,
  );
  const [modalVisible, setModalIsVisible] = useState<boolean>(false);

  if (isError || userIsError) return <p>Error</p>;

  if (isLoading || userIsLoading || !pedestal || !user) {
    return <PedestalLoadingState />;
  }

  const { identifier, sockets, valves } = pedestal;
  const { availablePower, availableWater } = filterSources(valves, sockets);

  const sortedSockets = sockets.sort((a, b) => a.position - b.position);
  const sortedValves = valves.sort((a, b) => a.position - b.position);

  const handleSetIsModalVisible = async (value: boolean) => {
    setModalIsVisible(value);
    await refetchPedestal();
  };

  return (
    <Layout.Content className={pedestalPageStyle}>
      <div className={homeButtonStyle}>
        <Button
          startIcon={<ChevronLeft />}
          variant={ButtonVariantEnum.TEXT}
          size={ButtonSizeEnum.LARGE}
          text={`back`}
          onClick={navigator.toPrevious}
        />
      </div>
      <div className={pedestalInfoSectionStyle}>
        <div>
          <label className={cx(labelStyle, infoLabelStyle)}>ID</label>
          <h1 className={doctrineStencilStyle}>{identifier.toUpperCase()}</h1>
        </div>
        <div id="sourceInfo">
          <figure>
            <PowerIcon />
            <figcaption className={p2Style}>
              {availablePower}/{sockets.length}
            </figcaption>
          </figure>
          <figure>
            <WaterIcon />
            <figcaption className={p2Style}>
              {availableWater}/{valves.length}
            </figcaption>
          </figure>
        </div>
      </div>
      <div className={SourceSectionStyle}>
        {sortedSockets.map((socket, index) => (
          <SocketCard
            key={index}
            socket={socket}
            setModalVisible={setModalIsVisible}
            setActiveModalSource={setActiveModalSource}
          />
        ))}
      </div>
      {sortedValves.length > 0 && sortedSockets.length > 0 && (
        <div className={sourceCardDividerStyle}></div>
      )}
      <div className={SourceSectionStyle}>
        {sortedValves.map((valve, index) => (
          <ValveCard
            key={index}
            valve={valve}
            setModalVisible={setModalIsVisible}
            setActiveModalSource={setActiveModalSource}
          />
        ))}
      </div>
      <SourceModal
        pedestal={pedestal}
        source={activeModalSource as Source}
        user={user}
        isVisible={modalVisible}
        setIsVisible={handleSetIsModalVisible}
      />
    </Layout.Content>
  );
};

const pedestalPageStyle = css`
  min-height: calc(100vh - ${HEADER_HEIGHT});
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.625rem 1.5rem 2rem 1.5rem;
`;

const homeButtonStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  justify-self: flex-start;
  align-self: flex-start;
  margin-bottom: 1rem;
`;

const pedestalInfoSectionStyle = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;

  div#sourceInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    figure {
      display: flex;
      justify-content: space-between;
      svg {
        margin-right: 0.25rem;
      }
      figcaption {
        width: 2rem;
        text-align: center;
      }
    }
  }
`;

const infoLabelStyle = css`
  color: ${Colors.WINDOW_GRAY};
`;

const SourceSectionStyle = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  width: 100%;
`;

const sourceCardDividerStyle = css`
  width: 100%;
  min-width: 19.25rem;
  display: grid;
  grid-template: auto;

  &::after {
    content: '';
    width: 0px;
    height: 3rem;
    border-left: 2px dashed ${Colors.WINDOW_GRAY};
    margin: 0px auto;
  }
`;
