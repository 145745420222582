import { css, cx } from '@emotion/css';
import { ChevronLeft } from '../../shared/assets/icons';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../shared/components/Button';
import { Layout } from '../../shared/components/layout/Layout';
import { Colors } from '../../shared/styles/colors';
import { h1Style, h2Style, p2Style } from '../../shared/styles/text';
import { useNavigator } from '../../routing/navigator';
import {useEffect} from "react";

export const PrivacyPolicyPage = (): JSX.Element => {
  const navigator = useNavigator();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout.Content className={bodyStyle}>
      <Button
        startIcon={<ChevronLeft />}
        variant={ButtonVariantEnum.TEXT}
        size={ButtonSizeEnum.LARGE}
        text={`back`}
        className={backButtonStyle}
        onClick={navigator.toPrevious}
      />
      <h1 className={h1Style}>Privacy Policy</h1>
      <h2 className={cx(p2Style, paragraphUpdatedStyle)}>
        Last Updated: October 30, 2020
      </h2>

      <p className={cx(p2Style, paragraphContentStyle)}>
        Tvrtka Marex Elektrostroj d.o.o. vlasnik web stranice marex-hc.com („web
        stranica“). Stvorili smo ovu izjavu o privatnosti kako bismo pokazali da
        brinemo o privatnosti naših korisnika te kako bismo ih informirali o
        načinima skupljanja i obrade osobnih podataka na ovoj web stranici.
      </p>

      <p className={cx(p2Style, paragraphContentStyle)}>
        Molimo vas da ovu Izjavu pročitate pažljivo kako biste saznali kako
        skupljamo, obrađujemo, štitimo ili na bilo koji drugi način koristimo
        vaše osobne podatke.
      </p>

      <h2 className={cx(h2Style, paragraphTitleStyle)}>
        Službenik za zaštitu podataka
      </h2>

      <p className={cx(p2Style, paragraphContentStyle)}>
        Naša tvrtka ima sjedište u Zadru, Republika Hrvatska. Imenovali smo
        službenika za zaštitu podataka kako biste ga mogli kontaktirati radi
        bilo kakvih pitanja ili pritužbi vezanih uz našu politiku privatnosti
        ili rukovanje osobnim podacima. Kontaktni mail za pitanja o
        potencijalnoj ili stvarnoj povredi Vaše privatnosti možete uputiti
        službenoj osobi za zaštitu podataka na info@marex-es.hr
      </p>

      <h2 className={cx(h2Style, paragraphTitleStyle)}>
        Prikupljanje podataka
      </h2>

      <p className={cx(p2Style, paragraphContentStyle)}>
        Osobni podatak jest svaki podatak koji se odnosi na pojedinca čiji je
        identitet poznat ili se može razmjerno razumnim uloženim resursima i
        nama pristupačnim te legalnim načinom nedvojbeno utvrditi. Pojedinac
        čiji se identitet može utvrditi jest osoba koja se može nedvojbeno
        identificirati izravno ili neizravno, osobito uz pomoć identifikatora
        kao što su ime, identifikacijski broj, podaci o točnoj geolokaciji,
        mrežni identifikator ili uz pomoć jednog ili više čimbenika svojstvenih
        za fizički, fiziološki, genetski, mentalni, ekonomski, kulturni ili
        socijalni identitet tog pojedinca.
      </p>

      <p className={cx(p2Style, paragraphContentStyle)}>
        Vaše osobne podatke prikupljamo samo kada nam svojom voljom date takve
        informacije. Podatke o Vama prikupljamo ako ispunite obrazac za primanje
        neke informacije ili besplatnog servisa ili prilikom prijave na
        newsletter no niti tada ne tražimo nikakav drugi podatak osim e-mail
        adrese koja nam ne može dati nedvojbenu informaciju o Vama kao osobi
        niti ih na neki drugi prikriveni način prikupljamo.
      </p>

      <p className={cx(p2Style, paragraphContentStyle)}>
        Trebali biste imati na umu da se neosobne informacije i podaci mogu
        automatski prikupljati putem naših Internet servera ili korištenjem
        „kolačića“. Kliknite ovdje kako biste pročitali našu politiku o
        kolačićima.
      </p>

      <p className={cx(p2Style, paragraphContentStyle)}>
        Primjeri informacija o Vašem korištenju stranice uključuju:
        najposjećenije i najgledanije stranice i poveznice na našoj web
        stranici, broj ispunjenih obrazaca, vrijeme provedeno na stranici,
        najpopularnije ključne riječi koji korisnike vode do naše stranice, vaša
        IP adresa, informacije koje se skupljaju putem kolačića, podaci vašeg
        uređaja poput postavki hardwarea, sistemske aktivnosti, vrste
        pretraživača itd.
      </p>

      <p className={cx(p2Style, paragraphContentStyle)}>
        Ne prikupljamo podatke koji Vas nedvojbeno mogu identificirati kao osobu
        osim ako to izričito tražimo ako nam se prijavljujete kroz neku formu
        (Anketni ili sl. upitnik) no tada tražimo izričitu privolu za
        prikupljanje takvog podatka kojeg niti tada ne prosljeđujemo trećoj
        strani.
      </p>

      <h2 className={cx(h2Style, paragraphTitleStyle)}>Obrada podataka</h2>

      <p className={cx(p2Style, paragraphContentStyle)}>
        Podatke dobivene o vama koristimo u jednu ili više navedenih svrha:
      </p>

      <p className={cx(p2Style, paragraphContentStyle)}>
        - Radi personalizacije Vašeg korisničkog iskustva (prikupljene
        informacije nam pomažu da bolje odgovorimo na Vaše individualne
        potrebe); - Radi poboljšanja naše web stranice (trajno nastojimo
        poboljšati ponudu naše web stranice na temelju povratnih informacija
        koje dobivamo od naših posjetitelja); - Radi uspostave primarnog kanala
        komunikacije s vama; - Radi povremenog slanja e-poruka (koje se tiču
        povremenog primanja vijesti (ako to želite), ažuriranja, informacija o
        sličnim proizvodima ili uslugama, itd.) - Poveznice s trećim stranama Na
        našoj web stranici u naše ime ne nudimo niti pružamo usluge ili
        proizvode trećih strana već samo zadržavamo pravo za pružanje reklamnog
        prostora u kojem treće strane mogu promovirati svoj proizvod. Zaštita
        vaših podataka
      </p>

      <p className={cx(p2Style, paragraphContentStyle)}>
        Kako bismo zaštitili osobne podatke koje šaljete preko ove web stranice,
        koristimo fizičke, tehničke i organizacijske mjere sigurnosti.
        Neprestano nadograđujemo i testiramo našu sigurnosnu tehnologiju.
        Ograničavamo pristup Vašim osobnim podacima samo onim zaposlenicima koji
        trebaju znati te informacije kako bi Vam pružili neke koristi ili
        usluge. Osim toga, educiramo naše zaposlenike o važnosti povjerljivosti
        podataka te čuvanju privatnosti i zaštiti Vaših podataka.
      </p>

      <h2 className={cx(h2Style, paragraphTitleStyle)}>
        Pohrana i rok čuvanja podataka
      </h2>

      <p className={cx(p2Style, paragraphContentStyle)}>
        Svi podaci su pohranjeni u bazama podataka i repozitorijima koji su pod
        nadzorom stručnih osoba. Pohranjene podatke nećemo prenijeti ili
        pohraniti u države izvan Europske Unije.
      </p>

      <p className={cx(p2Style, paragraphContentStyle)}>
        Vaše osobne podatke čuvamo i štitimo s najvećom mogućom pažnjom i brigom
        za vrijeme trajanja Vašeg poslovnog odnosa s nama.
      </p>

      <h2 className={cx(h2Style, paragraphTitleStyle)}>Prava pojedinaca</h2>

      <p className={cx(p2Style, paragraphContentStyle)}>
        Ako mislite da je Vaša privatnost ugrožena ili (i) povrijeđena posjetom
        našim web servisima, a ugroza ili (i) povreda je jasno suprotna jasno
        iskazanim našim dobrim namjerama upravljanja Vašim osobnim podacima u
        ovoj politici privatnosti, svakako nas neodloživo kontaktirajte na
        e-mail info@marex-es.hr ćemo sve kako bi uklonili potencijalnu ugrozu
        neovlaštenog upravljanja ili zloporabe Vaših osobnih podataka, ako smo
        tehnički u mogućnosti to riješiti.
      </p>

      <h2 className={cx(h2Style, paragraphTitleStyle)}>
        Obavješćivanje o povredi osobnih podataka
      </h2>

      <p className={cx(p2Style, paragraphContentStyle)}>
        U slučaju povrede osobnih podataka obavijestit ćemo Vas i nadležno
        nadzorno tijelo porukom e-pošte u roku 72 sata o razmjerima povrede,
        obuhvaćenim podacima, možebitnom utjecaju na naše usluge i našim
        planiranim mjerama za osiguranje podataka i ograničenje bilo kakvih
        štetnih učinaka po pojedince.
      </p>

      <h2 className={cx(h2Style, paragraphTitleStyle)}>
        Obavijest o povredi Vam nećemo poslati u slučaju:
      </h2>

      <p className={cx(p2Style, paragraphContentStyle)}>
        - ako postoje tehničke i organizacijske mjere zaštite (poput enkripcije)
        koje su primijenjene na osobne podatke pogođene povredom osobnih
        podataka, koje te podatke čine nerazumljiva bilo kojoj osobi koja im
        nije ovlaštena pristupiti; - ako smo poduzeli naknadne mjere kojima se
        osigurava da više nije vjerojatno da će doći do visokog rizika za prava
        i slobode pojedinaca; - ako bi to iziskivalo nerazmjeran napor (u tom
        slučaju ćemo Vas obavijestiti putem sredstava javnog obavješćivanja ili
        slične jednako djelotvorne mjere). Povreda osobnih podataka znači
        povredu sigurnosti koja vodi do nenamjernog ili nezakonitog uništenja,
        gubitka, izmjene, neautoriziranog otkrivanja ili pristupa osobnim
        podacima koji se prenose, čuvaju i obrađuju u vezi s nuđenjem naših
        usluga.
      </p>

      <h2 className={cx(h2Style, paragraphTitleStyle)}>Vaša privola</h2>

      <p className={cx(p2Style, paragraphContentStyle)}>
        Korištenjem ove web stranice dajete privolu na ovu politiku privatnosti.
      </p>

      <p className={cx(p2Style, paragraphContentStyle)}>
        Promjene naše politike privatnosti
      </p>

      <p className={cx(p2Style, paragraphContentStyle)}>
        U slučaju promjena naše politike privatnosti obavijestit ćemo Vas o tome
        na ovoj stranici i ažurirati datum izmjene politike privatnosti dolje
        kako slijedi.
      </p>

      <p className={cx(p2Style, paragraphContentStyle)}>
        Ova politika privatnosti je zadnji put izmijenjena 10. listopada 2018.
        godine.
      </p>
    </Layout.Content>
  );
};

const bodyStyle = css`
  padding-top: 0;
`;

const paragraphUpdatedStyle = css`
  color: ${Colors.WINDOW_GRAY};
  padding: 1rem 0rem;
`;

const paragraphTitleStyle = css`
  margin-top: 2rem;
`;

const paragraphContentStyle = css`
  color:${Colors.BLACK}
  font-weight:400;
  margin-top:0.7rem;
`;

const backButtonStyle = css`
  padding-top: 0rem;
`;
