import { css } from '@emotion/css';
import { Colors } from './colors';

export const h1Style = css`
  font-family: Doctrine;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 120%;

  color: ${Colors.BLACK};
`;
export const p1Style = css`
  font-family: Doctrine;
  font-style: normal;
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 150%;

  color: ${Colors.BLACK};
`;
export const p2Style = css`
  font-family: Doctrine;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 150%;

  color: ${Colors.BLACK};
`;
export const button1Style = css`
  font-family: Doctrine;
  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 145%;

  letter-spacing: 0.03em;

  color: ${Colors.BLACK};
`;
export const fieldStyle = css`
  font-family: Doctrine;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 100%;

  color: ${Colors.BLACK};
`;
export const labelStyle = css`
  font-family: Doctrine;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 100%;

  color: ${Colors.BLACK};
`;
export const h2Style = css`
  font-family: Doctrine;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 120%;

  color: ${Colors.BLACK};
`;
export const h3Style = css`
  font-family: Doctrine;
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 120%;

  color: ${Colors.BLACK};
`;
export const valueStyle = css`
  font-family: Doctrine;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 120%;

  color: ${Colors.BLACK};
`;
export const h4Style = css`
  font-family: Doctrine;
  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 120%;

  color: ${Colors.BLACK};
`;
export const button2Style = css`
  font-family: Doctrine;
  font-style: normal;
  font-weight: bold;
  font-size: 1rem;
  line-height: 120%;

  letter-spacing: 0.03em;
  text-transform: uppercase;

  color: ${Colors.BLACK};
`;
export const doctrineStencilStyle = css`
  font-family: 'Doctrine Stencil';
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0px;
`;
export const p3Style = css`
  font-family: Doctrine;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;

  color: #000000;
`;
