import './App.css';
import { AppRouter } from './routing/AppRouter';
import { QueryClient, QueryClientProvider } from 'react-query';
import { UserContext } from './shared/providers/user-provider';
import duration from 'dayjs/plugin/duration';
import dayjs from 'dayjs';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

dayjs.extend(duration);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

export const App = (): JSX.Element => {
  return (
    <QueryClientProvider client={queryClient}>
      <UserContext>
        <AppRouter />
      </UserContext>
      <ToastContainer position="bottom-center" hideProgressBar />
    </QueryClientProvider>
  );
};
