import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Pedestal,
  SessionRequestActionEnum,
  SessionRequestStatusEnum,
  Socket,
  Source,
  User,
  Valve,
  Vehicle,
} from '../../../shared/types/server-types';
import Sheet from 'react-modal-sheet';
import { ChevronDown } from '../../../shared/assets/icons';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../shared/components/Button';
import {
  activateButtonStyle,
  activationInfoLabelStyle,
  activationInfoStyle,
  sourceModalStyle,
} from './modal-styles';
import { PaymentTypeEnum, SourceTypeEnum } from '../../../shared/types/enums';
import { ValveModalContent } from './ValveModalContent';
import { SocketModalContent } from './SocketModalContent';
import { VehicleDropdown } from './VehicleDropdown';
import { css, cx } from '@emotion/css';
import { useClickOutside } from '../../../shared/custom-hooks/custom-hooks';
import {
  Divider,
  DividerVariantEnum,
} from '../../../shared/components/Divider';
import { useSessionRequest } from '../../../shared/services/api/session';
import { useNavigator } from '../../../routing/navigator';
import { http } from '../../../shared/services/http';
import {
  HowToAddMoneyText,
  NoAccountText,
} from '../../history-page/PaymentInfoText';
import { showPrivateToast } from '../../../shared/utils/show-private-toast';
import { Loader } from '../../../shared/components/Loader';

interface ISourceModalProps {
  pedestal: Pedestal;
  source: Source;
  user: User;
  isVisible: boolean;
  setIsVisible: any;
}

export const SourceModal = (props: ISourceModalProps): JSX.Element => {
  const { pedestal, source, isVisible, setIsVisible, user } = props;
  const { vehicles, accounts } = user;
  const [height, setHeight] = useState(0);
  const contentRef = useRef<any>();
  const headerRef = useRef<any>();
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle>(vehicles[0]);
  const navigator = useNavigator();
  const [requestId, setRequestId] = useState<number | undefined>(undefined);

  const { data: request, remove: removeSessionRequestQuery } =
      useSessionRequest(requestId);
  const [requestingSession, setRequestingSession] = useState<boolean>(false);

  const account = useMemo(
      () =>
          accounts.find((account) => account.location.id === pedestal.location.id),
      [user]);

  useEffect(() => {
    if (request) {
      if (
          request.status === SessionRequestStatusEnum.APPROVED &&
          request.action === SessionRequestActionEnum.ON
      ) {
        showPrivateToast('Starting the session.');
        setRequestId(undefined);
        removeSessionRequestQuery();
      }
      if (
          request.status === SessionRequestStatusEnum.DENIED &&
          request.action === SessionRequestActionEnum.ON
      ) {
        setRequestId(undefined);
        setIsVisible(false);
        removeSessionRequestQuery();
        showPrivateToast(
            'Session could not be started. Try again later or contact staff.',
        );
      }
    }
  }, [request]);

  const handleStartSession = async () => {
    setRequestingSession(true);
    try {
      if (source && source.type === SourceTypeEnum.SOCKET) {
        const { state } = source;
        if (!state.activated && state.socketStable) {
          const request = await http.startSession({
            sourceId: source.id,
            vehicleId: selectedVehicle.id,
          });
          setRequestId(request.id);
        }
      }
      if (source && source.type === SourceTypeEnum.VALVE) {
        const { state } = source;
        if (!state.activated) {
          const request = await http.startSession({
            sourceId: source.id,
            vehicleId: selectedVehicle.id,
          });
          setRequestId(request.id);
        }
      }
    } catch (e: any) {
      if (e?.response?.data) {
        const { message } = e.response.data;
        setIsVisible(false);
        showPrivateToast(message);
      }
    } finally {
      setRequestingSession(false);
      setIsVisible(false);
    }
  };

  const shouldEnableActivateButton = useMemo(() => {
    if (source) {
      const { type, state } = source;
      return (type === SourceTypeEnum.VALVE && !state.activated) ||
      (type === SourceTypeEnum.SOCKET && !state.activated && state.socketStable)
          ? true
          : false;
    } else {
      return false;
    }
  }, [source]);

  const dropdownRef = useClickOutside(() => {
    setDropdownVisible(false);
  }) as React.MutableRefObject<HTMLDivElement>;

  const onGoToAccountCreation = () => {
    navigator.toWalletAddFunds();
  };

  if (!account) {
    return <Loader />;
  }

  console.log(shouldEnableActivateButton || !vehicles?.length || account.balance <= 0)
  console.log(shouldEnableActivateButton)
  console.log(!vehicles?.length)
  console.log(account.balance <= 0)
  console.log({activateButtonState: shouldEnableActivateButton, vl: vehicles?.length, account: account})
  return (
      <Sheet
          isOpen={isVisible}
          onClose={() => {
            setIsVisible(false);
            setDropdownVisible(false);
          }}
          onOpenStart={() => {
            if (contentRef.current && headerRef.current) {
              const contentHeight = contentRef.current.offsetHeight;
              const headerHeight = headerRef.current.offsetHeight;
              setHeight(contentHeight + headerHeight);
            }
          }}
          snapPoints={[height]}
      >
        <Sheet.Container>
          <Sheet.Header ref={headerRef} />
          <Sheet.Content disableDrag={true}>
            <div className={sourceModalStyle} ref={contentRef}>
              {source && source.type === SourceTypeEnum.SOCKET ? (
                  <SocketModalContent
                      socket={source as Socket}
                      pedestal={pedestal}
                      account={account}
                  />
              ) : (
                  <ValveModalContent
                      valve={source as Valve}
                      pedestal={pedestal}
                      account={account}
                  />
              )}
              <Divider variant={DividerVariantEnum.FULL_WIDTH_DIVIDER} />
              <div className={vehicleDropdownContainerStyle}></div>
              <>
                {account && (
                    <div className={activationInfoStyle}>
                      {account.paymentType === PaymentTypeEnum.PREPAID && (
                          <div className={activationInfoLabelStyle}>
                            <div>
                              <label>available funds</label>
                              <p>
                                {account
                                    ? `${account.balance.toFixed(2)} EUR`
                                    : 'No funds'}{' '}
                              </p>
                            </div>
                          </div>
                      )}

                      <div
                          className={activationInfoLabelStyle}
                          onClick={() => {
                            setDropdownVisible(!dropdownVisible);
                          }}
                          ref={dropdownRef}
                      >
                        <VehicleDropdown
                            dropdownVisible={dropdownVisible}
                            setDropdownVisible={setDropdownVisible}
                            vehicles={vehicles}
                            selectedVehicle={selectedVehicle}
                            setSelectedVehicle={setSelectedVehicle}
                        />
                        <div>
                          <label>vehicle</label>
                          <p>
                            {vehicles.length === 0
                                ? 'No Vehicles'
                                : selectedVehicle.registration}
                          </p>
                        </div>
                        <ChevronDown
                            className={cx(
                                dropdownChevronStyle,
                                dropdownVisible && dropdownChevronRotateStyle,
                            )}
                        />
                      </div>
                      {account &&
                          account.paymentType === PaymentTypeEnum.PREPAID &&
                          account.balance === 0 && <HowToAddMoneyText />}
                      <Button
                          className={activateButtonStyle}
                          text={'activate'}
                          size={ButtonSizeEnum.LARGE}
                          variant={ButtonVariantEnum.FILLED}
                          disabled={
                              !shouldEnableActivateButton ||
                              vehicles?.length === 0 ||
                              (account.balance <= 0 && account.paymentType !== PaymentTypeEnum.POSTPAID)
                          }
                          onClick={handleStartSession}
                          loading={
                              requestingSession ||
                              (request &&
                                  request.status === SessionRequestStatusEnum.PENDING)
                          }
                      />
                    </div>
                )}
                {!account && (
                    <div className={noAccountContainer}>
                      <NoAccountText />
                      {!user.charter && (
                          <Button
                              className={activateButtonStyle}
                              text={'create one now'}
                              size={ButtonSizeEnum.LARGE}
                              variant={ButtonVariantEnum.FILLED}
                              onClick={onGoToAccountCreation}
                          />
                      )}
                    </div>
                )}
              </>
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop
            onTap={() => {
              setIsVisible(false);
              setDropdownVisible(false);
            }}
            // affects only backdrop opacity transition,
            // time until unmount unaffected,
            // until sheet component unmounts, entire screen is unclickable (~1 sec)
            // TODO: change animation time until unmount
            style={{ border: 'none' }}
        />
      </Sheet>
  );
};

const dropdownChevronStyle = css`
  margin-left: 8px;
  transition: transform 0.25s ease-out;
  align-self: center;
`;

const dropdownChevronRotateStyle = css`
  transform: rotate(180deg);
`;

const vehicleDropdownContainerStyle = css`
  width: 100%;
  position: relative;
`;

const noAccountContainer = css`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-top: 16px;
`;
