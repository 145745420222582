import { useQuery, UseQueryResult } from 'react-query';
import { SessionGroup } from '../../types/server-types';
import { CacheKeys } from './cache-configuration';
import { http } from '../http';

export const useGetSessionGroup = (
  id: string,
): UseQueryResult<SessionGroup> => {
  return useQuery(
    [CacheKeys.SESSION_GROUP, id],
    () => http.getSessionGroup(id),
    {
      refetchInterval: 5000,
    },
  );
};
