import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { TextInput } from '../../shared/components/TextInput';
import { useCheckIfEmailExists } from '../../shared/services/api/user';
import { css, cx } from '@emotion/css';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../shared/components/Button';
import { CheckEmailParams } from '../../shared/types/input-param-types';
import {h1Style, p2Style} from '../../shared/styles/text';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Layout } from '../../shared/components/layout/Layout';
import { useNavigator } from '../../routing/navigator';
import { authenticationStore } from '../../shared/stores/authentification-store';
import { observer } from 'mobx-react';
import { HEADER_HEIGHT } from '../../shared/styles/css-values';
import {Link} from "react-router-dom";
import {appRoutes} from "../../routing/app-routes";
import {Colors} from "../../shared/styles/colors";

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
});

export const CheckEmailPage = observer((): JSX.Element => {
  const checkIfEmailExists = useCheckIfEmailExists();
  const navigator = useNavigator();
  const authData = authenticationStore.getAuthenticationData();

  const methods = useForm<CheckEmailParams>({
    defaultValues: {
      email: authData?.email || '',
    },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  /**
   * Check if the email already exists within the database.
   * @param data
   */
  const handleCheckIfEmailExists: SubmitHandler<CheckEmailParams> = async (
    data: CheckEmailParams,
  ) => {
    try {
      const { email } = data;
      authenticationStore.setAuthenticationData({
        ...authData,
        email,
      });
      const userExists = await checkIfEmailExists({ email });
      if (userExists) {
        navigator.toEmailSignIn();
      } else {
        navigator.toSignUp();
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleCheckIfEmailExists)}>
        <Layout.Content className={formContentStyle}>
          <div>
            <h1 className={cx(h1Style, headerStyle)}>Start now.</h1>
            <TextInput
              name={'email'}
              placeHolder={'enter your email'}
              type={'email'}
            />
            {/*<p>or</p>*/}
            {/*<Link to={appRoutes.usernameSignIn}>*/}
            {/*  <p className={userSignInTextStyle}>sign-in with username.</p>*/}
            {/*</Link>*/}
          </div>
          <div className={buttonContainerStyle}>
            <Button
              variant={ButtonVariantEnum.TEXT}
              size={ButtonSizeEnum.LARGE}
              text={'back'}
              onClick={navigator.toLanding}
            />
            <Button
              variant={ButtonVariantEnum.FILLED}
              size={ButtonSizeEnum.LARGE}
              text={'next'}
              type={'submit'}
            />
          </div>
        </Layout.Content>
      </form>
    </FormProvider>
  );
});

const formContentStyle = css`
  min-height: calc(100vh - ${HEADER_HEIGHT});
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const headerStyle = css`
  margin-bottom: 2rem;
`;

const buttonContainerStyle = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const userSignInTextStyle = css`
  ${p2Style}
  color: ${Colors.CAPRI_BLUE};
  cursor: pointer;
`
