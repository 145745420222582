import { useQuery, UseQueryResult } from 'react-query';
import {
  GetFilteredReceiptsParams,
  GetReceiptOverviewParams,
} from '../../types/input-param-types';
import { Receipt } from '../../types/server-types';
import { http } from '../http';
import { CacheKeys } from './cache-configuration';

export const useReceipts = (): UseQueryResult<Receipt[]> => {
  return useQuery(CacheKeys.RECEIPTS, () => http.getReceipts());
};

export const useReceipt = (
  params: GetReceiptOverviewParams,
): UseQueryResult<Receipt> => {
  return useQuery(CacheKeys.RECEIPT, () => http.getReceipt(params));
};
