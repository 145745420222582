import { css } from '@emotion/css';
import { DurationTimer } from '../../shared/components/DurationTimer';
import { Colors } from '../../shared/styles/colors';
import { labelStyle } from '../../shared/styles/text';

interface ISessionTotalOverviewProps {
  sessionStartTimeStamp: Date;
  sessionTotalCost: string;
  currency: string;
  groupId: number;
}

export const SessionTotalOverview = (
  props: ISessionTotalOverviewProps,
): JSX.Element => {
  const { sessionStartTimeStamp, sessionTotalCost, currency = 'eur' } = props;

  return (
    <div className={sessionTotalOverviewStyle}>
      <div className={sessionTotalOverviewInfoStyle}>
        <DurationTimer startTime={sessionStartTimeStamp} />
        <div>
          <label>Total</label>
          <div className={sessionTotalOverviewCostStyle}>
            <span className={sessionTotalOverviewAmountStyle}>
              {sessionTotalCost}
              {'\u00A0'}
            </span>
            <span className={sessionTotalOverviewCurrencyStyle}>
              {currency}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const sessionTotalOverviewStyle = css`
  background-color: ${Colors.WHITE};
  border-top: 2px solid ${Colors.LIGHT_GRAY};
  padding: 1rem 1.5rem 2rem 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  bottom: 0px;
  left: 0px;
`;

const sessionTotalOverviewInfoStyle = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1.5rem;

  label {
    ${labelStyle.toString()}
    color: ${Colors.GRAY};
    text-transform: capitalize;
  }

  > div {
    display: flex;
    flex-direction: column;
    > label {
      margin-bottom: 0.5rem;
    }
  }
`;

const sessionTotalOverviewCostStyle = css`
  border: 2px solid #333333;
  padding: 0.125rem 0.5rem;
`;

const sessionTotalOverviewAmountStyle = css`
  font-size: 2rem;
  line-height: 120%;
`;

const sessionTotalOverviewCurrencyStyle = css`
  font-size: 0.75rem;
  line-height: 0.9rem;
  letter-spacing: 0px;

  text-transform: uppercase;
`;
