import { css, cx } from '@emotion/css';
import { useController, useFormContext } from 'react-hook-form';
import { Checkmark } from '../../shared/assets/icons';
import { Colors } from '../../shared/styles/colors';
import {labelStyle, p2Style, valueStyle} from '../../shared/styles/text';
import {Link} from "react-router-dom";
import {appRoutes} from "../../routing/app-routes";

const listItemStyle = css`
  margin-bottom: 1rem;
  list-decoration: none;
  cursor: pointer;
  text-decoration: underline;
`;

const listItemContainerStyle = css`
  margin-top: 2rem;
`

interface TermsConfirmationProps {
  name: string;
}

export const TermsConfirmation = (
  props: TermsConfirmationProps,
): JSX.Element => {
  const { control } = useFormContext();
  const { name } = props;
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <div className={checkboxContainerStyle}>
      <label>
        <input
          className={hiddenCheckbox}
          type="checkbox"
          {...field}
          checked={field.value}
        />
        <span className={checkboxStyle} id={'checkbox'}>
          <Checkmark className={checkMarkStyle} />
        </span>
        <span className={checkboxTextStyle}>
          I accept Terms of Use and Privacy Policy.
          <span style={{ color: Colors.ERROR }}>*</span>
        </span>
      </label>
      <div className={listItemContainerStyle}>
        <li className={listItemStyle}>
          <Link to={appRoutes.privacyPolicy}>Privacy Policy</Link>
        </li>
        <li className={listItemStyle}>
          <Link to={appRoutes.termsOfUse}>Terms of Use</Link>
        </li>
      </div>
      {/* error message is not displayed on validation */}
      {error && (
        <span className={cx(labelStyle, errorStyle)}>{error.message}</span>
      )}
    </div>
  );
};

const checkboxContainerStyle = css`
  margin-bottom: 2rem;
  position: relative;
  > label {
    display: flex;
    cursor: pointer;
    > input:checked ~ #checkbox {
      background-color: ${Colors.CAPRI_BLUE};
      border-color: ${Colors.CAPRI_BLUE};
    }
  }
`;

const checkboxStyle = css`
  /* background-color: green; */
  width: 18px;
  height: 18px;
  border: 2px solid ${Colors.WINDOW_GRAY};
  margin-right: 0.75rem;
  margin-top: 0.25rem;
  display: flex;
  justify-content: space-around;
  > svg {
    align-self: center;
  }
`;

const hiddenCheckbox = css`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const checkMarkStyle = css``;

const checkboxTextStyle = css`
  ${p2Style}
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
`;

const errorStyle = css`
  color: ${Colors.ERROR};
  transform: translate(1.125rem, 62px) scale(1);
  transform-origin: top left;
  pointer-events: none;
  &:first-letter {
    text-transform: capitalize;
  }
`;
