import { Layout } from '../../shared/components/layout/Layout';
import { css, cx } from '@emotion/css';
import { h1Style, p1Style } from '../../shared/styles/text';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../shared/components/Button';
import { useNavigator } from '../../routing/navigator';
import { HEADER_HEIGHT } from '../../shared/styles/css-values';
import { Colors } from '../../shared/styles/colors';

export const ErrorPage: React.FC = () => {
  const navigator = useNavigator();

  return (
    <Layout.Content className={innerContainerStyle}>
      <h1 className={cx(h1Style, errorText)}>404!</h1>
      <p className={cx(p1Style, infoText)}>
        Page Not Found! The Page you are looking for does not exist!
      </p>
      <Button
        variant={ButtonVariantEnum.FILLED}
        size={ButtonSizeEnum.LARGE}
        text={`back to home`}
        onClick={navigator.toLanding}
      />
    </Layout.Content>
  );
};

const errorText = css`
  font-size: 6rem;
  color: ${Colors.CAPRI_BLUE};
`;

const infoText = css`
  text-align: center;
`;

const innerContainerStyle = css`
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 2rem;
  justify-content: space-between;
  margin-top: calc(6.375rem - ${HEADER_HEIGHT});
`;
