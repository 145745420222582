import { css, cx } from '@emotion/css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Colors } from '../../styles/colors';
import { labelStyle } from '../../styles/text';
import { PowerIcon, WaterIcon } from '../../../shared/assets/icons';


export const PedestalLoadingState = (): JSX.Element => {
  return (
    <SkeletonTheme baseColor={Colors.LIGHT_GRAY} highlightColor='#e6e6e6'>

      <div className={content}>



        <div className={topContainerStyle}>
          <div className={textContainerStyle} >
            <span className={cx(labelStyle, titleStyle)}>ID</span>
            <Skeleton height={'2.375rem'} borderRadius={0} />
          </div>
          <div className={iconsContainerStyle}>
            <div className={iconStyle}>
              <PowerIcon />
              <Skeleton inline={true} className={iconStyle} height={'1.8rem'} width={'20%'} borderRadius={0} />
            </div>
            <div className={iconStyle}>
              <WaterIcon />
              <Skeleton inline={true} height={'1.8rem'} width={'20%'} borderRadius={0} />
            </div>
          </div>
        </div>



        <div className={containerStyle}>
          <div className={style}>
            <Skeleton height={'4rem'} borderRadius={0} />
          </div>

          <div className={style}>
            <Skeleton height={'4rem'} borderRadius={0} />
          </div>
        </div>

        <div className={cx(containerStyle, marginTop)}>
          <div className={style}>
            <Skeleton height={'4rem'} borderRadius={0} />
          </div>

          <div className={style}>
            <Skeleton height={'4rem'} borderRadius={0} />
          </div>
        </div>

        <div className={center}>
          <div className={separator} />
          <div className={separator} />
          <div className={separator} />
          <div className={separator} />
          <div className={separator} />

        </div>

        <div className={cx(containerStyle, marginTop)}>
          <div className={style}>
            <Skeleton height={'4rem'} borderRadius={0} />
          </div>

          <div className={style}>
            <Skeleton height={'4rem'} borderRadius={0} />
          </div>
        </div>

        <div className={cx(containerStyle, marginTop)}>

          <div className={style}>
            <Skeleton height={'4rem'} borderRadius={0} />
          </div>

          <div className={style}>
            <Skeleton height={'4rem'} borderRadius={0} />
          </div>
        </div>




      </div>



    </SkeletonTheme>
  );
};
const content = css`
    padding:1.5rem;
    padding-bottom:7rem;
`
const style = css`
    width:45%;
`

const containerStyle = css`
    width:100%;
    display:flex;
    justify-content:space-between;
`

const marginTop = css`
    margin-top:1rem;
`
const center = css`
  display:flex;
  flex-direction:column;
  align-items:center;
`
const separator = css`
  height:0.5rem;
  width:0.15rem;
  background-color:#9A9FA3;
  margin-top:0.2rem;
`

const topContainerStyle = css`
  width:100%;
  display:flex;
  justify-content: space-between;
  margin-bottom:1.5rem;
`
const titleStyle = css`
  color:${Colors.WINDOW_GRAY}
`
const iconStyle = css`
  align-items:end;
`
const iconsContainerStyle = css`
  width:45%;
  text-align-last: end;
`
const textContainerStyle = css`
  width:45%;
`