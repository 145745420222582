import { css } from '@emotion/css';
import { Colors } from '../../../shared/styles/colors';
import { Layout } from '../../../shared/components/layout/Layout';
import { Vehicle } from '../../../shared/types/server-types';
import { h1Style, valueStyle } from '../../../shared/styles/text';
import { useUser } from '../../../shared/providers/user-provider';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../shared/components/Button';
import { PlusCircleIcon } from '@heroicons/react/solid';
import {
  UserRole,
  VehicleTypeEnum,
  vehicleTypeLabels,
} from '../../../shared/types/enums';
import {
  Divider,
  DividerVariantEnum,
} from '../../../shared/components/Divider';
import { useState } from 'react';
import { EditVehicleForm } from './EditVehicleForm';
import { Modal } from './Modal';
import { NewVehicleForm } from './NewVehicleForm';
import 'react-toastify/dist/ReactToastify.css';
import { ChevronLeft } from '../../../shared/assets/icons';
import { useNavigator } from '../../../routing/navigator';

export const VehiclePage = (): JSX.Element => {
  const navigator = useNavigator();
  const user = useUser();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<JSX.Element>(<></>);
  const { vehicles } = user!;

  const handleModal = (visible: boolean, content: JSX.Element) => {
    setModalContent(content);
    setModalVisible(visible);
  };

  return (
    <Layout.Content className={vehiclePageStyle}>
      <Button
        startIcon={<ChevronLeft />}
        variant={ButtonVariantEnum.TEXT}
        size={ButtonSizeEnum.LARGE}
        text={`account`}
        className={backButtonStyle}
        onClick={navigator.toPrevious}
      />
      <div className={vehiclePageHeaderStyle}>
        <h1>Vehicles</h1>
        {user?.role !== UserRole.CHARTER_EMPLOYEE && (
          <span>
            <Button
              size={ButtonSizeEnum.MEDIUM}
              variant={ButtonVariantEnum.TEXT}
              text={'new'}
              startIcon={<PlusCircleIcon className={iconStyle} />}
              onClick={() =>
                handleModal(
                  true,
                  <NewVehicleForm setModalVisible={setModalVisible} />,
                )
              }
            />
          </span>
        )}
      </div>
      <ul className={vehiclesListStyle}>
        {vehicles.map((item: Vehicle, index: number) => (
          <li
            key={index}
            onClick={() =>
              !user?.charter &&
              handleModal(
                true,
                <EditVehicleForm
                  vehicle={item}
                  setModalVisible={setModalVisible}
                />,
              )
            }
          >
            <div className={listItemTextStyle}>
              <span className={vehicleRegistrationStye}>
                {item.registration}
              </span>
              <span className={vehicleTypeStyle}>
                {vehicleTypeLabels[item.type as VehicleTypeEnum]}
              </span>
            </div>
            <Divider variant={DividerVariantEnum.LIST_DIVIDER} />
          </li>
        ))}
      </ul>
      {/*
          This conditional rendering is needed because modal component uses
          custom hook that adds event listener when mounted,
          this produces a sideffect where modal can't be opened
       */}
      {modalVisible && (
        <Modal visible={modalVisible} setVisible={setModalVisible}>
          {modalContent}
        </Modal>
      )}
    </Layout.Content>
  );
};

const vehiclePageStyle = css`
  padding-top: 0.625rem;
`;

const vehiclePageHeaderStyle = css`
  display: flex;
  justify-content: space-between;
  h1 {
    ${h1Style}
    margin-bottom: 1.5rem;
  }
`;

const vehiclesListStyle = css`
  list-style: none;
  display: flex;
  flex-direction: column;
  li {
    ${valueStyle}
    display: flex;
    flex-direction: column;
    align-content: center;
  }
`;

const listItemTextStyle = css`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
`;

const vehicleRegistrationStye = css`
  ${valueStyle}
  margin-bottom: .25rem;
`;

const vehicleTypeStyle = css`
  ${valueStyle}
  color: ${Colors.WINDOW_GRAY};
`;

const iconStyle = css`
  width: 24px;
  height: 24px;
  color: ${Colors.CAPRI_BLUE};
`;

const backButtonStyle = css`
  padding-top: 0rem;
`;
