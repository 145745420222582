/** @jsxImportSource @emotion/react */
import { ResetPasswordForm } from './ResetPasswordForm'
import { Layout } from '../../shared/components/layout/Layout';

export const ResetPasswordPage = (): JSX.Element => {
  return (
    <Layout.Content>
      <div>
        Reset your password
      </div>
      <ResetPasswordForm />
    </Layout.Content>
  )
}
