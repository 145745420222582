import { css } from '@emotion/css';
import { useClickOutside } from '../../../shared/custom-hooks/custom-hooks';

interface IModalProps {
  visible: boolean;
  setVisible: (arg: boolean) => void;
  children: JSX.Element;
}

export const Modal = (props: IModalProps): JSX.Element => {
  const { children, setVisible, visible } = props;
  const modalRef = useClickOutside(() => {
    setVisible(false);
  }) as React.MutableRefObject<HTMLDivElement>;
  return (
    <div className={modalStyle(visible)}>
      <section className={modalContentStyle} ref={modalRef}>
        {children}
      </section>
    </div>
  );
};

const modalStyle = (visible: boolean) => css`
  display: ${visible ? 'block' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
`;

const modalContentStyle = css`
  display: flex;
  position: fixed;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.5rem 1.5rem 0px 1.5rem;
  border-radius: 0.5rem;
  width: 90%;
`;
