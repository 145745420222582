import { ClassNamesArg, css, cx } from '@emotion/css';
import { Oval } from 'react-loader-spinner';
import { Colors } from '../styles/colors';

interface LoaderProps {
  className?: ClassNamesArg;
}

export const Loader = (props: LoaderProps): JSX.Element => {
  const { className } = props;
  return (
    <Oval
      arialLabel="loading-indicator"
      color={Colors.CAPRI_BLUE}
      wrapperClass={cx(loaderStyle, className)}
    />
  );
};

const loaderStyle = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
