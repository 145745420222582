import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { TextInput } from '../../shared/components/TextInput';
import { css, cx } from '@emotion/css';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../shared/components/Button';
import {
  CheckEmailParams,
  EmailSignInParams,
} from '../../shared/types/input-param-types';
import { Colors } from '../../shared/styles/colors';
import { h1Style } from '../../shared/styles/text';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Layout } from '../../shared/components/layout/Layout';
import { useNavigator } from '../../routing/navigator';
import { useQueryClient } from 'react-query';
import { CacheKeys } from '../../shared/services/api/cache-configuration';
import { authenticationStore } from '../../shared/stores/authentification-store';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { HEADER_HEIGHT } from '../../shared/styles/css-values';
import { appRoutes } from '../../routing/app-routes';
import { showPublicToast } from "../../shared/utils/show-public-toast";
import { useEmailSignIn } from "../../shared/services/api/user";

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(8, 'Password is too short').required(),
});

export const EmailSignInPage = observer((): JSX.Element => {
  const signInUser = useEmailSignIn();
  const navigator = useNavigator();
  const queryClient = useQueryClient();
  const authData = authenticationStore.getAuthenticationData();

  /**
   * Redirect in case of manually entered URL.
   */
  useEffect(() => {
    const email = authData?.email;
    if (!email) {
      navigator.toCheckEmail();
    }
  }, []);

  const methods = useForm<CheckEmailParams | EmailSignInParams>({
    defaultValues: {
      email: authData?.email ? authData.email : '',
      password: '',
    },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  /**
   * Handle the sign-in logic for the user.
   * @param data
   */
  const handleSignInUser: SubmitHandler<EmailSignInParams> = async (
    data: EmailSignInParams,
  ) => {
    try {
      const { token } = await signInUser({ ...data });
      window.localStorage.setItem('token', token);
      await queryClient.invalidateQueries(CacheKeys.ME);
      authenticationStore.reset();
      navigator.toLanding();
    } catch (err: any) {
      showPublicToast('Incorrect credentials or unverified.')
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSignInUser)}>
        <Layout.Content className={formContentStyle}>
          <div>
            <h1 className={cx(h1Style, headerStyle)}>Start now.</h1>
            <TextInput
              name={'email'}
              placeHolder={'enter your email'}
              type={'email'}
            />
            <div>
              <TextInput
                name={'password'}
                placeHolder={'password'}
                type={'password'}
              />
              <a href={appRoutes.forgotPassword} className={forgotPasswordStyle}>forgot password?</a>
            </div>
          </div>
          <div className={buttonContainerStyle}>
            <Button
              variant={ButtonVariantEnum.TEXT}
              size={ButtonSizeEnum.LARGE}
              text={'back'}
              onClick={navigator.toPrevious}
            />
            <Button
              variant={ButtonVariantEnum.FILLED}
              size={ButtonSizeEnum.LARGE}
              text={'next'}
              type={'submit'}
            />
          </div>
        </Layout.Content>
      </form>
    </FormProvider>
  );
});

const formContentStyle = css`
  min-height: calc(100vh - ${HEADER_HEIGHT});
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const headerStyle = css`
  margin-bottom: 2rem;
`;

const buttonContainerStyle = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const forgotPasswordStyle = css`
  font-family: Doctrine;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: ${Colors.CAPRI_BLUE};
  padding: 0.75rem 0px;
  cursor: pointer;
`;
