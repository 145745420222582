import { useState } from 'react';
import { css, cx } from '@emotion/css';
import { Colors } from '../styles/colors';
import { fieldStyle, labelStyle } from '../styles/text';
import { useController, useFormContext } from 'react-hook-form';
import { EyeIconOff, EyeIconOn } from '../assets/icons';
import InputMask from 'react-input-mask';

interface ITextInputProps {
  placeHolder: string;
  name: string;
  type?: string;
  markAsRequired?: boolean;
  defaultValue?: any;
  className?: string;
  onChange?: (value: any) => void;
}

export const TextInput = (props: ITextInputProps): JSX.Element => {
  const { control, watch } = useFormContext();
  const {
    placeHolder,
    name,
    type = 'text',
    markAsRequired = false,
    className,
    defaultValue,
    onChange = (e: any) => e,
  } = props;

  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const userInput = watch(name);
  const [passwordIsVisible, setPasswordIsVisible] = useState<boolean>(false);
  const togglePasswordVisibility = () => {
    setPasswordIsVisible(!passwordIsVisible);
  };

  return (
    <div className={cx(textInputStyle(userInput, error), className)}>
      {type !== 'date' ? (
        <>
          <input
            className={inputStyle}
            type={passwordIsVisible ? 'text' : type}
            {...field}
            value={field.value || ''}
            onChange={(e: any) => {
              field.onChange(e.target.value);
              onChange(e.target.value);
            }}
            defaultValue={defaultValue}
          />
          <label className={cx(fieldStyle, inputLabelStyle)}>
            {placeHolder}
            {markAsRequired && <span style={{ color: Colors.ERROR }}>*</span>}
          </label>
          {passwordIsVisible ? (
            <EyeIconOn
              className={eyeIconStyle(type)}
              onClick={togglePasswordVisibility}
            />
          ) : (
            <EyeIconOff
              className={eyeIconStyle(type)}
              onClick={togglePasswordVisibility}
            />
          )}
          {error && (
            <span className={cx(labelStyle, errorStyle)}>{error.message}</span>
          )}
        </>
      ) : (
        <>
          <InputMask
            className={inputStyle}
            {...field}
            value={field.value || ''}
            onChange={(e) => {
              field.onChange(e.target.value);
              onChange(e.target.value);
            }}
            defaultValue={defaultValue}
            mask={'99/99/9999'}
          />
          <label className={cx(fieldStyle, inputLabelStyle)}>
            {placeHolder}
            {markAsRequired && <span style={{ color: Colors.ERROR }}>*</span>}
          </label>
        </>
      )}
    </div>
  );
};

const textInputStyle = (userInput: any, error: any) => css`
  width: 100%;
  height: 5.25rem;
  position: relative;
  display: flex;
  flex-direction: column;

  &:focus-within label {
    ${labelStyle}
    transform: translate(1.125rem, 0.75rem) scale(1);
    color: ${Colors.CAPRI_BLUE};
  }
  label {
    ${userInput &&
    css`
      ${labelStyle}
      transform: translate(1.125rem, 0.75rem) scale(1);
      color: ${Colors.WINDOW_GRAY};
    `}
  }
  input {
    ${error &&
    css`
      border-color: ${Colors.ERROR};
    `}
  }
  &:focus-within input {
    border-color: ${Colors.CAPRI_BLUE};
  }
`;

const inputLabelStyle = css`
  color: ${Colors.WINDOW_GRAY};
  position: absolute;
  pointer-events: none;
  transform: translate(1.125rem, 1.25rem) scale(1);
  transform-origin: top left;
  transition: 200ms ease-out 0ms;
  text-transform: capitalize;
`;

const inputStyle = css`
  height: 3.5rem;
  border: 0.125rem solid ${Colors.WINDOW_GRAY};
  padding: 28px 16px 12px 16px;

  position: absolute;
  background: none;
  outline: none;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: textfield;
`;

const errorStyle = css`
  color: ${Colors.ERROR};
  transform: translate(1.125rem, 62px) scale(1);
  transform-origin: top left;
  pointer-events: none;
  &:first-letter {
    text-transform: capitalize;
  }
`;

const eyeIconStyle = (type: string) => css`
  visibility: ${type === 'password' ? 'visibe' : 'hidden'};
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translate(-1rem, 1rem);
  cursor: pointer;
`;
