import { css } from '@emotion/css';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  Button,
  ButtonVariantEnum,
  ButtonSizeEnum,
} from '../../../shared/components/Button';
import {
  Divider,
  DividerVariantEnum,
} from '../../../shared/components/Divider';
import { SelectInput } from '../../../shared/components/SelectInput';
import { TextInput } from '../../../shared/components/TextInput';
import {
  useDeleteVehicle,
  useUpdateVehicle,
} from '../../../shared/services/api/vehicle';
import { h3Style } from '../../../shared/styles/text';
import {
  vehicleTypeLabels,
  VehicleTypeEnum,
} from '../../../shared/types/enums';
import { UpdateVehicleParams } from '../../../shared/types/input-param-types';
import { Vehicle } from '../../../shared/types/server-types';
import { useState } from 'react';
import { Colors } from '../../../shared/styles/colors';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {showPrivateToast} from "../../../shared/utils/show-private-toast";

const options = [
  {
    label: vehicleTypeLabels.CAMPING_VAN,
    value: VehicleTypeEnum.CAMPING_VAN,
  },
  {
    label: vehicleTypeLabels.BOAT,
    value: VehicleTypeEnum.BOAT,
  },
];

const validationSchema = yup.object().shape({
  id: yup.number().required(),
  type: yup.string().required(),
  registration: yup.string().required(),
  length: yup.number().when('type', {
    is: VehicleTypeEnum.BOAT,
    then: yup.number().required(),
  }),
});

interface IEditVehicleFormProps {
  vehicle: Vehicle;
  setModalVisible: (arg: boolean) => void;
}

export const EditVehicleForm = (props: IEditVehicleFormProps): JSX.Element => {
  const { vehicle, setModalVisible } = props;
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const methods = useForm({
    defaultValues: {
      id: vehicle.id || '',
      type: VehicleTypeEnum[vehicle.type] || '',
      registration: vehicle.registration || '',
      length: vehicle.length || '',
    },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const updateVehicle = useUpdateVehicle();
  const deleteVehicle = useDeleteVehicle();

  const vehicleType = methods.watch('type');

  const handler: SubmitHandler<UpdateVehicleParams> = async (
    data: UpdateVehicleParams,
  ): Promise<void> => {
    const { id, registration, type, length } = data;
    try {
      setUpdateLoading(true);
      const vehicleResponse = await updateVehicle({
        id: id,
        registration: registration,
        type: type,
        length: type === VehicleTypeEnum.BOAT ? length : 0,
      });
      if (vehicleResponse) {
        showPrivateToast(`Changes saved`);
      }
    } catch (err: any) {
      showPrivateToast('Something went wrong!');
    } finally {
      setUpdateLoading(false);
      setModalVisible(false);
    }
  };

  const handleDeleteVehicle = async () => {
    if (
      confirm(
        `Are you sure you want to delete vehicle ${vehicle.registration}?`,
      )
    ) {
      try {
        setDeleteLoading(true);
        const deleteVehicleResponse = await deleteVehicle(vehicle);
        if (deleteVehicleResponse) {
          showPrivateToast(`Vehicle ${vehicle.registration} deleted`);
        }
      } catch (err: any) {
        showPrivateToast('Something went wrong!');
      } finally {
        setDeleteLoading(false);
        setModalVisible(false);
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handler)} className={formStyle}>
        <h3 className={formHeaderStyle}>Edit Vehicle</h3>
        <SelectInput
          defaultValue={{
            label: vehicleTypeLabels[vehicle.type],
            value: VehicleTypeEnum[vehicle.type],
          }}
          name="type"
          placeHolder={'Vehicle Type'}
          options={options}
        />
        <TextInput name="registration" placeHolder="vehicle registration" />
        {vehicleType === VehicleTypeEnum.BOAT && (
          <TextInput
            name="length"
            placeHolder="boat length (m)"
            type="number"
          />
        )}
        <Divider variant={DividerVariantEnum.FULL_WIDTH_DIVIDER} />
        <div className={buttonContainerStyle}>
          <Button
            variant={ButtonVariantEnum.TEXT}
            size={ButtonSizeEnum.MEDIUM}
            text="cancel"
            className={buttonStyle}
            onClick={() => {
              setModalVisible(false);
            }}
          />
          <Button
            variant={ButtonVariantEnum.TEXT}
            size={ButtonSizeEnum.MEDIUM}
            text="delete"
            className={buttonStyle}
            color={Colors.ERROR}
            onClick={handleDeleteVehicle}
            loading={deleteLoading}
          />
          <Button
            variant={ButtonVariantEnum.TEXT}
            size={ButtonSizeEnum.MEDIUM}
            text="save"
            type={'submit'}
            className={buttonStyle}
            loading={updateLoading}
          />
        </div>
      </form>
    </FormProvider>
  );
};

const formStyle = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const formHeaderStyle = css`
  ${h3Style}
  margin-bottom: 1.5rem;
`;

const buttonContainerStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const buttonStyle = css`
  margin-right: 1rem;
`;
