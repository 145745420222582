import { css, cx } from '@emotion/css';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useInterval } from '../custom-hooks/custom-hooks';
import { Colors } from '../styles/colors';
import { doctrineStencilStyle } from '../styles/text';
import { getDurationFormat } from '../utils/duration';

type DurationTimerProps = {
  startTime: Date;
};

export const DurationTimer = (props: DurationTimerProps): JSX.Element => {
  const { startTime } = props;

  const diff = dayjs().diff(dayjs(startTime));
  const durr = dayjs.duration(diff);

  const durationFormat = useMemo(() => getDurationFormat(durr), [durr]);

  const [duration, setDuration] = useState<string>(durr.format(durationFormat));
  const [delay] = useState<number>(1000);

  useInterval(() => {
    const diff = dayjs().diff(dayjs(startTime));
    const durr = dayjs.duration(diff);
    setDuration(durr.format(durationFormat));
  }, delay);

  return (
    <div>
      <label>Duration</label>
      <div className={cx(doctrineStencilStyle, timerStyle)}>{duration}</div>
    </div>
  );
};

const timerStyle = css`
  min-width: '4rem';
  color: ${Colors.WHITE};
  background: #1d5883;
  padding: 0.25rem 0.5rem;
`;
