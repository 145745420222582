import { css } from '@emotion/css';
import { Layout } from '../../../shared/components/layout/Layout';
import { h1Style } from '../../../shared/styles/text';
import { useUser } from '../../../shared/providers/user-provider';
import { TextInput } from '../../../shared/components/TextInput';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../shared/components/Button';
import { ChevronLeft } from '../../../shared/assets/icons';
import { useNavigator } from '../../../routing/navigator';
import { useUpdateMyEmail } from '../../../shared/services/api/user';
import { UpdateMyEmailParams } from '../../../shared/types/input-param-types';
import { useState } from 'react';
import { Colors } from '../../../shared/styles/colors';
import {showPrivateToast} from "../../../shared/utils/show-private-toast";

const validationSchema: yup.SchemaOf<UpdateMyEmailParams> = yup
  .object()
  .shape({
    email: yup.string().required(),
  })
  .defined();

export const ChangeEmailPage = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigator = useNavigator();
  const user = useUser();
  const updateMyEmail = useUpdateMyEmail();
  const methods = useForm<UpdateMyEmailParams>({
    defaultValues: {
      email: user?.email || '',
    },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handlePersonalInformationUpdate: SubmitHandler<
    UpdateMyEmailParams
  > = async (data: UpdateMyEmailParams) => {
    try {
      setIsLoading(true);
      await updateMyEmail(data);
      navigator.toVerifyEmailPage();
    } catch (err: any) {
      showPrivateToast('Something went wrong.')
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout.Content className={emailPageStyle}>
      <Button
        startIcon={<ChevronLeft />}
        variant={ButtonVariantEnum.TEXT}
        size={ButtonSizeEnum.LARGE}
        text={`account`}
        className={backButtonStyle}
        onClick={navigator.toPrevious}
      />
      <div className={emailPageHeaderStyle}>
        <h1>Change Email</h1>
        <span className={subtitleStyle}>
          After you enter your new email address, you will have to verify it.
        </span>
      </div>
      <FormProvider {...methods}>
        <form
          className={formStyle}
          onSubmit={methods.handleSubmit(handlePersonalInformationUpdate)}
        >
          <TextInput
            placeHolder={'New email'}
            name="email"
            markAsRequired={true}
          />
          <Button
            disabled={isLoading}
            variant={ButtonVariantEnum.FILLED}
            size={ButtonSizeEnum.LARGE}
            fullWidth={true}
            text="next"
            type={'submit'}
            loading={isLoading}
            className={submitButtonStyle}
          />
        </form>
      </FormProvider>
    </Layout.Content>
  );
};

const formStyle = css`
  padding-top: 3.5rem;
`;

const emailPageStyle = css`
  padding-top: 0rem;
`;

const backButtonStyle = css`
  padding-top: 0rem;
  margin-bottom: 4rem;
`;

const subtitleStyle = css`
  color: ${Colors.WINDOW_GRAY};
`;

const submitButtonStyle = css`
  margin-top: 4rem;
`;

const emailPageHeaderStyle = css`
  h1 {
    ${h1Style}
    margin-bottom: 1rem;
  }
`;
