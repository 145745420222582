import { css, cx } from '@emotion/css';
import React, { useMemo } from 'react';
import { Colors } from '../styles/colors';
import { CONTENT_PADDING_WIDTH } from '../styles/css-values';

interface IDividerProps {
  className?: string;
  variant: DividerVariantEnum;
}

export enum DividerVariantEnum {
  FULL_WIDTH_DIVIDER = 'fullWidthDivider',
  LIST_DIVIDER = 'listDivider',
  FILTER_DIVIDER = 'filterDivider',
}

type DividerStyle = {
  fullWidthDivider: string;
  listDivider: string;
  filterDivider: string;
};

export const Divider: React.FC<IDividerProps> = (props: IDividerProps) => {
  const { className, variant } = props;
  const variantStyle = useMemo(() => dividerStyle[variant], []);

  return <div className={cx(variantStyle, className)}></div>;
};

const listDividerStyle = css`
  width: calc(100% + ${CONTENT_PADDING_WIDTH});
  background-color: ${Colors.LIGHT_GRAY};
  height: 1px;
`;

const fullWidthStyle = css`
  width: calc(100% + ${CONTENT_PADDING_WIDTH} + ${CONTENT_PADDING_WIDTH});
  background-color: ${Colors.LIGHT_GRAY};
  height: 2px;
  align-self: center;
`;

const filterDividerStyle = css`
  background-color: ${Colors.LIGHT_GRAY};
  height: 2px;
  align-self: center;
`;

export const dividerStyle: DividerStyle = {
  fullWidthDivider: fullWidthStyle,
  listDivider: listDividerStyle,
  filterDivider: filterDividerStyle,
};
