import { useEffect, useState } from 'react';
import { useLocation } from '../../shared/services/api/location';
import { css, cx } from '@emotion/css';
import { TabSwitch } from '../../shared/components/TabSwitch';
import InfoGroup from '../../shared/components/InfoGroup';
import { Colors } from '../../shared/styles/colors';
import {
  doctrineStencilStyle,
  h3Style,
  p1Style,
  p2Style,
  p3Style,
} from '../../shared/styles/text';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../shared/components/Button';
import { Link, useParams } from 'react-router-dom';
import { Layout } from '../../shared/components/layout/Layout';
import {
  ChevronDown,
  LinkIcon,
  MailIcon,
  MarexLogo,
  PhoneIcon,
  PowerIcon,
  WaterIcon,
} from '../../shared/assets/icons';
import { GoogleMap } from './GoogleMap';
import { Facility, Pedestal } from '../../shared/types/server-types';
import { useNavigator } from '../../routing/navigator';
import { appRoutes } from '../../routing/app-routes';
import { ArrowSmUpIcon } from '@heroicons/react/outline';
import { Divider, DividerVariantEnum } from '../../shared/components/Divider';
import { LocationLoadingState } from '../../shared/components/loading-states/LoactionLoadingState';
import { filterSources } from '../../shared/utils/filterSources';
import { http } from '../../shared/services/http';
import { CacheKeys } from '../../shared/services/api/cache-configuration';
import { useUser } from '../../shared/providers/user-provider';
import { useQueryClient } from 'react-query';

enum TabSwitchIndex {
  INFO = 0,
  PEDESTALS = 1,
}

export const LocationPage = (): JSX.Element => {
  const { locationId } = useParams<{ locationId: string }>();
  if (!locationId) throw new Error();
  const {
    data: location,
    isLoading,
    isError,
  } = useLocation({ id: locationId });
  const navigator = useNavigator();
  const user = useUser();
  const queryClient = useQueryClient();
  const [creatingAccount, setCreatingAccount] = useState<boolean>(false);

  const [selectedTab, setSelectedTab] = useState<number>(TabSwitchIndex.INFO);

  if (isError) {
    return <p>Error</p>;
  }

  if (isLoading || !location || creatingAccount) {
    return <LocationLoadingState />;
  }

  const {
    imageUrl: locationBackgroundImage,
    logoUrl,
    country,
    city,
    name,
    email,
    websiteUrl,
    phone,
    pedestals,
    constants,
    facilities,
    workingHours,
  } = location;
  const {
    berthCount,
    maxVehicleBeamLength,
    maxVehicleLength,
    portDepthRange,
    maxSocketSize,
    waterSocketSize,
  } = constants;

  const handleOpenPedestal = async (pedestal: Pedestal) => {
    try {
      setCreatingAccount(true);
      if (user && user.accounts.some((a) => a.location.id === location.id)) {
        navigator.toPedestal(pedestal.identifier);
      } else {
        await http.createAccount(location.id);
        await queryClient.refetchQueries([CacheKeys.ME]);
        navigator.toPedestal(pedestal.identifier);
        setCreatingAccount(false);
      }
    } finally {
      setCreatingAccount(false);
    }
  };

  const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  return (
    <>
      <header className={headerStyle(locationBackgroundImage)}>
        <Link to={appRoutes.landing}>
          <MarexLogo fill={Colors.WHITE} />
        </Link>
        <div className={locationContactInfoStyle}>
          {websiteUrl && (
            <span>
              <a href={`${websiteUrl}`} target="_blank" rel="noreferrer">
                <LinkIcon />
              </a>
            </span>
          )}
          {phone && (
            <span>
              <a href={`tel:${phone}`}>
                <PhoneIcon />
              </a>
            </span>
          )}
          {email && (
            <span>
              <a href={`mailto:${email}`}>
                <MailIcon />
              </a>
            </span>
          )}
        </div>
      </header>
      <Layout.Content className={locationPageStyle}>
        <div className={locationPageContainerStyle}>
          <figure className={locationNameLocationLogoStyle}>
            <img src={logoUrl} alt="" className={locationLogoStyle} />
            <figcaption>
              <span id={'locationName'}>{name}</span>
              <span id={'locationAddress'}>
                {city}, {country}
              </span>
            </figcaption>
          </figure>
          <div className={locationDescriptionStyle}>{location.description}</div>
          <TabSwitch
            tabs={['info', 'pedestals']}
            selectedIndex={selectedTab}
            setIndex={setSelectedTab}
          />
          {selectedTab === TabSwitchIndex.INFO && (
            <>
              <InfoGroup
                elements={[
                  {
                    label: 'number of berths',
                    value: berthCount ? `${berthCount}` : '-',
                  },
                  {
                    label: 'max length',
                    value: maxVehicleLength ? `${maxVehicleLength} m` : '-',
                  },
                  {
                    label: 'depth',
                    value: portDepthRange ? `${portDepthRange} m` : '-',
                  },
                  {
                    label: 'max beam',
                    value: maxVehicleBeamLength
                      ? `${maxVehicleBeamLength}m`
                      : '-',
                  },
                  {
                    label: 'max socket size',
                    value: maxSocketSize ? `${maxSocketSize}A` : '-',
                  },
                  {
                    label: 'water socket size',
                    value: waterSocketSize ? `${waterSocketSize}''` : '-',
                  },
                ]}
              />
              {facilities?.length > 0 && (
                <>
                  <Divider variant={DividerVariantEnum.FULL_WIDTH_DIVIDER} />
                  <div className={facilitiesStyle}>
                    <h3 className={cx(h3Style, facilitiesHeaderStyle)}>
                      Facilities
                    </h3>
                    <ul className={facilitiesListStyle}>
                      {facilities.map((facility: Facility, index) => (
                        <li key={index}>
                          <div className={facilityIcon}>
                            <DynamicIcon iconPath={facility.iconUrl} />
                          </div>
                          {facility.name}
                        </li>
                      ))}
                    </ul>
                    {/* <Button
                      variant={ButtonVariantEnum.TEXT}
                      size={ButtonSizeEnum.MEDIUM}
                      text={'see all'}
                      endIcon={<ChevronDown className={iconStyle} />}
                    /> */}
                  </div>
                </>
              )}

              <Divider variant={DividerVariantEnum.FULL_WIDTH_DIVIDER} />
              <div className={locationMapStyle}>
                <h3 className={cx(h3Style, locationHeaderStyle)}>Location</h3>
                {/*<div className={googleMapStyle}>*/}
                {/*  <GoogleMap />*/}
                {/*</div>*/}
                <div className={locationAddressStyle}>
                  <span id={'location-name'}>{location.name}</span>
                  <span>{location.address}</span>
                  <span>{`${location.postcode} ${location.city}`}</span>
                  <span>{location.country}</span>
                </div>
                <iframe
                  src={`https://maps.google.com/maps?q=${location.latitude},${location.longitude}&hl=en&z=14&amp&output=embed`}
                  allowFullScreen={true}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  className={embeddedMapStyle}
                ></iframe>
                {/*<div className={seeAllStyle}>*/}
                {/*  <a*/}
                {/*    href={`https://goo.gl/maps/YLYXJTJUS2EjEugZ9`}*/}
                {/*    target="_blank"*/}
                {/*    rel="noreferrer"*/}
                {/*  >*/}
                {/*    <Button*/}
                {/*      variant={ButtonVariantEnum.TEXT}*/}
                {/*      size={ButtonSizeEnum.LARGE}*/}
                {/*      text={'view on map'}*/}
                {/*      endIcon={<ArrowSmUpIcon className={openMapIconStyle} />}*/}
                {/*    />*/}
                {/*  </a>*/}
                {/*</div>*/}
              </div>
            </>
          )}
          {workingHours && (
            <>
              <Divider variant={DividerVariantEnum.FULL_WIDTH_DIVIDER} />
              <div className={openingHoursStyle}>
                <h3 className={cx(h3Style, openingHoursHeaderStyle)}>
                  Open hours
                </h3>
                <ul className={openingHoursListStyle}>
                  {dayNames.map((dayName: string, index) => (
                    <li key={index}>
                      <span id={'day-range'}>{dayName}</span>
                      <span id={'time-range'}>
                        {
                          workingHours.find((wh) => wh.dayOfTheWeek === index + 1)
                            ?.openingTime
                        }
                        -
                        {
                          workingHours.find((wh) => wh.dayOfTheWeek === index + 1)
                            ?.closingTime
                        }
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}
          {selectedTab === TabSwitchIndex.PEDESTALS && (
            <ul className={pedestalListStyle}>
              {pedestals.map((item, index) => {
                const { availablePower, availableWater } = filterSources(
                  item.valves,
                  item.sockets,
                );
                const { sockets, valves, identifier } = item;
                return (
                  <li
                    key={index}
                    onClick={() => handleOpenPedestal(item)}
                    className={pedestalCardLinkStyle}
                  >
                    <div className={listItemStyle}>
                      <div className={pedestalIdStyle}>{identifier}</div>
                      <div className={pedestalAvailabilityInfoStyle}>
                        <figure>
                          <PowerIcon />
                          <figcaption className={p2Style}>
                            {availablePower}/{sockets.length}
                          </figcaption>
                        </figure>
                        <figure>
                          <WaterIcon />
                          <figcaption className={p2Style}>
                            {availableWater}/{valves.length}
                          </figcaption>
                        </figure>
                      </div>
                    </div>
                    <Divider variant={DividerVariantEnum.LIST_DIVIDER} />
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </Layout.Content>
    </>
  );
};

const pedestalCardLinkStyle = css`
  cursor: pointer;
`;

const locationPageStyle = css`
  display: flex;
  flex-direction: column;
  padding-top: 0px;
`;

const locationPageContainerStyle = css`
  display: flex;
  flex-direction: column;
`;

const headerStyle = (locationBackgroundImage: string) => css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.25rem 1.5rem;
  width: 100%;
  height: 17.5625rem;
  margin-bottom: 1.5rem;
  background-image: linear-gradient(
      180deg,
      #1d5883 0%,
      rgba(29, 88, 131, 0) 51.25%
    ),
    url(${locationBackgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const locationContactInfoStyle = css`
  display: flex;
  justify-content: flex-end;
  > span {
    display: inline-flex;
    background: ${Colors.WHITE};
    padding: 0.25rem;
    margin-left: 0.5625rem;
    > a {
      display: flex;
      cursor: pointer;
    }
  }
`;

const locationNameLocationLogoStyle = css`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  > figcaption {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
    #locationName {
      width: 100%;
      ${doctrineStencilStyle}
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 29px;
      letter-spacing: 0px;
      text-align: left;
    }
    #locationAddress {
      ${p3Style}
      color: ${Colors.WINDOW_GRAY};
      text-transform: capitalize;
    }
  }
`;

const locationLogoStyle = css`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
`;

const locationDescriptionStyle = css`
  ${p1Style}
  margin-bottom: 1.5rem;
`;

const facilitiesStyle = css`
  margin-top: 1.5rem;
`;

const facilitiesHeaderStyle = css`
  margin-bottom: 1rem;
`;

const facilitiesListStyle = css`
  list-style: none;
  > li {
    display: flex;
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0.25rem;
    }
    > svg {
      margin-right: 1rem;
    }
  }
`;

const seeAllStyle = css`
  display: flex;
  align-content: center;
  align-items: center;
`;

const openMapIconStyle = css`
  color: ${Colors.CAPRI_BLUE};
  transform: rotate(45deg);
  width: 1.5rem;
  height: 1.5rem;
`;

const iconStyle = css`
  > path {
    stroke: ${Colors.CAPRI_BLUE};
  }
`;

const openingHoursStyle = css`
  margin-top: 1.5rem;
`;

const openingHoursHeaderStyle = css`
  margin-bottom: 1rem;
`;

const openingHoursListStyle = css`
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  > li {
    display: flex;
    justify-content: space-between;
    #day-range {
    }
    #time-range {
      color: ${Colors.CAPRI_BLUE};
    }
  }
`;

const locationMapStyle = css`
  margin-top: 1.5rem;
  margin-bottom: 6.25rem;
`;
const locationHeaderStyle = css`
  margin-bottom: 1rem;
`;

const googleMapStyle = css`
  margin-bottom: 1rem;
`;

const locationAddressStyle = css`
  display: flex;
  flex-direction: column;
  #location-name {
    color: ${Colors.CAPRI_BLUE};
  }
`;

const pedestalAvailabilityInfoStyle = css`
  ${p2Style}
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  figure {
    display: flex;
    justify-content: space-between;
    svg {
      margin-right: 0.25rem;
    }
    figcaption {
      width: 2rem;
      text-align: center;
    }
  }
`;

const pedestalIdStyle = css`
  ${p1Style}
`;

const pedestalListStyle = css`
  list-style: none;
  margin-top: 1.5rem;
  margin-bottom: 6.25rem;
`;

const listItemStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0rem;
`;

const embeddedMapStyle = css`
  margin-top: 2rem;
  width: 100%;
`;

const facilityIcon = css`
  margin-right: 8px;
`;

interface DynamicIconProps {
  iconPath: string;
}

const workingHoursListItemStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.5rem 0rem;
`;

// eslint-disable-next-line react/prop-types
const DynamicIcon: React.FC<DynamicIconProps> = ({ iconPath }) => {
  const [icon, setIcon] = useState<string | null>(null);

  useEffect(() => {
    const importIcon = async () => {
      try {
        const iconModule = await import(`../../assets${iconPath}`);
        setIcon(iconModule.default);
      } catch (error) {
        console.error();
      }
    };

    importIcon();
  }, [iconPath]);

  return (
    <div className="icon-container">
      {icon && <img src={icon} width="15" height="20" />}
    </div>
  );
};
