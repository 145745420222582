import { css } from '@emotion/css';
import { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../shared/components/Button';
import {
  Divider,
  DividerVariantEnum,
} from '../../../shared/components/Divider';
import { TextInput } from '../../../shared/components/TextInput';
import { h3Style } from '../../../shared/styles/text';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditEmployeeParams } from '../../../shared/types/input-param-types';
import { User } from '../../../shared/types/server-types';
import { Colors } from '../../../shared/styles/colors';
import {
  useDeleteEmployee,
  useEditEmployee,
} from '../../../shared/services/api/user';
import cleanDeep from 'clean-deep';

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  password: yup
    .string()
    .notRequired()
    .min(8, 'Password is too short')
    .nullable()
    .transform((value) => (value ? value : null)),
});

interface IEditEmployeeFormProps {
  user: User;
  setModalVisible: (arg: boolean) => void;
}

export const EditEmployeeForm = (
  props: IEditEmployeeFormProps,
): JSX.Element => {
  const { setModalVisible, user } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const methods = useForm<EditEmployeeParams>({
    defaultValues: {
      id: user.id,
      charterId: user.charter?.id,
      name: user.name,
      username: user.username,
      email: user.email,
      password: '',
    },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const editEmployee = useEditEmployee();
  const deleteEmployee = useDeleteEmployee();

  const handler: SubmitHandler<EditEmployeeParams> = async (
    data: EditEmployeeParams,
  ): Promise<void> => {
    try {
      setLoading(true);
      const employee = await editEmployee(
        cleanDeep(data, { emptyStrings: true }),
      );
      if (employee) {
        toast(`Changes saved`);
      }
    } catch (err: any) {
      toast('Something went wrong!');
    } finally {
      setLoading(false);
      setModalVisible(false);
    }
  };

  const handleDeleteEmployee = async () => {
    if (confirm(`Are you sure you want to delte user ${user.name}?`)) {
      try {
        setDeleteLoading(true);
        const deleteEmployeeResponse = await deleteEmployee(user.id);
        if (deleteEmployeeResponse) {
          toast(`User ${user.name} deleted`);
        }
      } catch (err: any) {
        toast('Something went wrong!');
      } finally {
        setDeleteLoading(false);
        setModalVisible(false);
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handler)} className={formStyle}>
        <h3 className={formHeaderStyle}>Edit Employee</h3>
        <TextInput name="name" placeHolder={'Employee name'} />
        <TextInput name="email" placeHolder={'Employee email'} type="email" />
        <TextInput
          name="username"
          placeHolder={'Employee username'}
          type="text"
        />
        <TextInput
          name="password"
          placeHolder={'New password'}
          type="password"
        />
        <Divider variant={DividerVariantEnum.FULL_WIDTH_DIVIDER} />
        <div className={buttonContainerStyle}>
          <Button
            variant={ButtonVariantEnum.TEXT}
            size={ButtonSizeEnum.MEDIUM}
            text="cancel"
            className={buttonStyle}
            color={Colors.GRAY}
            onClick={() => setModalVisible(false)}
          />
          <Button
            variant={ButtonVariantEnum.TEXT}
            size={ButtonSizeEnum.MEDIUM}
            text="delete"
            className={buttonStyle}
            color={Colors.ERROR}
            onClick={() => handleDeleteEmployee()}
            loading={deleteLoading}
          />
          <Button
            variant={ButtonVariantEnum.TEXT}
            size={ButtonSizeEnum.MEDIUM}
            text="save"
            type={'submit'}
            className={buttonStyle}
            loading={loading}
          />
        </div>
      </form>
    </FormProvider>
  );
};

const formStyle = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const formHeaderStyle = css`
  ${h3Style}
  margin-bottom: 1.5rem;
`;

const buttonContainerStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const buttonStyle = css`
  margin-right: 1rem;
`;
