import { css } from '@emotion/css';
import { InfoOutlineIcon } from '../../shared/assets/icons';
import { p2Style } from '../../shared/styles/text';

export const WalletInfo = (): JSX.Element => {
  return (
    <div className={infoOuterRootStyle}>
      <InfoOutlineIcon />
      <p className={p2Style}>
        You can also add funds at the front desk using cash or card.
      </p>
    </div>
  );
};

const infoOuterRootStyle = css`
  margin-top: 2rem;
`;
