export enum Colors {
  BLACK = '#333333',
  GRAY = '#757575',
  WHITE = '#ffffff',
  CAPRI_BLUE = '#1D5883',
  WINDOW_GRAY = '#9A9FA3',
  LIGHT_GRAY = '#EEEEEE',
  ERROR = '#FF6565',
  MONO_PHASE = '#1684DD',
  THREE_PHASE = '#DE373D',
  SOURCE_AVAILABLE = '#57D9A3',
  IN_USE = '#1684DD',
  PURPLE = '#5B21B6',
  BLUE = '#1E40AF',
  RED = '#DC2626',
}
