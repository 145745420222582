import { css } from '@emotion/css';
import { useNavigator } from '../../routing/navigator';
import { QrIcon } from '../assets/icons';
import { Colors } from '../styles/colors';
import { BOTTOM_NAV_HEIGHT } from '../styles/css-values';
import { labelStyle } from '../styles/text';
import { useLocation } from 'react-router-dom';
import { HeroIcon, IconName } from './HeroIcon';
import { useMemo } from 'react';

type NavigationItem = {
  navigate: () => void;
  icon: IconName;
  label: string;
  path: string;
};

type NavigationItemProps = {
  pathRoot: string;
};

enum NavigationBarLabel {
  HOME = 'home',
  HISTORY = 'history',
  SCAN = 'scan',
  WALLET = 'wallet',
  ACCOUNT = 'account',
}

const NavigationItem = (
  props: NavigationItem & NavigationItemProps,
): JSX.Element => {
  const { path, icon, label, navigate, pathRoot } = props;
  return (
    <div onClick={navigate}>
      <HeroIcon
        outline={path !== pathRoot}
        icon={icon}
        width={24}
        color={path === pathRoot ? Colors.CAPRI_BLUE : Colors.WINDOW_GRAY}
      />
      <span
        className={navLabelStyle}
        style={{
          color: path === pathRoot ? Colors.CAPRI_BLUE : Colors.WINDOW_GRAY,
        }}
      >
        {label}
      </span>
    </div>
  );
};

const navLabelStyle = css`
  ${labelStyle}
  font-weight: 700;
  text-transform: uppercase;
  color: ${Colors.WINDOW_GRAY};
  margin-top: 4px;
`;

export const BottomNavigationBar = (): JSX.Element => {
  const navigator = useNavigator();
  const location = useLocation();

  const currentPathRoot = useMemo(
    () => location.pathname.slice(1).split('/')[0],
    [location],
  );

  const navigationItems: NavigationItem[] = [
    {
      navigate: () => navigator.toLanding(),
      icon: 'HomeIcon',
      label: NavigationBarLabel.HOME,
      path: NavigationBarLabel.HOME,
    },
    {
      navigate: () => navigator.toHistory(),
      icon: 'ClockIcon',
      label: NavigationBarLabel.HISTORY,
      path: NavigationBarLabel.HISTORY,
    },
    {
      navigate: () => navigator.toScan(),
      icon: 'QrcodeIcon',
      label: NavigationBarLabel.SCAN,
      path: NavigationBarLabel.SCAN,
    },
    {
      navigate: () => navigator.toWallet(),
      icon: 'CreditCardIcon',
      label: NavigationBarLabel.WALLET,
      path: NavigationBarLabel.WALLET,
    },
    {
      navigate: () => navigator.toAccount(),
      icon: 'UserIcon',
      label: NavigationBarLabel.ACCOUNT,
      path: NavigationBarLabel.ACCOUNT,
    },
  ];

  return (
    <nav className={navbarStyle}>
      {navigationItems.map((item, index) =>
        item.label === 'scan' ? (
          <div key={`item-${index}`} id="qrCode" onClick={item.navigate}>
            <QrIcon className={qrIconStyle} />
          </div>
        ) : (
          <NavigationItem
            key={`item-${index}`}
            {...item}
            pathRoot={currentPathRoot}
          />
        ),
      )}
    </nav>
  );
};

const navbarStyle = css`
  display: grid;
  grid-template-columns: 1fr 1fr 4rem 1fr 1fr;
  position: fixed;
  z-index:100;
  bottom: 0px;
  height: ${BOTTOM_NAV_HEIGHT};
  width: 100%;
  background-color: ${Colors.WHITE};
  left: 0px;
  box-shadow: 0px -2px 24px 0px rgba(154, 159, 163, 0.25);
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  #qrCode {
    background-color: ${Colors.CAPRI_BLUE};
    transform: translateY(-32px) rotate(45deg);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px -2px 248px rgba(29, 88, 131, 0.25);
  }
`;

const qrIconStyle = css`
  transform: rotate(-45deg);
`;
