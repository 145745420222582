import { css } from '@emotion/css';
import { Colors } from '../../../shared/styles/colors';
import { labelStyle } from '../../../shared/styles/text';
import { Socket, Source } from '../../../shared/types/server-types';

//TODO: change Activate button labels color to window gray
export const sourceModalStyle = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 1.5rem 0px 1.5rem;
  width: 100%;
  box-shadow: 0px -2px 40px rgba(154, 159, 163, 0.25);
  label {
    ${labelStyle.toString()}
    color: ${Colors.GRAY};
    text-transform: capitalize;
  }
`;

export const pullTabStyle = css`
  align-self: center;
  width: 80px;
  height: 4px;
  background: #eeeeee;
  border-radius: 2px;
  margin-top: 8px;
  margin-bottom: 16px;
`;

export const modalHeaderStyle = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-transform: capitalize;
  margin-bottom: 1rem;

  > div > p {
    color: ${Colors.GRAY};
  }
`;

export const iconStyle = css`
  color: green;
  width: 3rem;
  height: 3rem;
  align-self: center;
`;

export const statusSignalsStyle = (source: Source): string => css`
  display: flex;
  flex-direction: row;
  width: 100%;
  span {
    border: 2px solid ${Colors.ERROR};
    padding: 8px;
    text-transform: capitalize;
    margin-right: 8px;
    transition: 0.5s ease-out;
  }
  span#available {
    ${source.state.activated === false &&
    css`
      border: 2px solid ${Colors.SOURCE_AVAILABLE};
    `}
  }
  span#plugged {
    ${(source as Socket).state.socketStable === true &&
    css`
      border: 2px solid ${Colors.SOURCE_AVAILABLE};
    `}
  }
  svg {
    align-self: center;
  }
`;

export const sourceInfoStyle = css`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 1rem 0px;
  div {
    width: 50%;
    margin: 0.5rem 0px;
    p {
      text-transform: capitalize;
    }
  }
`;

export const activationInfoStyle = css`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const activationInfoLabelStyle = css`
  width: 50%;
  display: flex;
  margin: 24px 0px;
`;

export const activateButtonStyle = css`
  min-width: 100%;
  margin-bottom: 32px;
`;
