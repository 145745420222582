import { css } from '@emotion/css';
import { useController, useFormContext } from 'react-hook-form';
import { Colors } from '../../shared/styles/colors';
import { h2Style } from '../../shared/styles/text';

interface IEnterCodeInputProps {
  name: string;
}

export const EnterCodeInput = (props: IEnterCodeInputProps): JSX.Element => {
  const { name } = props;
  const { control } = useFormContext();
  const { field } = useController({
    control,
    name,
  });

  return (
    <div className={codeInputContainerStyle}>
      <input className={codeInputStyle} {...field} type={'text'} />
    </div>
  );
};

const codeInputContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const codeInputStyle = css`
  ${h2Style}
  margin: 1.5rem;
  padding: 0.5rem 0;
  text-align: center;
  border: 2px solid ${Colors.CAPRI_BLUE};
  color: ${Colors.CAPRI_BLUE};
  flex: 1;
  -webkit-appearance: none;
  -moz-appearance: textfield;
`;
