import { css } from '@emotion/css';
import { useMemo, useState } from 'react';
import { useNavigator } from '../../routing/navigator';
import { QrIconBlue } from '../../shared/assets/icons';
import { useUser } from '../../shared/providers/user-provider';
import { Colors } from '../../shared/styles/colors';
import { SourceTypeEnum } from '../../shared/types/enums';
import {
  Pedestal,
  Socket,
  Source,
  SourceStatusEnum,
  Valve,
} from '../../shared/types/server-types';
import { SourceModal } from '../pedestal-page/source-modals/SourceModal';
import { ActivateMoreSocketCard } from './source-cards/ActivateMoreSocketCard';
import { ActivateMoreValveCard } from './source-cards/ActivateMoreValveCard';

interface IActivateMoreProps {
  sessionSourceType: SourceTypeEnum | undefined;
  pedestal: Pedestal;
}

export const ActivateMore = (props: IActivateMoreProps): JSX.Element => {
  const navigator = useNavigator();
  const { sessionSourceType, pedestal } = props;
  const [modalVisible, setModalIsVisible] = useState<boolean>(false);
  const user = useUser();

  const availableSources = () => {
    if (sessionSourceType === SourceTypeEnum.SOCKET) {
      const availableValve = pedestal.valves.find(
        (valve) => valve.status === SourceStatusEnum.AVAILABLE,
      );
      return availableValve as Source;
    } else {
      const availableSocket = pedestal.sockets.find(
        (socket) => socket.status === SourceStatusEnum.AVAILABLE,
      );
      const availablePluggedSocket = pedestal.sockets.find(
        (socket) => socket.status === SourceStatusEnum.PLUGGED_IN,
      );
      return (availablePluggedSocket as Source) ?? (availableSocket as Source);
    }
  };

  const activeModalSource = useMemo(
    () => availableSources(),
    [sessionSourceType, pedestal],
  );

  return (
    <div className={activateMoreStyle}>
      <h3>Activate more</h3>
      <div className={activateMoreContainerStyle}>
        {activeModalSource &&
          activeModalSource.type === SourceTypeEnum.SOCKET && (
            <ActivateMoreSocketCard
              setModalVisible={setModalIsVisible}
              socket={activeModalSource as Socket}
              pedestal={pedestal}
            />
          )}
        {activeModalSource &&
          activeModalSource.type === SourceTypeEnum.VALVE && (
            <ActivateMoreValveCard
              setModalVisible={setModalIsVisible}
              valve={activeModalSource as Valve}
              pedestal={pedestal}
            />
          )}
        <div
          id="scanCard"
          className={activateBoxStyle}
          onClick={navigator.toScan}
        >
          <div>
            <h4>Scan</h4>
          </div>
          <div>
            <QrIconBlue />
          </div>
        </div>
      </div>
      <SourceModal
        isVisible={modalVisible}
        setIsVisible={setModalIsVisible}
        user={user!}
        pedestal={pedestal}
        source={activeModalSource}
      />
    </div>
  );
};

const activateMoreStyle = css`
  width: 100%;
`;
const activateMoreContainerStyle = css`
  display: flex;
  flex-wrap: wrap;

  > div#scanCard {
    border-color: ${Colors.CAPRI_BLUE};
  }

  > div {
    width: 50%;
    margin: 0.5rem 0px;
    border-width: 2px 2px 2px 12px;
    border-style: solid;
    padding: 0.6rem;
    flex: 1;
    display: flex;

    &:nth-child(odd) {
      margin-right: 0.5rem;
    }

    &:nth-child(even) {
      margin-left: 0.5rem;
    }

    h4 {
      color: #1d5883;
    }
  }
`;

const activateBoxStyle = css`
  cursor: pointer;
  div:first-child {
    width: 50%;
  }

  div:nth-child(2) {
    width: 50%;
    text-align: right;
  }
`;
