import { useQuery, UseQueryResult } from 'react-query';
import { useNavigator } from '../../../routing/navigator';
import { locationStore } from '../../stores/location-store';
import {
  GetLocationInputParams,
  LocationSearchParams,
} from '../../types/input-param-types';
import { Location } from '../../types/server-types';
import { showPrivateToast } from '../../utils/show-private-toast';
import { http } from '../http';
import { CacheKeys } from './cache-configuration';

export const useLocation = (
  params: GetLocationInputParams,
): UseQueryResult<Location> => {
  const navigator = useNavigator();
  return useQuery(CacheKeys.LOCATION, () => http.getLocation(params), {
    onError: (error: any) => {
      if (error.request.status === 404) {
        locationStore.reset();
        navigator.toLanding();
        showPrivateToast('Location no longer exist, please try again');
      }
    },
  });
};

export const useLocations = (
  params?: LocationSearchParams,
): UseQueryResult<Location[]> => {
  return useQuery([CacheKeys.LOCATIONS, params ? params.term : ''], () =>
    http.getLocations(params),
  );
};
