import { useQuery, UseQueryResult } from 'react-query';
import { GetPedestalInputParams } from '../../types/input-param-types';
import { Pedestal } from '../../types/server-types';
import { http } from '../http';
import { CacheKeys } from './cache-configuration';

export const usePedestal = (
  params: GetPedestalInputParams,
): UseQueryResult<Pedestal> => {
  return useQuery(CacheKeys.PEDESTAL, () => http.getPedestal(params), {
    refetchInterval: 5000,
  });
};
