import React from 'react';
import { css, cx } from '@emotion/css';
import { CONTENT_PADDING_WIDTH } from '../../styles/css-values';

interface ContentProps {
  className?: string;
}

export const Content: React.FC<ContentProps> = ({ children, className }) => {
  return <div className={cx(contentContainerStyle, className)}>{children}</div>;
};

const contentContainerStyle = css`
  max-width: 100%;
  margin: 0 auto;
  padding: 2rem ${CONTENT_PADDING_WIDTH};

  @media (min-width: 24rem) {
    max-width: 24rem;
  }
`;
