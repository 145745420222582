import { css, cx } from '@emotion/css';
import { useMemo } from 'react';
import { Colors } from '../../../../shared/styles/colors';
import { h1Style, h4Style, valueStyle } from '../../../../shared/styles/text';
import { ChartTabSwitchEnum } from '../../../../shared/types/enums';
import { ChartDataParams, StatsBarChart } from '../StatsBarChart';
import { getTotalAmount } from '../utils/ChartDataUtils';

const dataYear: ChartDataParams[] = [
  {
    label: '1',
    amount: 1000,
  },
  {
    label: '2',
    amount: 1000,
  },
  {
    label: '3',
    amount: 500,
  },
  {
    label: '4',
    amount: 500,
  },
  {
    label: '5',
    amount: 500,
  },
  {
    label: '6',
    amount: 500,
  },
  {
    label: '7',
    amount: 500,
  },
  {
    label: '8',
    amount: 500,
  },
  {
    label: '9',
    amount: 500,
  },
  {
    label: '10',
    amount: 500,
  },
  {
    label: '11',
    amount: 500,
  },
  {
    label: '12',
    amount: 500,
  },
];

const years = [2021, 2020, 2019];

export const YearTabView: React.FC = () => {
  const totalAmunt = useMemo(() => getTotalAmount(dataYear), [dataYear]);

  return (
    <>
      {years.map((year) => (
        <div className={chartContainerStyle} key={year}>
          <h4 className={h4Style}>{year}</h4>
          <StatsBarChart data={dataYear} viewType={ChartTabSwitchEnum.YEARLY} />
          <div className={totalInfoChartContainerStyle}>
            <p className={cx(valueStyle, valueTextStyle)}>Total for {year}</p>
            <h1 className={h1Style}>{totalAmunt} EUR</h1>
          </div>
        </div>
      ))}
    </>
  );
};

const chartContainerStyle = css`
  display: flex;
  flex-flow: column;
  align-items: start;
  min-width: calc(100% - 1.5rem);
  height: 300px;
  background-color: ${Colors.LIGHT_GRAY};
  padding: 1rem;
  gap: 1rem;
`;

const valueTextStyle = css`
  color: ${Colors.WINDOW_GRAY};
`;

const totalInfoChartContainerStyle = css`
  display: flex;
  flex-flow: column;
  align-items: start;
  gap: 0.25rem;
`;
