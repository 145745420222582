import { css } from '@emotion/css';
import { Layout } from '../../../shared/components/layout/Layout';
import { h1Style } from '../../../shared/styles/text';
import { useUser } from '../../../shared/providers/user-provider';
import { TextInput } from '../../../shared/components/TextInput';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectInput } from '../../../shared/components/SelectInput';
import { SupportedCountries } from '../../../shared/types/enums';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../shared/components/Button';
import { ChevronLeft } from '../../../shared/assets/icons';
import { useNavigator } from '../../../routing/navigator';
import { useUpdateMe } from '../../../shared/services/api/user';
import { UpdateMeParams } from '../../../shared/types/input-param-types';
import { useState } from 'react';
import {showPrivateToast} from "../../../shared/utils/show-private-toast";

const validationSchema: yup.SchemaOf<UpdateMeParams> = yup
  .object()
  .shape({
    name: yup.string().required(),
    phoneNumber: yup.string().nullable(),
    country: yup.string().nullable(),
  })
  .defined();

export const PersonalInformationPage = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigator = useNavigator();
  const user = useUser();
  const updateUser = useUpdateMe();
  const methods = useForm<UpdateMeParams>({
    defaultValues: {
      name: user?.name || '',
      country: user?.country || null,
      phoneNumber: user?.phoneNumber || null,
    },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handlePersonalInformationUpdate: SubmitHandler<UpdateMeParams> = async (
    data: UpdateMeParams,
  ) => {
    try {
      setIsLoading(true);
      await updateUser(data);
      showPrivateToast('Information updated');
      navigator.toAccount();
    } catch (err: any) {
      console.error('Something went wrong.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout.Content className={personalInformationPageStyle}>
      <Button
        startIcon={<ChevronLeft />}
        variant={ButtonVariantEnum.TEXT}
        size={ButtonSizeEnum.LARGE}
        text={`account`}
        className={backButtonStyle}
        onClick={navigator.toPrevious}
      />
      <div className={personalInformationPageHeaderStyle}>
        <h1>
          Personal
          <br />
          information
        </h1>
      </div>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handlePersonalInformationUpdate)}>
          <TextInput
            placeHolder={'your name'}
            name="name"
            markAsRequired={true}
          />
          <TextInput
            placeHolder={'your phone'}
            name="phoneNumber"
            type={'tel'}
            markAsRequired={true}
          />
          <SelectInput
            placeHolder={'your country'}
            name="country"
            markAsRequired={true}
            options={[
              {
                value: SupportedCountries.CROATIA,
                label: 'Croatia',
              },
            ]}
          />
          <Button
            disabled={isLoading}
            variant={ButtonVariantEnum.FILLED}
            size={ButtonSizeEnum.LARGE}
            fullWidth={true}
            text="save"
            type={'submit'}
            loading={isLoading}
          />
        </form>
      </FormProvider>
    </Layout.Content>
  );
};

const personalInformationPageStyle = css`
  padding-top: 0.625rem;
`;

const backButtonStyle = css`
  padding-top: 0rem;
`;

const personalInformationPageHeaderStyle = css`
  h1 {
    ${h1Style}
    margin-bottom: 1.5rem;
  }
`;
