import { css } from '@emotion/css';
import { MarexLogo } from '../../assets/icons';
import { HEADER_HEIGHT } from '../../styles/css-values';
import { Colors } from '../../styles/colors';
import { Layout } from './Layout';
import { appRoutes } from '../../../routing/app-routes';
import { Link } from 'react-router-dom';

export const Header = (): JSX.Element => {
  return (
    <header className={headerStyle}>
      <Layout.Content className={headerContentStyle}>
        <Link to={appRoutes.landing} className={linkStyle}>
          <MarexLogo />
        </Link>
      </Layout.Content>
    </header>
  );
};

const headerStyle = css`
  box-sizing: border-box;
  height: ${HEADER_HEIGHT};
  background-color: ${Colors.WHITE};
`;

const headerContentStyle = css`
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  height: 100%;
`;

const linkStyle = css`
  display: flex;
  cursor: pointer;
`;
