import { css } from '@emotion/css';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigator } from '../../../routing/navigator';
import { QuestionmarkCircle } from '../../../shared/assets/icons';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../shared/components/Button';
import { Layout } from '../../../shared/components/layout/Layout';
import { useLocation } from '../../../shared/services/api/location';
import { handlePaymentSubmit } from '../../../shared/services/payment/payment';
import {
  BOTTOM_NAV_HEIGHT,
  HEADER_HEIGHT,
} from '../../../shared/styles/css-values';
import {
  doctrineStencilStyle,
  h1Style,
  p2Style,
} from '../../../shared/styles/text';
import { CurrencyAmountSelection } from '../../payment-page/CurrencyAmountSelection';
import { http } from '../../../shared/services/http';
import { useCurrentUser } from '../../../shared/services/api/user';

export const WalletAddFundsToLocationPage: React.FC = () => {
  const navigator = useNavigator();
  const { locationId } = useParams<{ locationId: string }>();
  const [amount, setAmount] = useState<string>('0');
  const [proceedToPayWaiting, setProceedToPayWaiting] =
    useState<boolean>(false);
  const {
    data: user,
    isLoading: userIsLoading,
    isError: userIsError,
  } = useCurrentUser();

  if (!locationId) throw new Error();

  const {
    data: location,
    isLoading,
    isError,
  } = useLocation({ id: locationId });

  if (isError || userIsError) {
    console.error({ isError, userIsError });
    return <p>Error</p>;
  }

  if (isLoading || !location || userIsLoading) {
    return <p>Loading...</p>;
  }

  return (
    <Layout.Content>
      <div className={innerContainerStyle}>
        <div className={infoContainerStyle}>
          <h1>
            Add funds to your{' '}
            <span className={doctrineStencilStyle}>{location.name}</span>{' '}
            account.
          </h1>
          <p>
            Funds are refundable, but are not transferrable between accounts.
          </p>
        </div>
        <Button
          size={ButtonSizeEnum.MEDIUM}
          variant={ButtonVariantEnum.TEXT}
          text={`more info`}
          startIcon={<QuestionmarkCircle />}
        />
        <CurrencyAmountSelection setAmount={setAmount} />
      </div>
      <div className={buttonActionContainerStyle}>
        <Button
          size={ButtonSizeEnum.LARGE}
          variant={ButtonVariantEnum.TEXT}
          text={'back'}
          onClick={navigator.toPrevious}
        />
        <Button
          size={ButtonSizeEnum.LARGE}
          variant={ButtonVariantEnum.FILLED}
          text={'proceed to pay'}
          onClick={async () => {
            if (!proceedToPayWaiting) {
              setProceedToPayWaiting(true);
              if (user) {
                const account = user.accounts?.find(
                  (a) => a.location.id === location.id,
                );
                if (!account) {
                  await http.createAccount(location.id);
                  setProceedToPayWaiting(false);
                }
              }
              await handlePaymentSubmit(amount, location.id);
              setProceedToPayWaiting(false);
            }
          }}
        />
      </div>
    </Layout.Content>
  );
};

const innerContainerStyle = css`
  display: flex;
  flex-flow: column;
  align-items: start;
  gap: 1em;
  margin-top: calc(6.375rem - ${HEADER_HEIGHT});
  min-height: calc(100vh - 6.375rem - ${HEADER_HEIGHT} - ${BOTTOM_NAV_HEIGHT});
`;

const infoContainerStyle = css`
  h1 {
    ${h1Style}
  }
  p {
    ${p2Style}
    margin-top: 1rem;
  }
`;

const buttonActionContainerStyle = css`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
