import { css, cx } from '@emotion/css';
import { useMemo } from 'react';
import { Colors } from '../../../../shared/styles/colors';
import { h1Style, h4Style, valueStyle } from '../../../../shared/styles/text';
import { ChartTabSwitchEnum } from '../../../../shared/types/enums';
import { ChartDataParams, StatsBarChart } from '../StatsBarChart';
import { getTotalAmount } from '../utils/ChartDataUtils';

const dataWeek: ChartDataParams[] = [
  {
    label: 'Mon',
    amount: 1000,
  },
  {
    label: 'Tue',
    amount: 1000,
  },
  {
    label: 'Wed',
    amount: 750,
  },
  {
    label: 'Thu',
    amount: 500,
  },
  {
    label: 'Fri',
    amount: 500,
  },
  {
    label: 'Sat',
    amount: 750,
  },
  {
    label: 'Sun',
    amount: 500,
  },
];

const weeks = [
  'February 12 - 19, 2021',
  'February 12 - 19, 2021',
  'February 12 - 19, 2021',
];

export const WeekTabView: React.FC = () => {
  const totalAmunt = useMemo(() => getTotalAmount(dataWeek), [dataWeek]);

  return (
    <>
      {weeks.map((week, index) => (
        <div key={index} className={chartContainerStyle}>
          <h4 className={h4Style}>{week}</h4>
          <StatsBarChart data={dataWeek} viewType={ChartTabSwitchEnum.WEEKLY} />
          <div className={totalInfoChartContainerStyle}>
            <p className={cx(valueStyle, valueTextStyle)}>Total for {week}</p>
            <h1 className={h1Style}>{totalAmunt} EUR</h1>
          </div>
        </div>
      ))}
    </>
  );
};

const chartContainerStyle = css`
  display: flex;
  flex-flow: column;
  align-items: start;
  min-width: calc(100% - 1.5rem);
  height: 300px;
  background-color: ${Colors.LIGHT_GRAY};
  padding: 1rem;
  gap: 1rem;
`;

const valueTextStyle = css`
  color: ${Colors.WINDOW_GRAY};
`;

const totalInfoChartContainerStyle = css`
  display: flex;
  flex-flow: column;
  align-items: start;
  gap: 0.25rem;
`;
