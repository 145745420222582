import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { TextInput } from '../../shared/components/TextInput';
import { useSignUpUser } from '../../shared/services/api/user';
import { css, cx } from '@emotion/css';
import { Colors } from '../../shared/styles/colors';
import {
  SignUpFormParams,
  SignUpParams,
} from '../../shared/types/input-param-types';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../shared/components/Button';
import { h1Style, p2Style } from '../../shared/styles/text';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Layout } from '../../shared/components/layout/Layout';
import { useNavigator } from '../../routing/navigator';
import { StepComponentProps } from '../../shared/components/Stepper';
import { useEffect } from 'react';
import { cleanObject } from 'tiny-clean-object';
import { VehicleTypeEnum } from '../../shared/types/enums';
import { authenticationStore } from '../../shared/stores/authentification-store';
import { observer } from 'mobx-react';
import { TermsConfirmation } from './TermsConfirmation';
import { SelectInput } from '../../shared/components/SelectInput';
import { HEADER_HEIGHT } from '../../shared/styles/css-values';

const validationSchema: yup.SchemaOf<SignUpParams> = yup
  .object()
  .shape({
    name: yup.string().required(),
    password: yup.string().required().min(8, 'Password is too short'),
    phoneNumber: yup.string().required(),
    country: yup.string().required(),
    vehicleType: yup.string(),
    vehicleRegistration: yup.string().nullable(),
    vehicleLength: yup.number().typeError('Must be a valid number'),
    acceptedTermsAndConditions: yup
      .boolean()
      .oneOf([true], 'You must accept the terms and conditions!')
      .required('You must accept the terms and conditions!'),
  })
  .defined();

export const SignUpStep = observer((props: StepComponentProps): JSX.Element => {
  const signUpUser = useSignUpUser();
  const navigator = useNavigator();
  const { setCurrentStep } = props;
  const authData = authenticationStore.getAuthenticationData();

  /**
   * Redirect in case of manually entered URL.
   */
  useEffect(() => {
    const email = authData?.email;
    if (!email) {
      navigator.toCheckEmail();
    }
  }, []);

  const methods = useForm<SignUpFormParams>({
    defaultValues: {
      email: authData?.email || '',
      country: authData?.country || '',
      name: authData?.name || '',
      password: '',
      phoneNumber: authData?.phoneNumber || '',
      acceptedTermsAndConditions: authData?.acceptedTermsAndConditions
        ? authData.acceptedTermsAndConditions
        : false,
      vehicleRegistration: authData?.vehicleRegistration || '',
      vehicleType: authData?.vehicleType || '',
      vehicleLength: authData?.vehicleLength,
    },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const vehicleTypeSelected = methods.watch('vehicleType');

  /**
   * Handle the sign-up logic for the user
   * @param data
   */
  const handleSignUpUser: SubmitHandler<SignUpFormParams> = async (
    data: SignUpFormParams,
  ) => {
    try {
      const email = authData?.email;
      const { acceptedTermsAndConditions, password, ...publicAuthData } = data;
      authenticationStore.setAuthenticationData({
        ...publicAuthData,
        acceptedTermsAndConditions,
      });
      const cleanDataForSubmit = cleanObject({
        ...publicAuthData,
        password,
      }) as SignUpFormParams;
      if (!email) {
        navigator.toCheckEmail();
      } else {
        await signUpUser({ ...cleanDataForSubmit, email });
        setCurrentStep(1);
      }
    } catch (err: any) {
      console.error('Something went wrong.');
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSignUpUser)}>
        <Layout.Content className={formContentStyle}>
          <div>
            <h1 className={cx(h1Style, headerStyle)}>
              No existing account found. Create it now.
            </h1>
            <p className={cx(p2Style, paragraphStyle)}>
              Fields with an asterix (
              <span style={{ color: Colors.ERROR }}>*</span>) are required.
            </p>
            <div>
              <TextInput
                placeHolder={'your name'}
                name="name"
                markAsRequired={true}
              />
              <TextInput
                placeHolder={'password'}
                name="password"
                type={'password'}
                markAsRequired={true}
              />
              <TextInput
                placeHolder={'your phone'}
                name="phoneNumber"
                type={'tel'}
                markAsRequired={true}
              />
              <TextInput
                placeHolder={'country'}
                name="country"
                type={'text'}
                markAsRequired={true}
              />
              <SelectInput
                placeHolder="Vehicle type"
                name="vehicleType"
                options={[
                  {
                    value: VehicleTypeEnum.BOAT,
                    label: 'Boat',
                  },
                  {
                    value: VehicleTypeEnum.CAMPING_VAN,
                    label: 'Camping van',
                  },
                ]}
              />
              {vehicleTypeSelected && (
                <TextInput
                  placeHolder={'vehicle registration'}
                  name="vehicleRegistration"
                />
              )}
              {vehicleTypeSelected === VehicleTypeEnum.BOAT && (
                <TextInput
                  placeHolder={'vehicle length'}
                  name="vehicleLength"
                  type="number"
                />
              )}
              {/* TODO: link privacy policy and terms of use */}
              <TermsConfirmation name="acceptedTermsAndConditions" />
            </div>
          </div>
          <div className={buttonContainerStyle}>
            <Button
              variant={ButtonVariantEnum.TEXT}
              size={ButtonSizeEnum.LARGE}
              text={'back'}
              onClick={navigator.toPrevious}
            />
            <Button
              variant={ButtonVariantEnum.FILLED}
              size={ButtonSizeEnum.LARGE}
              text={'next'}
              type={'submit'}
            />
          </div>
        </Layout.Content>
      </form>
    </FormProvider>
  );
});

const formContentStyle = css`
  min-height: calc(100vh - ${HEADER_HEIGHT});
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const headerStyle = css`
  margin-bottom: 1rem;
`;

const paragraphStyle = css`
  color: ${Colors.WINDOW_GRAY};
  margin-bottom: 2rem;
`;

const buttonContainerStyle = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
