import { css } from '@emotion/css';
import { Location } from '../../../shared/types/server-types';
import { h3Style, labelStyle } from '../../../shared/styles/text';
import { Colors } from '../../../shared/styles/colors';
import { useNavigator } from '../../../routing/navigator';

interface ILocationCardProps {
  location: Location;
}

export const LocationCard = (props: ILocationCardProps): JSX.Element => {
  const { location } = props;
  const navigator = useNavigator();

  return (
    <figure
      className={containerStyle}
      onClick={() => {
        navigator.toLocation(location.id);
      }}
    >
      <img className={imageStyle} src={location.thumbnailUrl} alt={location.name} />
      <figcaption className={nameStyle}>{location.name}</figcaption>
      <span className={addressStyle}>
        {location.city}, {location.country}
      </span>
    </figure>
  );
};

const containerStyle = css`
  margin-bottom: 2rem;
  cursor: pointer;
`;

const imageStyle = css`
  width: 100%;
  height: 11.5rem;
  margin-bottom: 1rem;
`;

const nameStyle = css`
  ${h3Style}
  color: ${Colors.CAPRI_BLUE};
`;

const addressStyle = css`
  ${labelStyle}
  color: ${Colors.GRAY};
`;
