import { useMemo } from 'react';
import { PowerIcon, QuestionmarkCircle } from '../../../shared/assets/icons';
import { h1Style, p1Style } from '../../../shared/styles/text';
import { powerLabels, sourceLabels } from '../../../shared/types/enums';
import { Account, Pedestal, Socket } from '../../../shared/types/server-types';
import { convertEURToHRK } from '../../../shared/utils/currency-conversion';
import {
  iconStyle,
  modalHeaderStyle,
  sourceInfoStyle,
  statusSignalsStyle,
} from './modal-styles';

interface ISocketModalProps {
  socket: Socket;
  pedestal: Pedestal;
  account: Account;
}

export const SocketModalContent = (props: ISocketModalProps): JSX.Element => {
  const { socket, pedestal, account } = props;
  const { current, position, phase, type, state } = socket;
  const { identifier, location } = pedestal;
  //tarif should be from account, fallback on location.constants
  const { constants: locationConstants } = location;
  const { powerTariff } = account;

  const tariff = useMemo(() => {
    return powerTariff
      ? powerTariff
      : locationConstants
      ? locationConstants.powerTariff
      : 0;
  }, [locationConstants, powerTariff]);

  return (
    <>
      <div className={modalHeaderStyle}>
        <div>
          <p className={p1Style}>{identifier.toUpperCase()}</p>
          <h1 className={h1Style}>
            {sourceLabels[type]} {position}
          </h1>
        </div>
        <PowerIcon className={iconStyle} />
      </div>
      <div className={statusSignalsStyle(socket)}>
        <span id="available">
          {!state.activated ? 'available' : 'not available'}
        </span>
        <span id="plugged">
          {state.socketStable ? 'plugged in' : 'not pluged in'}
        </span>
        <QuestionmarkCircle />
      </div>
      <div className={sourceInfoStyle}>
        <div>
          <label>phases</label>
          <p>{powerLabels[phase]}</p>
        </div>
        <div>
          <label>current</label>
          <p>{current ? `${current}A` : 'Info not available'}</p>
        </div>
        <div>
          <label>tariff</label>
          <p>
            {tariff} EUR/kWh ({convertEURToHRK(tariff)} HRK/kWh)
          </p>
        </div>
      </div>
    </>
  );
};
