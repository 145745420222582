import * as yup from 'yup';

import { FormProvider, useForm } from 'react-hook-form';
import React, { useCallback } from 'react';

import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../shared/components/Button';
/** @jsxImportSource @emotion/react */
import { TextInput } from '../../shared/components/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { http } from '../../shared/services/http';
import { useNavigator } from '../../routing/navigator';

interface IForgotPasswordForm {
  email: string;
}

const schema = yup.object().shape({
  email: yup.string().email().required('Email is required'),
});

export const ForgotPasswordForm: React.FC = () => {
  const methods = useForm<IForgotPasswordForm>({
    defaultValues: {
      email: '',
    },
    shouldUseNativeValidation: false,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });
  const navigator = useNavigator();

  const onSubmit = useCallback(
    methods.handleSubmit(async (data) => {
      try {
        await http.forgotPassword(data);
        navigator.toEmailSignIn();
      } catch (e) {
        console.error(e);
      }
    }),
    [],
  );
  const errors = Object.values(methods.formState.errors);

  return (
    <form onSubmit={onSubmit}>
      <FormProvider {...methods}>
        <div>
          <TextInput name="email" placeHolder="Email" type="email" />
        </div>
        <div>
          {errors.map((e) => (
            <span key={e.message}>{e.message}</span>
          ))}
        </div>

        <Button
          variant={ButtonVariantEnum.FILLED}
          size={ButtonSizeEnum.LARGE}
          text="Send me an email"
          type="submit"
          onClick={onSubmit}
        />
      </FormProvider>
    </form>
  );
};
