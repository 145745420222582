import { useMemo } from 'react';
import { QuestionmarkCircle, WaterIcon } from '../../../shared/assets/icons';
import { h1Style, p1Style } from '../../../shared/styles/text';
import { sourceLabels } from '../../../shared/types/enums';
import {
  Account,
  Pedestal,
  SourceStatusEnum,
  Valve,
} from '../../../shared/types/server-types';
import { convertEURToHRK } from '../../../shared/utils/currency-conversion';
import {
  iconStyle,
  modalHeaderStyle,
  sourceInfoStyle,
  statusSignalsStyle,
} from './modal-styles';

interface IValveModalProps {
  valve: Valve;
  pedestal: Pedestal;
  account: Account;
}

export const ValveModalContent = (props: IValveModalProps): JSX.Element => {
  const { valve, pedestal, account } = props;
  const { status, position, type } = valve;
  const { identifier, location } = pedestal;
  const { constants: locationConstants } = location;
  const { waterTariff } = account;

  const tariff = useMemo(() => {
    return waterTariff
      ? waterTariff
      : locationConstants
      ? locationConstants.waterTariff
      : 0;
  }, [locationConstants, waterTariff]);

  return (
    <>
      <div className={modalHeaderStyle}>
        <div>
          <p className={p1Style}>ID {identifier}</p>
          <h1 className={h1Style}>
            {sourceLabels[type]} {position}
          </h1>
        </div>
        <WaterIcon className={iconStyle} />
      </div>
      <div className={statusSignalsStyle(valve)}>
        <span id="available">
          {status === SourceStatusEnum.AVAILABLE
            ? 'available'
            : 'not available'}
        </span>
        <QuestionmarkCircle />
      </div>
      <div className={sourceInfoStyle}>
        <div>
          <label>tariff</label>
          <p>
            {tariff} EUR/m3 ({convertEURToHRK(tariff)} HRK/m3)
          </p>
        </div>
        <div>
          <label>Connection size</label>
          <p>{valve.connectionSize} inch</p>
        </div>
      </div>
    </>
  );
};
