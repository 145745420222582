import * as yup from 'yup';

import { FormProvider, useForm } from 'react-hook-form';
import React, { useCallback } from 'react';

import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../shared/components/Button';
/** @jsxImportSource @emotion/react */
import { TextInput } from '../../shared/components/TextInput';
import { yupResolver } from '@hookform/resolvers/yup';
import { http } from '../../shared/services/http';
import { useNavigator } from '../../routing/navigator';
import { useParams } from 'react-router-dom';
import { showPublicToast } from '../../shared/utils/show-public-toast';

interface IResetPasswordForm {
  password: string;
  passwordConfirm: string;
}

const schema = yup.object().shape({
  password: yup.string().min(10).required('username is required'),
  passwordConfirm: yup
    .string()
    .required('Please retype your password.')
    .oneOf([yup.ref('password')], 'Your passwords do not match.'),
});

export const ResetPasswordForm: React.FC = () => {
  const methods = useForm<IResetPasswordForm>({
    defaultValues: {
      password: '',
      passwordConfirm: '',
    },
    shouldUseNativeValidation: false,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });
  const navigator = useNavigator();
  const { passwordResetToken } = useParams<{ passwordResetToken: string }>();

  const onSubmit = useCallback(
    methods.handleSubmit(async (data) => {
      try {
        await http.resetPassword({
          password: data.password,
          passwordResetToken: passwordResetToken || '',
        });
        navigator.toEmailSignIn();
      } catch (e) {
        showPublicToast("Link expired. Request a new one.")
        console.error(e);
      }
    }),
    [],
  );
  const errors = Object.values(methods.formState.errors);

  return (
    <form onSubmit={onSubmit}>
      <FormProvider {...methods}>
        <div>
          <TextInput name="password" placeHolder="Password" type="password" />
          <TextInput
            name="passwordConfirm"
            placeHolder="Confirm password"
            type="password"
          />
        </div>
        <div>
          {errors.map((e) => (
            <span key={e.message}>{e.message}</span>
          ))}
        </div>

        <Button
          variant={ButtonVariantEnum.FILLED}
          size={ButtonSizeEnum.LARGE}
          text="Set password"
          type="submit"
          onClick={onSubmit}
        />
      </FormProvider>
    </form>
  );
};
