import { Layout } from '../../shared/components/layout/Layout';
import { css } from '@emotion/css';
import { h1Style } from '../../shared/styles/text';
import { PlusFilledIcon } from '../../shared/assets/icons';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../shared/components/Button';
import { WalletInfo } from './WalletInfo';
import { useNavigator } from '../../routing/navigator';
import { useUser } from '../../shared/providers/user-provider';
import { AccountCard } from './AccountCard';
import { Account, Location } from '../../shared/types/server-types';
import { useLocations } from '../../shared/services/api/location';
import { useMemo } from 'react';
import { Loader } from '../../shared/components/Loader';
import { BOTTOM_NAV_HEIGHT } from '../../shared/styles/css-values';
import { UserRole } from '../../shared/types/enums';
import { NoPermission } from '../../shared/components/NoPermission';

export const WalletPage = (): JSX.Element => {
  const navigator = useNavigator();
  const { data: locations, isLoading, isError } = useLocations();
  const user = useUser()!;

  const unvisitedLocations = useMemo(() => {
    const visitedLocationsIds = user.accounts.map((account: Account) => {
      return account.location.id;
    });
    return locations?.filter(
      (location: Location) => !visitedLocationsIds.includes(location.id),
    );
  }, [locations]);

  if (isError) {
    return <p>Something went wrong</p>;
  }

  if (isLoading || !unvisitedLocations) {
    return <Loader />;
  }

  return (
    <Layout.Content className={containerStyle}>
      <div className={pageTitleContainerStyle}>
        <h1 className={h1Style}>Wallet</h1>
        {unvisitedLocations.length > 0 && !user.charter && (
          <Button
            startIcon={<PlusFilledIcon />}
            variant={ButtonVariantEnum.TEXT}
            size={ButtonSizeEnum.LARGE}
            text={`new`}
            onClick={navigator.toWalletAddFunds}
          />
        )}
      </div>
      {user.role === UserRole.CHARTER_EMPLOYEE ? (
        <NoPermission />
      ) : (
        <>
          {user.accounts.map((account: Account) => (
            <AccountCard key={account.id} account={account} />
          ))}
          <WalletInfo />
        </>
      )}
    </Layout.Content>
  );
};

const containerStyle = css`
  margin-bottom: calc(${BOTTOM_NAV_HEIGHT} + 2rem);
  padding-top: 0.75rem;
`;

const pageTitleContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
