import { useMemo, useState } from 'react';
import { css } from '@emotion/css';
import { Colors } from '../../shared/styles/colors';
import {
  Session,
  Source,
  Socket,
  SessionGroup,
} from '../../shared/types/server-types';
import {
  PowerTypeEnum,
  sourceLabels,
  SourceTypeEnum,
} from '../../shared/types/enums';
import { OutlineX, PowerIcon, WaterIcon } from '../../shared/assets/icons';
import { h2Style, p2Style } from '../../shared/styles/text';
import { useInterval } from '../../shared/custom-hooks/custom-hooks';
import dayjs from 'dayjs';
import { getDurationFormat } from '../../shared/utils/duration';
import { Info } from '../../shared/components/Info';
import { Oval } from 'react-loader-spinner';
import { convertEURToHRK } from '../../shared/utils/currency-conversion';

interface ISessionCardProps {
  session: Session;
  sessionGroup: SessionGroup;
  showVehicle: boolean;
  handleStopSession: (val: Session, sourcePositon: number) => Promise<void>;
  refetchSessions: () => Promise<any>;
}

export const SessionCard = (props: ISessionCardProps): JSX.Element => {
  const {
    session,
    showVehicle,
    refetchSessions,
    handleStopSession,
    sessionGroup,
  } = props;
  const { position: sourcePositon, state } = session.source;
  const { identifier: pedestalId } = session.source.pedestal;
  const [endingSession, setEndingSession] = useState<boolean>(false);
  const diff = dayjs(session.endedAt || undefined).diff(
    dayjs(session.createdAt),
  );
  const durr = dayjs.duration(diff);

  const durationFormat = useMemo(() => getDurationFormat(durr), [durr]);
  const [duration, setDuration] = useState<string>(durr.format(durationFormat));
  const [delay] = useState<number>(1000);

  useInterval(() => {
    const diff = dayjs(session.endedAt || undefined).diff(
      dayjs(session.createdAt),
    );
    const durr = dayjs.duration(diff);
    setDuration(durr.format(durationFormat));
  }, delay);

  const determineColorStyle = (source: Source) => {
    if (source.type === SourceTypeEnum.SOCKET) {
      if ((source as Socket).phase === PowerTypeEnum.SINGLE)
        return `${Colors.MONO_PHASE}`;
      else return `${Colors.THREE_PHASE}`;
    }
    return `${Colors.CAPRI_BLUE}`;
  };

  const colorStyle = useMemo(() => determineColorStyle(session.source), []);

  const endSession = async () => {
    if (
      !confirm(
        `Are you sure you want to end your ${session.source.type} ${sourcePositon} session?`,
      )
    ) {
      return;
    }
    setEndingSession(true);
    await handleStopSession(session, sourcePositon);
    setEndingSession(false);
    await refetchSessions();
  };

  const calculateTotal = () => {
    const tariff =
      session.source.type === SourceTypeEnum.SOCKET
        ? sessionGroup.account?.powerTariff ||
          sessionGroup.location.constants.powerTariff
        : sessionGroup.account?.waterTariff ||
          sessionGroup.location.constants.waterTariff;
    const consumption = session.source.state.value - session.initialMeterState;
    return (tariff * consumption).toFixed(2);
  };

  const total = useMemo(() => calculateTotal(), [sessionGroup]);

  return (
    <div className={sessionCardStyle(colorStyle)}>
      <div className={sessionCardHeaderStyle(colorStyle)}>
        <div>
          <p>{pedestalId.toUpperCase()}</p>
          <h2>
            {sourceLabels[session.source.type]} {sourcePositon}
          </h2>
        </div>
        {session.source.type === SourceTypeEnum.SOCKET && <PowerIcon />}
        {session.source.type === SourceTypeEnum.VALVE && <WaterIcon />}
      </div>
      <div className={sessionInfoSectionStyle}>
        {session.source.type === SourceTypeEnum.SOCKET && (
          <Info
            label="consumption"
            value={`${(state.value - session.initialMeterState).toFixed(
              3,
            )} kWh`}
          />
        )}
        {session.source.type === SourceTypeEnum.VALVE && (
          <Info
            label="consumption"
            value={`${(state.value - session.initialMeterState).toFixed(
              3,
            )} m\u00B3`}
          />
        )}
        <Info label="duration" value={duration} />
        <Info
          label="total"
          value={`${total} EUR (${convertEURToHRK(parseFloat(total))} HRK)`}
        />
        {showVehicle && (
          <Info label="vehicle" value={session.vehicle.registration} />
        )}
      </div>
      <div onClick={endSession} className={closeButtonStyle(colorStyle)}>
        {endingSession ? (
          <Oval height={30} width={30} color={'white'} />
        ) : (
          <OutlineX />
        )}
      </div>
    </div>
  );
};

const sessionCardStyle = (colorStyle: string) => css`
  position: relative;
  border-left: 12px solid ${colorStyle};
  background-color: ${colorStyle}1A;
  align-self: center;
  padding: 1rem 1rem 0.5rem 0.75rem;
  margin-bottom: 2rem;
  width: calc((100%) + 3rem);
`;

const sessionCardHeaderStyle = (colorStyle: string) => css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;

  > div > p {
    ${p2Style}
    color: ${Colors.GRAY};
  }
  > div > h2 {
    ${h2Style}
  }

  > svg {
    width: 3rem;
    height: 3rem;
    align-self: center;

    path {
      fill: ${colorStyle};
    }
  }
`;

const sessionInfoSectionStyle = css`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0px;
`;

const closeButtonStyle = (colorStyle: string) => css`
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-color: ${colorStyle};
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: space-around;
  cursor: pointer;

  > svg {
    align-self: center;
  }
`;
