import {toast} from "react-toastify";
import {css} from "@emotion/css";

const containerStyle = css`
  clip-path: polygon(
    0 0,
    100% 0,
    100% 100%,
    100% 100%,
    55% 100%,
    50% 70%,
    45% 100%,
    0 100%
  );
`

export const showPrivateToast = (message: string): void => {
  toast(message, {
    className: containerStyle
  })
}
