import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { SignUpFormParams } from '../types/input-param-types';

class AuthenticationStore {
  authenticationData: Partial<SignUpFormParams> | null = null;
  userIsVerified: boolean = false;

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'authenticationData',
      properties: ['authenticationData'],
      storage: window.sessionStorage,
    });
    makePersistable(this, {
      name: 'userIsVerified',
      properties: ['userIsVerified'],
      storage: window.sessionStorage,
    });
  }

  setAuthenticationData(value: Partial<SignUpFormParams>) {
    this.authenticationData = value;
  }

  getAuthenticationData() {
    return this.authenticationData;
  }

  setUserIsVerified(value: boolean) {
    this.userIsVerified = value;
  }

  getUserIsVerified() {
    return this.userIsVerified;
  }

  reset() {
    this.authenticationData = null;
    this.userIsVerified = false;
  }
}

export const authenticationStore = new AuthenticationStore();
