import { css, cx } from '@emotion/css';
import { Colors } from '../styles/colors';
import { fieldStyle, labelStyle } from '../styles/text';
import { useController, useFormContext } from 'react-hook-form';
import { CloseX, Search } from '../assets/icons';
import { useState } from 'react';

interface ISearchInputProps {
  placeHolder: string;
  hint: string;
  name: string;
}

export const SearchInput = (props: ISearchInputProps): JSX.Element => {
  const { control, watch } = useFormContext();
  const [inputFocused, setInputFocused] = useState(false);
  const { placeHolder, hint, name, } = props;
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
  });
  const userInput = watch(name);

  return (
    <div className={SearchInputStyle(userInput, error)}>
      <input
        {...field}
        className={userInput !== '' ? inputHasValueStyle : inputStyle}
        onBlur={() => setInputFocused(false)}
        onFocus={() => setInputFocused(true)}
      />
      <label className={cx(fieldStyle, inputLabelStyle)}>
        {inputFocused ? placeHolder : hint}
      </label>
      {inputFocused ?
        <CloseX className={suffixIconStyle} /> :
        <Search className={suffixSearchIconStyle} />
      }

      {error && (
        <span className={cx(labelStyle, errorStyle)}>{error.message}</span>
      )}
    </div>
  );
};

const SearchInputStyle = (userInput: any, error: any) => css`
  width: 100%;
  height: 5.25rem;
  position: relative;
  display: flex;
  flex-direction: column;

  &:focus-within label {
    ${labelStyle}
    transform: translate(1.125rem, 0.75rem) scale(1);
    color: ${Colors.CAPRI_BLUE};
  }
  label {
    ${userInput &&
  css`
      ${labelStyle}
      transform: translate(1.125rem, 0.75rem) scale(1);
      color: ${Colors.WINDOW_GRAY};
    `}
  }
  input {
    ${error &&
  css`
      border-color: ${Colors.ERROR};
    `}
  }

 
  &:focus-within input {
    border: 0.125rem solid ${Colors.CAPRI_BLUE};
  }
`;

const inputLabelStyle = css`
  color: ${Colors.BLACK};
  position: absolute;
  pointer-events: none;
  transform: translate(0rem, 1.25rem) scale(1);
  transform-origin: top left;
  transition: 200ms ease-out 0ms;
  font-style: normal;
  line-height: .5;
  font-weight: bold;
  font-size: 2rem;
  text-transform: capitalize;
`;

const inputStyle = css`
  height: 3.5rem;
  border:  none;
  padding: 28px 16px 12px 16px;
  position: absolute;
  background: none;
  outline: none;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: textfield;
`;

const inputHasValueStyle = css`
  height: 3.5rem;
  border: 0.125rem solid ${Colors.CAPRI_BLUE};
  padding: 28px 16px 12px 16px;
  position: absolute;
  background: none;
  outline: none;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: textfield;
`;

const errorStyle = css`
  color: ${Colors.ERROR};
  transform: translate(1.125rem, 62px) scale(1);
  transform-origin: top left;
  pointer-events: none;
  &:first-letter {
    text-transform: capitalize;
  }
`;

const suffixSearchIconStyle = css`
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translate(-1rem, 1rem);
  cursor: pointer;
  pointer-events: none;
`;
const suffixIconStyle = css`
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translate(-1rem, 1rem);
  cursor: pointer;
`;
