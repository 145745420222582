import { css } from '@emotion/css';
import { Colors } from '../../shared/styles/colors';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../shared/components/Button';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { CheckIfPedestalExistsParams } from '../../shared/types/input-param-types';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { EnterCodeInput } from './EnterCodeInput';
import { useEffect } from 'react';
interface EnterCodeModalProps {
  visible: boolean;
  setVisible(value: boolean): void;
  onSubmit(value: string | number): void;
}

const validationSchema = yup.object().shape({
  id: yup.string().required(),
});

export const EnterCodeModal = (props: EnterCodeModalProps): JSX.Element => {
  const { visible, setVisible, onSubmit } = props;
  const methods = useForm<CheckIfPedestalExistsParams>({
    defaultValues: { id: '' },
    resolver: yupResolver(validationSchema),
  });

  const handler: SubmitHandler<CheckIfPedestalExistsParams> = async (
    data: CheckIfPedestalExistsParams,
  ): Promise<void> => {
    onSubmit(data.id);
  };

  useEffect(() => {
    visible && methods.setFocus('id');
  }, [visible]);

  return (
    <FormProvider {...methods}>
      <form
        className={visible ? modalContainerStyle : notVisibleStyle}
        onSubmit={methods.handleSubmit(handler)}
      >
        <EnterCodeInput name="id" />
        <hr />
        <div className={buttonContainerStyle}>
          <Button
            variant={ButtonVariantEnum.TEXT}
            size={ButtonSizeEnum.MEDIUM}
            text="cancel"
            onClick={() => setVisible(false)}
            className={buttonStyle}
          />
          <Button
            variant={ButtonVariantEnum.TEXT}
            size={ButtonSizeEnum.MEDIUM}
            text="confirm"
            type={'submit'}
            className={buttonStyle}
          />
        </div>
      </form>
    </FormProvider>
  );
};

const notVisibleStyle = css`
  display: none;
`;

const modalContainerStyle = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  background-color: ${Colors.WHITE};
  border-radius: 0.5rem;
  z-index: 3;
`;

const buttonContainerStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const buttonStyle = css`
  margin-right: 1rem;
`;
