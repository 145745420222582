import {css} from '@emotion/css';
import {InformationCircleIcon} from '@heroicons/react/outline';
import {Colors} from '../../shared/styles/colors';
import {p2Style} from '../../shared/styles/text';
import {Button, ButtonSizeEnum, ButtonVariantEnum} from "../../shared/components/Button";
import {useNavigator} from "../../routing/navigator";

export const PaymentInfoTextCharter = (): JSX.Element => {
  return (
    <div className={infoContainerStyle}>
      <InformationCircleIcon className={iconStyle} />
      <p className={infoTextStyle}>
        You can pay at the front desk using cash or card.
      </p>
    </div>
  );
};

export const HowToAddMoneyText = (): JSX.Element => {
  const navigator = useNavigator()
  return (
    <div className={infoContainerStyle}>
      <InformationCircleIcon className={iconStyle} />
      <p className={infoTextStyle}>
        You can add money to your account at the front desk or you can try adding money in the wallet screen.
      </p>
        <Button variant={ButtonVariantEnum.TEXT} size={ButtonSizeEnum.MEDIUM} text={'Open Wallet'} onClick={() => navigator.toWallet()}/>
    </div>
  );
};

export const PaymentInfoTextPostpaid = (): JSX.Element => {
  return (
    <div className={infoContainerStyle}>
      <InformationCircleIcon className={iconStyle} />
      <p className={infoTextStyle}>
        You can also pay at the front desk using cash or card.
      </p>
    </div>
  );
};

export const NoAccountText = (): JSX.Element => {
  return (
    <div className={noAccountTextStyle}>
      <p className={infoTextStyle}>
        {`You don't have an account for this marina.`}
      </p>
    </div>
  );
};

const infoContainerStyle = css`
  margin-bottom: 4rem;
`;
const iconStyle = css`
  width: 1.5rem;
  color: ${Colors.CAPRI_BLUE};
`;
const infoTextStyle = css`
  ${p2Style}
`;

const noAccountTextStyle = css`
  margin-bottom: 2rem;
`;
