import { css, cx } from '@emotion/css';
import {
  doctrineStencilStyle,
  h1Style,
  p1Style,
} from '../../../shared/styles/text';
import { Colors } from '../../../shared/styles/colors';
import { locationStore } from '../../../shared/stores/location-store';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../shared/components/Button';
import { observer } from 'mobx-react';
import { useUser } from '../../../shared/providers/user-provider';
import { useNavigator } from '../../../routing/navigator';

export const HeroSection = observer(() => {
  const navigator = useNavigator();
  const user = useUser();
  const currentLocation = locationStore.getLocation();

  return (
    <section className={containerStyle}>
      {currentLocation && user && (
        <div>
          <h1 className={locationNameStyle}>{currentLocation.name}</h1>
          <h1 className={cx(titleStyle, bottomSpacing)}>is ready for you</h1>
          <p className={descriptionStyle}>
            Go to the nearest supply point and scan the QR code.
          </p>
          <div className={buttonsContainerStyle}>
            <Button
              variant={ButtonVariantEnum.FILLED}
              size={ButtonSizeEnum.LARGE}
              text="Scan Now"
              onClick={navigator.toScan}
              fullWidth
              gutterbottom
            />
            <Button
              variant={ButtonVariantEnum.OUTLINE}
              size={ButtonSizeEnum.LARGE}
              text="Marina Info"
              onClick={() => navigator.toLocation(currentLocation.id)}
              fullWidth
            />
          </div>
        </div>
      )}
      {currentLocation && !user && (
        <div>
          <h1 className={titleStyle}>Welcome to</h1>
          <h1 className={cx(locationNameStyle, bottomSpacing)}>
            {currentLocation.name}
          </h1>
          <p className={descriptionStyle}>
            We offer a simple and quick way to activate water and power supply.
          </p>
          <div className={buttonsContainerStyle}>
            <Button
              variant={ButtonVariantEnum.FILLED}
              size={ButtonSizeEnum.LARGE}
              text="Start Now"
              onClick={navigator.toCheckEmail}
              fullWidth
              gutterbottom
            />
            <Button
              variant={ButtonVariantEnum.OUTLINE}
              size={ButtonSizeEnum.LARGE}
              text="Marina Info"
              onClick={() => navigator.toLocation(currentLocation.id)}
              fullWidth
            />
          </div>
        </div>
      )}
      {!currentLocation && (
        <div>
          <h1 className={titleStyle}>
            We offer a simple and quick way to activate water and power supply.
          </h1>
          <div className={buttonsContainerStyle}>
            <Button
              variant={ButtonVariantEnum.FILLED}
              size={ButtonSizeEnum.LARGE}
              text={user ? 'Scan Now' : 'Start Now'}
              onClick={() => {
                user ? navigator.toScan() : navigator.toCheckEmail();
              }}
              fullWidth
            />
          </div>
        </div>
      )}
    </section>
  );
});

const containerStyle = css`
  width: 100%;
`;

const titleStyle = css`
  ${h1Style}
  color: ${Colors.CAPRI_BLUE};
`;

const locationNameStyle = css`
  ${doctrineStencilStyle}
  color: ${Colors.CAPRI_BLUE};
`;

const descriptionStyle = css`
  ${p1Style}
`;

const buttonsContainerStyle = css`
  margin-top: 2rem;
  margin-bottom: 10rem;
`;

const bottomSpacing = css`
  margin-bottom: 1rem;
`;
