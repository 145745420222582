import { css, cx } from '@emotion/css';
import React from 'react';
import { Colors } from '../styles/colors';
import { labelStyle } from '../styles/text';

interface iconAtributes {
  className?: string;
}
export interface IInfoProps {
  label: string;
  value: string;
  className?: string;
  iconClassName?: string;
  Icon?: React.FC<iconAtributes>;
}

export const Info = (props: IInfoProps): JSX.Element => {
  const { label, value, className, Icon, iconClassName } = props;
  return (
    <div className={cx(infoStyle, className)}>
      <div className={infoContainerStyle}>
        <span className={infoLabelStyle}>{label}</span>
        <p className={infoValueStyle}>{value}</p>
      </div>
      {Icon && <Icon className={cx(iconStyle, iconClassName)} />}
    </div>
  );
};

const infoStyle = css`
  display: flex;
  width: calc((100% - 0.25rem) / 2);
  margin: 0.5rem 0px;
  > svg {
  }
`;

const iconStyle = css`
  margin-left: 0.875rem;
`;

const infoContainerStyle = css``;

const infoLabelStyle = css`
  ${labelStyle}
  text-transform: capitalize;
  color: ${Colors.GRAY};
`;
const infoValueStyle = css``;
