import { css, cx } from '@emotion/css';
import {
  CheckCircleOutline,
  CheckCircleSolidInverted,
} from '../../shared/assets/icons';
import { Colors } from '../../shared/styles/colors';
import { button1Style, p2Style } from '../../shared/styles/text';
import { convertEURToHRK } from '../../shared/utils/currency-conversion';

interface ICurrencyAmountCardProps {
  onClick: any;
  amount: number;
  estimatedTime: string;
  isSelected: boolean;
}

export const CurrencyAmountCard = (
  props: ICurrencyAmountCardProps,
): JSX.Element => {
  const { onClick, amount, estimatedTime, isSelected } = props;
  return isSelected ? (
    <div
      className={cx(unselectedCurrencyCardStyle, selectedCurrencyCardStyle)}
      onClick={onClick}
    >
      <div>
        <span>
          {amount} EUR ({convertEURToHRK(amount)} HRK)
        </span>
        <CheckCircleSolidInverted className={iconStyle} />
      </div>
      <span>{estimatedTime}</span>
    </div>
  ) : (
    <div className={unselectedCurrencyCardStyle} onClick={onClick}>
      <div>
        <span>
          {amount} EUR ({convertEURToHRK(amount)} HRK)
        </span>
        <CheckCircleOutline />
      </div>
      <span>{estimatedTime}</span>
    </div>
  );
};

export const unselectedCurrencyCardStyle = css`
  border: 0.125rem solid ${Colors.CAPRI_BLUE};
  padding: 0.75rem 1rem;
  > div {
    ${button1Style}
    color: ${Colors.CAPRI_BLUE};
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  > span {
    ${p2Style}
    color: ${Colors.WINDOW_GRAY}
  }
`;

export const selectedCurrencyCardStyle = css`
  background-color: ${Colors.CAPRI_BLUE};
  > div {
    color: ${Colors.WHITE};
  }
  > span {
    color: ${Colors.LIGHT_GRAY};
  }
`;

const iconStyle = css`
  fill: ${Colors.WHITE};
`;
