import { css } from '@emotion/css';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../shared/components/Button';
import {
  Divider,
  DividerVariantEnum,
} from '../../../shared/components/Divider';
import { TextInput } from '../../../shared/components/TextInput';
import { h3Style } from '../../../shared/styles/text';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { NewEmployeeParams } from '../../../shared/types/input-param-types';
import { useAddEmployee } from '../../../shared/services/api/user';
import { useUser } from '../../../shared/providers/user-provider';
import cleanDeep from 'clean-deep';
import { Colors } from '../../../shared/styles/colors';
import { showPrivateToast } from '../../../shared/utils/show-private-toast';

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().when('withoutEmail', {
    is: (withoutEmail: boolean) => !withoutEmail,
    then: yup.string().email().required(),
    otherwise: yup.string().optional(),
  }),
  username: yup.string().when('withoutEmail', {
    is: (withoutEmail: boolean) => withoutEmail,
    then: yup.string().required().min(1),
    otherwise: yup.string().optional(),
  }),
  password: yup.string().when('withoutEmail', {
    is: (withoutEmail: boolean) => withoutEmail,
    then: yup.string().required().min(8, 'Password is too short'),
    otherwise: yup.string().optional(),
  }),
});

interface INewEmployeeFormProps {
  setModalVisible: (arg: boolean) => void;
}

export const NewEmployeeForm = (props: INewEmployeeFormProps): JSX.Element => {
  const { setModalVisible } = props;
  const user = useUser()!;
  const [loading, setLoading] = useState<boolean>(false);
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const methods = useForm<NewEmployeeParams>({
    defaultValues: {
      withoutEmail: false,
      name: '',
      username: '',
      email: '',
      charterId: user?.ownedCharter?.id,
      country: user?.country,
      password: '',
    },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const email = methods.watch('email');
  const username = methods.watch('username');
  const withoutEmail = methods.watch('withoutEmail');

  useEffect(() => {
    if (email.length > 0) {
      methods.setValue('withoutEmail', false);
      methods.trigger('username');
      methods.trigger('email');
    } else {
      if (!firstRender) {
        methods.setValue('withoutEmail', true);
      } else {
        setFirstRender(false);
      }
    }
  }, [email, username]);

  const addEmployee = useAddEmployee();

  const handler: SubmitHandler<NewEmployeeParams> = async (
    data: NewEmployeeParams,
  ): Promise<void> => {
    try {
      setLoading(true);
      if (data.password.length > 0 && !withoutEmail) {
        data.password = '';
      }
      const response = await addEmployee(
        cleanDeep(data, { emptyStrings: true }),
      );
      if (response) {
        if (data.email.length > 0) {
          toast(`Employee ${data.name} invited`);
        } else {
          toast(`Employee created`);
        }
      }
    } catch (err: any) {
      if (err.message.includes('409')) {
        showPrivateToast('User with that email or username already exists!');
      } else {
        showPrivateToast('Something went wrong!');
      }
    } finally {
      setLoading(false);
      setModalVisible(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handler)} className={formStyle}>
        <h3 className={formHeaderStyle}>New Employee</h3>
        <TextInput name="name" placeHolder={'Employee name'} />
        <div className={formInfoStyle}>Enter email and/or username</div>
        <TextInput name="email" placeHolder={'Employee email'} type="email" />
        <TextInput
          name="username"
          placeHolder={'Employee username'}
          type="text"
        />
        {withoutEmail && (
          <TextInput name="password" placeHolder={'Password'} type="password" />
        )}
        <Divider variant={DividerVariantEnum.FULL_WIDTH_DIVIDER} />
        <div className={buttonContainerStyle}>
          <Button
            variant={ButtonVariantEnum.TEXT}
            size={ButtonSizeEnum.MEDIUM}
            text="cancel"
            className={buttonStyle}
            color={Colors.GRAY}
            onClick={() => setModalVisible(false)}
          />
          <Button
            variant={ButtonVariantEnum.TEXT}
            size={ButtonSizeEnum.MEDIUM}
            text={withoutEmail ? 'Create employee' : 'Send invite'}
            type={'submit'}
            className={buttonStyle}
            loading={loading}
          />
        </div>
      </form>
    </FormProvider>
  );
};

const formStyle = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const formHeaderStyle = css`
  ${h3Style}
  margin-bottom: 1.5rem;
`;

const formInfoStyle = css`
  align-self: center;
  margin-bottom: 0.5rem;
`;

const buttonContainerStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const buttonStyle = css`
  margin-right: 1rem;
`;
