import {
  AccountTypeEnum,
  LocationType,
  PaymentTypeEnum,
  PowerTypeEnum,
  ReceiptStatusEnum,
  SourceTypeEnum,
  UserRole,
  VehicleTypeEnum,
} from './enums';

export type Facility = {
  name: string;
  iconUrl: string;
};

export type OpenHoursEntry = {
  day: string;
  open: string;
  close: string;
};

export type OpenHours = {
  entries: OpenHoursEntry[];
};

export type LocationConstants = {
  waterTariff: number;
  powerTariff: number;
  openHours: OpenHours;
  portDepthRange: string;
  maxSocketSize: string;
  waterSocketSize: string;
  maxVehicleLength: number;
  maxVehicleBeamLength: number;
  berthCount: number;
  defaultPaymentType?: PaymentTypeEnum;
};

export type LocationWorkingHours = {
  dayOfTheWeek: number;
  openingTime: number;
  closingTime: OpenHours;
};

export type Location = {
  id: number;
  type: LocationType;
  name: string;
  description: string;
  country: string;
  city: string;
  address: string;
  postcode: string;
  logoUrl: string;
  imageUrl: string;
  thumbnailUrl: string;
  websiteUrl: string;
  phone: string;
  email: string;
  latitude: number;
  longitude: number;
  pedestals: Pedestal[];
  facilities: Facility[];
  constants: LocationConstants;
  workingHours: LocationWorkingHours[];
};

export type Vehicle = {
  id: number;
  length?: number;
  registration: string;
  type: VehicleTypeEnum;
};

export type User = {
  username?: string;
  id: number;
  email?: string;
  name: string;
  password: string;
  phoneNumber: string | null;
  country: string | null;
  funds: number;
  vehicles: Vehicle[];
  accounts: Account[];
  charter?: Charter;
  ownedCharter?: Charter;
  role: UserRole;
  verified: boolean;
};

export interface Charter {
  id: number;
  owner: User;
  employees?: User[];
  dedicatedPedestals?: Pedestal[];
  deletedAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
}

export type Token = {
  token: string;
};

export type SourceState = {
  activated: boolean;
  modbusStable: boolean;
  fusesStable: boolean;
  socketStable: boolean;
  value: number;
};

export type Valve = {
  id: number;
  position: number;
  type: SourceTypeEnum;
  connectionSize: string;
  pedestal: Pedestal;
  state: SourceState;
  status: SourceStatusEnum;
  activeSession: Session;
};

export enum SourceStatusEnum {
  AVAILABLE = 'AVAILABLE',
  IN_USE = 'IN_USE',
  PLUGGED_IN = 'PLUGGED_IN',
  OUT_OF_SERVICE = 'OUT_OF_SERVICE',
}

export type Socket = {
  id: number;
  position: number;
  type: SourceTypeEnum;
  phase: PowerTypeEnum;
  current: number;
  pedestal: Pedestal;
  state: SourceState;
  status: SourceStatusEnum;
  activeSession: Session;
};

export type Source = Valve | Socket;

export type Pedestal = {
  id: string;
  identifier: string;
  location: Location;
  valves: Valve[];
  sockets: Socket[];
};

export type Session = {
  group: SessionGroup;
  source: Valve | Socket;
  vehicle: Vehicle;
  createdAt: Date;
  endedAt: Date;
  tariff: number;
  initialMeterState: number;
  id?: number;
};

export enum SessionRequestStatusEnum {
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
  PENDING = 'PENDING',
}

export enum SessionRequestActionEnum {
  ON = 'ON',
  OFF = 'OFF',
}

export type SessionRequest = {
  id: number;
  account: Account;
  pedestal: Pedestal;
  source: Source;
  vehicle: Vehicle;
  activatedBy: User;
  status: SessionRequestStatusEnum;
  action: SessionRequestActionEnum;
};

export type SessionGroup = {
  id: number;
  account: Account;
  location: Location;
  sessions: Session[];
  createdAt: string;
  endedAt: string;
};

export type PaymentOrder = {
  id: number;
  shopId: string;
  amount: string;
  version: string;
  signature: string;
  returnURL: string;
  returnErrorURL: string;
  cancelURL: string;
};

export type Account = {
  id: number;
  balance: number;
  type: AccountTypeEnum;
  paymentType: PaymentTypeEnum;
  location: Location;
  waterTariff?: number;
  powerTariff?: number;
};

export type ReceiptItem = {
  consumption: number;
  createdAt: Date;
  deletedAt: Date;
  endDate: Date;
  id: number;
  service: string;
  pedestalIdentifier: string;
  phaseType?: PowerTypeEnum;
  sourceType: SourceTypeEnum;
  startDate: Date;
  tariff: number;
  updatedAt: Date;
  vehicleRegistration: string;
  vehicleType: string;
};

export type Receipt = {
  id: number;
  transactionId: string;
  account: Account;
  items: ReceiptItem[];
  periodStartDate: Date;
  periodEndDate: Date;
  status: ReceiptStatusEnum;
  paymentType: PaymentTypeEnum;
  paidAt: Date;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
};
