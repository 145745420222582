import { css } from '@emotion/css';
import { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigator } from '../../../routing/navigator';
import {
  CheckCircleOutline,
  CheckCircleSolidInverted,
  QuestionmarkCircle,
} from '../../../shared/assets/icons';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../shared/components/Button';
import {
  Divider,
  DividerVariantEnum,
} from '../../../shared/components/Divider';
import { Layout } from '../../../shared/components/layout/Layout';
import { Loader } from '../../../shared/components/Loader';
import { useUser } from '../../../shared/providers/user-provider';
import { CacheKeys } from '../../../shared/services/api/cache-configuration';
import { useLocations } from '../../../shared/services/api/location';
import { http } from '../../../shared/services/http';
import { locationStore } from '../../../shared/stores/location-store';
import { Colors } from '../../../shared/styles/colors';
import {
  BOTTOM_NAV_HEIGHT,
  HEADER_HEIGHT,
} from '../../../shared/styles/css-values';
import { h1Style, p2Style } from '../../../shared/styles/text';
import { PaymentTypeEnum } from '../../../shared/types/enums';
import { Account, Location } from '../../../shared/types/server-types';
import { showPrivateToast } from '../../../shared/utils/show-private-toast';

export const WalletAddFundsPage: React.FC = () => {
  const navigator = useNavigator();
  const queryClient = useQueryClient();
  const { data: locations, isLoading, isError } = useLocations();
  const user = useUser()!;
  const currentLocation = locationStore.getLocation()!;
  const [selectedLocation, setSelectedLocation] =
    useState<Location>(currentLocation);

  const unvisitedLocations = useMemo(() => {
    const visitedLocationsIds = user.accounts.map((account: Account) => {
      return account.location.id;
    });
    return locations?.filter(
      (location: Location) => !visitedLocationsIds.includes(location.id),
    );
  }, [locations]);

  const handleLocationSelect = async () => {
    if (selectedLocation) {
      switch (selectedLocation.constants.defaultPaymentType) {
        case PaymentTypeEnum.PREPAID:
          navigator.toWalletAddFundsToLocation(selectedLocation.id);
          break;
        case PaymentTypeEnum.POSTPAID:
        case PaymentTypeEnum.PERIODICAL:
          await http.createAccount(selectedLocation.id);
          await queryClient.refetchQueries([CacheKeys.ME]);
          navigator.toSuccessful();
          break;
        default:
          navigator.toPrevious();
          showPrivateToast(
            `Can't create your account right now. Try again later.`,
          );
      }
    }
  };

  if (isError) {
    return <p>Something went wrong</p>;
  }

  if (isLoading || !unvisitedLocations) {
    return <Loader />;
  }

  return (
    <Layout.Content>
      <div className={innerContainerStyle}>
        <h1 className={h1Style}>Where do you want to create an account?</h1>
        <p className={p2Style}>
          Accounts are location specific. If you are planning a visit to a
          location, you can add funds to an account in advance or set up an
          postpaid account.
        </p>
        <Button
          startIcon={<QuestionmarkCircle />}
          variant={ButtonVariantEnum.TEXT}
          size={ButtonSizeEnum.MEDIUM}
          text={`more info`}
        />
        <dl className={listItemRootStyle}>
          {unvisitedLocations.map((location: Location) => (
            <div key={location.id}>
              <div
                className={listItemActionStyle}
                onClick={() => setSelectedLocation(location)}
              >
                <dd>{location.name}</dd>
                {selectedLocation && location.id === selectedLocation?.id ? (
                  <CheckCircleSolidInverted className={iconStyle} />
                ) : (
                  <CheckCircleOutline />
                )}
              </div>
              <Divider variant={DividerVariantEnum.LIST_DIVIDER} />
            </div>
          ))}
          <div>
            <Button
              size={ButtonSizeEnum.LARGE}
              variant={
                selectedLocation
                  ? ButtonVariantEnum.FILLED
                  : ButtonVariantEnum.OUTLINE
              }
              text={selectedLocation ? `create account` : `select location`}
              fullWidth
              gutterbottom
              className={confirmButtonStyle}
              onClick={handleLocationSelect}
            />
          </div>
        </dl>
      </div>
      <Button
        text={'back'}
        size={ButtonSizeEnum.LARGE}
        variant={ButtonVariantEnum.TEXT}
        onClick={navigator.toPrevious}
      />
    </Layout.Content>
  );
};

const listItemRootStyle = css`
  width: 100%;
  gap: 1rem;
`;

const listItemActionStyle = css`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 1rem 0rem;
`;

const innerContainerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
  margin-top: calc(6.375rem - ${HEADER_HEIGHT});
  min-height: calc(100vh - 6.375rem - ${HEADER_HEIGHT} - ${BOTTOM_NAV_HEIGHT});
`;

const confirmButtonStyle = css`
  margin-top: 1rem;
`;

const iconStyle = css`
  fill: ${Colors.CAPRI_BLUE};
`;
