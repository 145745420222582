import { useState } from 'react';
import { StepIndicator } from './StepIndicator';

/**
 * Every step component must implement this interface.
 */
export interface StepComponentProps {
  setCurrentStep(currentStep: number): void;
}

interface StepperProps {
  steps: React.FC<StepComponentProps>[];
}

export const Stepper = (props: StepperProps): JSX.Element => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const { steps } = props;
  const StepComponent = steps[currentStep];
  return (
    <>
      <StepIndicator currentStep={currentStep} totalSteps={steps.length} />
      <StepComponent setCurrentStep={setCurrentStep} />
    </>
  );
};
