import React from 'react';
import { Navigate } from 'react-router-dom';
import { BottomNavigationBar } from '../shared/components/BottomNavigationBar';
import { Layout } from '../shared/components/layout/Layout';
import { appRoutes } from './app-routes';

interface IPrivateRouteProps {
  component: React.FC;
  showHeader?: boolean;
  showFooter?: boolean;
  showBottomNavigationBar?: boolean;
  isAuthenticated: boolean;
}

export const PrivateRoute = (props: IPrivateRouteProps): JSX.Element => {
  const {
    component: Component,
    showHeader = true,
    showFooter = false,
    showBottomNavigationBar = false,
    isAuthenticated,
  } = props;

  return isAuthenticated ? (
    <>
      {showHeader && <Layout.Header />}
      <Component />
      {showFooter && <Layout.Footer />}
      {showBottomNavigationBar && <BottomNavigationBar />}
    </>
  ) : (
    <Navigate to={appRoutes.checkEmail} />
  );
};
