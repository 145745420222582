import React, { useMemo } from 'react';
import { WaterIcon } from '../../../shared/assets/icons';
import { h4Style, labelStyle } from '../../../shared/styles/text';
import { Source, Valve } from '../../../shared/types/server-types';
import {
  reactiveCardStyle,
  sourceCardStyle,
  determineCardStyle,
  determineIsUsedByCurrentUser,
} from './card-styles';
import { cx } from '@emotion/css';
import { sourceLabels } from '../../../shared/types/enums';
import { useUser } from '../../../shared/providers/user-provider';
import { useNavigator } from '../../../routing/navigator';

interface IValveCardProps {
  valve: Valve;
  setModalVisible(value: boolean): void;
  setActiveModalSource(value: Source): void;
}

export const ValveCard = (props: IValveCardProps): JSX.Element => {
  const { valve, setModalVisible, setActiveModalSource } = props;
  const { type, position, connectionSize } = valve;
  const user = useUser();
  const navigator = useNavigator();

  const cardStyle = useMemo(() => determineCardStyle(valve, user!), [valve]);
  const isInUse = determineIsUsedByCurrentUser(valve, user!);

  const handleCardClick = () => {
    if (isInUse) {
      valve.activeSession && navigator.toSession(valve.activeSession.group.id);
    } else {
      setModalVisible(true);
      setActiveModalSource(valve);
    }
  };

  return (
    <div
      className={cx(sourceCardStyle, reactiveCardStyle(cardStyle))}
      onClick={handleCardClick}
    >
      <figure>
        <h4 className={h4Style}>
          {sourceLabels[type]} {position}
        </h4>
        <WaterIcon />
      </figure>
      <label className={labelStyle}>{connectionSize}“</label>
    </div>
  );
};
