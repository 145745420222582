import Select, {
  StylesConfig,
  components as ReactSelectComponents,
} from 'react-select';
import { Controller, useController, useFormContext } from 'react-hook-form';
import { Colors } from '../styles/colors';
import { labelStyle } from '../styles/text';
import { css, cx } from '@emotion/css';

export type SelectInputOptionsProps = {
  value: string | number;
  label: string;
};

type ISelectInputProps = {
  placeHolder?: string;
  name: string;
  options: SelectInputOptionsProps[];
  markAsRequired?: boolean;
  defaultValue?: SelectInputOptionsProps;
  multiple?: boolean;
};

const CustomValueContainer = ({ props, markAsRequired }: any) => {
  return (
    <ReactSelectComponents.ValueContainer
      {...props}
      isRequired={markAsRequired}
    >
      <ReactSelectComponents.Placeholder
        {...props}
        isFocused={props.selectProps.menuIsOpen || props.hasValue}
      >
        {props.selectProps.placeholder}
        {markAsRequired && <span style={{ color: Colors.ERROR }}>*</span>}
      </ReactSelectComponents.Placeholder>
      {props.children.map((child: any) =>
        child && child.type !== ReactSelectComponents.Placeholder
          ? child
          : null,
      )}
    </ReactSelectComponents.ValueContainer>
  );
};

const CustomControl = ({ props, error }: any) => {
  return (
    <ReactSelectComponents.Control {...props} isError={error}>
      {props.children}
    </ReactSelectComponents.Control>
  );
};

export const SelectInput = (props: ISelectInputProps): JSX.Element => {
  const {
    placeHolder,
    name,
    options,
    markAsRequired = false,
    defaultValue,
    multiple = false,
  } = props;

  const { control } = useFormContext();

  const {
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const handleChange = (
    newValue: number | string | undefined,
    oldValues: any,
  ) => {
    if (multiple) {
      if (!oldValues) {
        return [newValue];
      } else if (oldValues.includes(newValue)) {
        return oldValues.filter((value: string | number) => value !== newValue);
      } else {
        return [...oldValues, newValue];
      }
    } else {
      return newValue;
    }
  };

  return (
    <div className={selectInputRootContainerStyle}>
      <Controller
        name={name}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Select
            isMulti={multiple ? undefined : false}
            options={options}
            placeholder={placeHolder}
            value={
              multiple
                ? value.length > 0
                  ? options.filter((option) => value.includes(option.value))
                  : []
                : options.find((option) => option.value === value) || null
            }
            onChange={(val) => onChange(handleChange(val?.value, value))}
            components={{
              ValueContainer: (props) =>
                CustomValueContainer({ props, markAsRequired }),
              Control: (props) => CustomControl({ props, error }),
            }}
            styles={customSelectStyle}
            isSearchable={false}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary: error ? Colors.ERROR : Colors.CAPRI_BLUE,
              },
            })}
            defaultValue={defaultValue}
          />
        )}
        control={control}
      />
      {error && (
        <span className={cx(labelStyle, errorStyle)}>{error.message}</span>
      )}
    </div>
  );
};

const selectInputRootContainerStyle = css`
  height: 5.25rem;
`;

const errorStyle = css`
  color: ${Colors.ERROR};
  &:first-letter {
    text-transform: capitalize;
  }
  padding-left: 1rem;
`;

const customSelectStyle: StylesConfig<SelectInputOptionsProps, false> = {
  control: (styles, { isFocused, theme, isError }: any) => ({
    ...styles,
    boxShadow: 'none',
    height: '3.5rem',
    borderWidth: '0.125rem',
    borderColor: isError ? theme.colors.primary : Colors.WINDOW_GRAY,
    '&:hover': {
      borderColor: isError
        ? theme.colors.primary
        : isFocused
        ? theme.colors.primary
        : Colors.WINDOW_GRAY,
    },
  }),
  input: (styles) => ({
    ...styles,
    marginTop: '1rem',
  }),
  singleValue: (styles) => ({
    ...styles,
    marginTop: '1rem',
    fontSize: '0.83rem',
  }),
  valueContainer: (styles) => ({
    ...styles,
    overflow: 'visible',
    paddingLeft: '1rem',
  }),
  placeholder: (styles, { isFocused, selectProps }) => ({
    ...styles,
    transform: isFocused ? 'translate(0rem, -0.75rem) scale(1)' : 'none',
    fontSize: isFocused ? '0.75rem' : 'initial',
    color: selectProps.menuIsOpen ? Colors.CAPRI_BLUE : Colors.WINDOW_GRAY,
    transition: ' 200ms ease-out 0ms',
    lineHeight: '100%',
    display: 'block',
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    svg: {
      fill: Colors.WINDOW_GRAY,
      width: '26px',
      height: '26px',
    },
  }),
  menu: (styles) => ({
    ...styles,
    margin: '0',
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? Colors.CAPRI_BLUE : Colors.WHITE,
    height: '3.5rem',
    paddingTop: '1rem',
  }),
};
