import { useMemo } from 'react';
import { PowerIcon } from '../../../shared/assets/icons';
import { h4Style, labelStyle } from '../../../shared/styles/text';
import { Socket, Source } from '../../../shared/types/server-types';
import {
  sourceCardStyle,
  reactiveCardStyle,
  determineCardStyle,
  determineIsUsedByCurrentUser,
} from './card-styles';
import { cx } from '@emotion/css';
import { sourceLabels } from '../../../shared/types/enums';
import { useUser } from '../../../shared/providers/user-provider';
import { useNavigator } from '../../../routing/navigator';

interface ISocketCardProps {
  socket: Socket;
  setModalVisible(value: boolean): void;
  setActiveModalSource(value: Source): void;
}

export const SocketCard = (props: ISocketCardProps): JSX.Element => {
  const { socket, setModalVisible, setActiveModalSource } = props;
  const { type, position, current } = socket;
  const user = useUser();
  const navigator = useNavigator();

  const cardStyle = useMemo(() => determineCardStyle(socket, user!), [socket]);
  const isInUse = determineIsUsedByCurrentUser(socket, user!);

  const handleCardClick = () => {
    if (isInUse) {
      socket.activeSession &&
        navigator.toSession(socket.activeSession.group.id);
    } else {
      setModalVisible(true);
      setActiveModalSource(socket);
    }
  };

  return (
    <div
      className={cx(sourceCardStyle, reactiveCardStyle(cardStyle))}
      onClick={handleCardClick}
    >
      <figure>
        <h4 className={h4Style}>
          {sourceLabels[type]} {position}
        </h4>
        <PowerIcon />
      </figure>
      <label className={labelStyle}>{current} A</label>
    </div>
  );
};
