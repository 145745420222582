import { css } from '@emotion/css';
import { Colors } from '../../../shared/styles/colors';
import { Layout } from '../../../shared/components/layout/Layout';
import { User } from '../../../shared/types/server-types';
import { h1Style, valueStyle } from '../../../shared/styles/text';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../shared/components/Button';
import { PlusCircleIcon } from '@heroicons/react/solid';
import {
  Divider,
  DividerVariantEnum,
} from '../../../shared/components/Divider';
import { useState } from 'react';
import { ChevronLeft } from '../../../shared/assets/icons';
import { useNavigator } from '../../../routing/navigator';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from '../vehicle-page/Modal';
import { NewEmployeeForm } from './NewEmployeeForm';
import { EditEmployeeForm } from './EditEmployeeForm';
import { useGetEmployees } from '../../../shared/services/api/user';

export const PersonnelPage = (): JSX.Element => {
  const navigator = useNavigator();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<JSX.Element>(<></>);

  const { data: employees, isError, isLoading } = useGetEmployees();

  const handleModal = (content: JSX.Element) => {
    setModalContent(content);
    setModalVisible(true);
  };

  if (isLoading || !employees) {
    return <>Loading...</>;
  }
  if (isError) {
    return <p>Something went wrong</p>;
  }

  return (
    <Layout.Content className={vehiclePageStyle}>
      <Button
        startIcon={<ChevronLeft />}
        variant={ButtonVariantEnum.TEXT}
        size={ButtonSizeEnum.LARGE}
        text={`account`}
        className={backButtonStyle}
        onClick={navigator.toPrevious}
      />
      <div className={vehiclePageHeaderStyle}>
        <h1>Personnel</h1>
        <span>
          <Button
            size={ButtonSizeEnum.MEDIUM}
            variant={ButtonVariantEnum.TEXT}
            text={'new'}
            startIcon={<PlusCircleIcon className={iconStyle} />}
            onClick={() =>
              handleModal(<NewEmployeeForm setModalVisible={setModalVisible} />)
            }
          />
        </span>
      </div>
      <ul className={vehiclesListStyle}>
        {employees?.map((user: User, index: number) => (
          <li
            key={index}
            onClick={() =>
              handleModal(
                <EditEmployeeForm
                  user={user}
                  setModalVisible={setModalVisible}
                />,
              )
            }
          >
            <div className={listItemTextStyle}>
              <span className={nameStyle(user)}>
                {user.name} {!user.verified && '(invited)'}
              </span>
              {user.email && (
                <span className={infoStyle(user)}>{user.email}</span>
              )}
              {user.username && (
                <span className={infoStyle(user)}>{user.username}</span>
              )}
            </div>
            <Divider variant={DividerVariantEnum.LIST_DIVIDER} />
          </li>
        ))}
      </ul>
      {/*
          This conditional rendering is needed because modal component uses
          custom hook that adds event listener when mounted,
          this produces a sideffect where modal can't be opened
       */}
      {modalVisible && (
        <Modal visible={modalVisible} setVisible={setModalVisible}>
          {modalContent}
        </Modal>
      )}
    </Layout.Content>
  );
};

const vehiclePageStyle = css`
  padding-top: 0.625rem;
`;

const vehiclePageHeaderStyle = css`
  display: flex;
  justify-content: space-between;
  h1 {
    ${h1Style}
    margin-bottom: 1.5rem;
  }
`;

const vehiclesListStyle = css`
  list-style: none;
  display: flex;
  flex-direction: column;
  li {
    ${valueStyle}
    display: flex;
    flex-direction: column;
    align-content: center;
  }
`;

const listItemTextStyle = css`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
`;

const nameStyle = (user: User) => css`
  ${valueStyle}
  margin-bottom: .25rem;
  font-style: ${!user.verified && 'italic'};
`;

const infoStyle = (user: User) => css`
  ${valueStyle}
  color: ${Colors.WINDOW_GRAY};
  font-style: ${!user.verified && 'italic'};
`;

const iconStyle = css`
  width: 24px;
  height: 24px;
  color: ${Colors.CAPRI_BLUE};
`;

const backButtonStyle = css`
  padding-top: 0rem;
`;
