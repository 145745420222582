import { useEffect, useRef, useState } from 'react';
import { css, cx } from '@emotion/css';
import ReactCodeInput from 'react-verification-code-input';
import { h1Style, p2Style } from '../../shared/styles/text';
import {
  Button,
  ButtonVariantEnum,
  ButtonSizeEnum,
} from '../../shared/components/Button';
import { HEADER_HEIGHT } from '../../shared/styles/css-values';
import { Colors } from '../../shared/styles/colors';
import { useSendVerificationCode } from '../../shared/services/api/user';
import { Layout } from '../../shared/components/layout/Layout';
import { authenticationStore } from '../../shared/stores/authentification-store';
import { useQueryClient } from 'react-query';
import { CacheKeys } from '../../shared/services/api/cache-configuration';
import { StepComponentProps } from '../../shared/components/Stepper';
import { observer } from 'mobx-react';
import { useNavigator } from '../../routing/navigator';

export const VerificationStep = observer(
  (props: StepComponentProps): JSX.Element => {
    const resendTimerValue = 10;
    const [errorState, setErrorState] = useState(false);
    const [resendEmailState, setResendEmailState] = useState(false);
    const [resendTimer, setResendTimer] = useState(resendTimerValue);
    const [verificationCode, setVerificationCode] = useState<number>(0);
    const intervalRef: any = useRef(null);
    const sendVerificationCode = useSendVerificationCode();
    const queryClient = useQueryClient();
    const { setCurrentStep } = props;
    const authData = authenticationStore.getAuthenticationData();
    const navigator = useNavigator();

    const onSubmit = async () => {
      try {
        if (!authData?.email) {
          navigator.toCheckEmail();
        } else {
          const { token } = await sendVerificationCode({
            code: verificationCode,
            email: authData.email,
          });
          if (token) {
            authenticationStore.setUserIsVerified(true);
            window.localStorage.setItem('token', token);
            await queryClient.invalidateQueries(CacheKeys.ME);
            authenticationStore.reset();
            navigator.toSuccessful();
          } else {
            setErrorState(true);
          }
        }
      } catch {
        setErrorState(true);
      }
    };

    const resendEmailInterval = () => {
      intervalRef.current = setInterval(() => {
        setResendTimer((resendTimer) => resendTimer - 1);
      }, 1000);
    };

    const resendEmail = () => {
      setResendEmailState(true);
      resendEmailInterval();
    };

    useEffect(() => {
      return () => {
        if (resendTimer <= 1) {
          clearInterval(intervalRef.current);
          setResendTimer(resendTimerValue);
          setResendEmailState(false);
        }
      };
    }, [resendTimer]);

    return (
      <Layout.Content className={verificationPageStyle}>
        <div className={verificationContentStyle}>
          <h1 className={cx(h1Style, headerStyle)}>Verify your email.</h1>
          <p className={cx(p2Style, paragraphStyle)}>
            We have sent a code to your email.
          </p>
          <ReactCodeInput
            fields={4}
            className={verificationInputStye(errorState)}
            fieldHeight={56}
            fieldWidth={52}
            onComplete={(value: string) => setVerificationCode(parseInt(value))}
          />
          <div className={infoTextStyle}>
            <p className={cx(p2Style, inputErrorStyle(errorState))}>
              Wrong code entered. Please try again.
            </p>
            <p className={cx(p2Style, resendTextStyle(resendEmailState))}>
              Email sent. You can resend it in {resendTimer} seconds.
            </p>
          </div>
          <Button
            text={'resend email'}
            type={'button'}
            variant={ButtonVariantEnum.TEXT}
            size={ButtonSizeEnum.LARGE}
            onClick={resendEmail}
            disabled={resendEmailState}
          />
        </div>
        <div className={buttonContainerStyle}>
          <Button
            variant={ButtonVariantEnum.TEXT}
            size={ButtonSizeEnum.LARGE}
            text={'back'}
            onClick={() => {
              setCurrentStep(0);
            }}
          />
          <Button
            variant={ButtonVariantEnum.FILLED}
            size={ButtonSizeEnum.LARGE}
            text={'verify'}
            type={'submit'}
            onClick={onSubmit}
          />
        </div>
      </Layout.Content>
    );
  },
);

const verificationPageStyle = css`
  min-height: calc(100vh - ${HEADER_HEIGHT});
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const verificationContentStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: calc(11.5rem - ${HEADER_HEIGHT});
`;

const headerStyle = css`
  margin-bottom: 1rem;
  align-self: flex-start;
`;

const paragraphStyle = css`
  margin-bottom: 4rem;
  align-self: flex-start;
  color: ${Colors.WINDOW_GRAY};
`;
// TODO: wiggle animation on error state event
const verificationInputStye = (errorState: any) => css`
  display: flex;

  width: 100% !important;

  div {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  input {
    margin: 0px 1.0625rem;
    border: 2px solid ${Colors.LIGHT_GRAY} !important;
    border-radius: 0px !important;
    font: normal normal bold 2rem Doctrine !important;
    color: ${Colors.BLACK} !important;

    ${errorState === true &&
    `
      border-color: ${Colors.ERROR} !important;
    `}
  }

  input:focus {
    border: 2px solid ${Colors.CAPRI_BLUE} !important;
  }
`;

const infoTextStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 7.25rem;
`;

const inputErrorStyle = (errorState: any) => css`
  color: ${Colors.ERROR};
  margin-top: 1rem;
  ${errorState === false &&
  `
    visibility: hidden;
  `}
`;

const resendTextStyle = (resendEmail: any) => css`
  color: ${Colors.WINDOW_GRAY};
  margin-top: calc(4rem - 1.5rem - 1rem);
  ${resendEmail === false &&
  `
    visibility: hidden;
  `}
`;

const buttonContainerStyle = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
