export enum CacheKeys {
  ME = 'me',
  LOCATION = 'location',
  LOCATIONS = 'locations',
  SIGNATURE = 'locations',
  PEDESTAL = 'pedestal',
  RECEIPT = 'receipt',
  RECEIPTS = 'receipts',
  SESSION_GROUP = 'sessionGroup',
  SESSION_REQUEST = 'sessionRequest',
  EMPLOYEES = 'employees',
}
