import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
} from 'react-query';
import {
  AddVehicleParams,
  DeleteVehicleParams,
  UpdateVehicleParams,
} from '../../types/input-param-types';
import { http } from '../http';
import { CacheKeys } from './cache-configuration';

export const useAddVehicle = (): UseMutateAsyncFunction<
  any,
  any,
  AddVehicleParams
> => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation<any, any, AddVehicleParams>(
    http.addVehicle,
    {
      onError: (error) => {
        throw error;
      },
      onSuccess: () => {
        queryClient.refetchQueries(CacheKeys.ME);
      },
    },
  );
  return mutateAsync;
};

export const useUpdateVehicle = (): UseMutateAsyncFunction<
  any,
  any,
  UpdateVehicleParams
> => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation<any, any, UpdateVehicleParams>(
    http.updateVehicle,
    {
      onError: (error) => {
        throw error;
      },
      onSuccess: () => {
        queryClient.refetchQueries(CacheKeys.ME);
      },
    },
  );
  return mutateAsync;
};

export const useDeleteVehicle = (): UseMutateAsyncFunction<
  any,
  any,
  DeleteVehicleParams
> => {
  const queryClient = useQueryClient();
  const { mutateAsync } = useMutation<any, any, DeleteVehicleParams>(
    http.deleteVehicle,
    {
      onError: (error) => {
        throw error;
      },
      onSuccess: () => {
        queryClient.refetchQueries(CacheKeys.ME);
      },
    },
  );
  return mutateAsync;
};
