import * as SolidIcons from '@heroicons/react/solid';
import * as OutlineIcons from '@heroicons/react/outline';

export type IconName = keyof typeof SolidIcons | keyof typeof OutlineIcons;

interface Props {
  icon: IconName;
  outline?: boolean;
  className?: string;
  width?: string | number;
  color?: string;
}

export const HeroIcon = (props: Props): JSX.Element => {
  const { icon, outline = false, width, color } = props;

  const Icon = outline ? OutlineIcons[icon] : SolidIcons[icon];

  return <Icon width={width} color={color} />;
};
