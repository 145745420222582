import { useMemo } from 'react';
import { PowerIcon } from '../../../shared/assets/icons';
import { h4Style, labelStyle } from '../../../shared/styles/text';
import { Pedestal, Socket } from '../../../shared/types/server-types';
import {
  sourceCardStyle,
  reactiveCardStyle,
  determineCardStyle,
} from './card-styles';
import { cx } from '@emotion/css';
import { sourceLabels } from '../../../shared/types/enums';

interface ISocketCardProps {
  socket: Socket;
  setModalVisible(value: boolean): void;
  pedestal: Pedestal;
}

export const ActivateMoreSocketCard = (
  props: ISocketCardProps,
): JSX.Element => {
  const { socket, setModalVisible, pedestal } = props;
  const { type, position } = socket;

  const cardStyle = useMemo(() => determineCardStyle(socket), [socket]);

  return (
    <div
      className={cx(sourceCardStyle, reactiveCardStyle(cardStyle))}
      onClick={() => {
        setModalVisible(true);
      }}
    >
      <figure>
        <h4 className={h4Style}>
          {sourceLabels[type]} {position}
        </h4>
        <PowerIcon />
      </figure>
      <label className={labelStyle}>ID {pedestal.identifier}</label>
    </div>
  );
};
