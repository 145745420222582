import { css } from '@emotion/css';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigator } from '../../routing/navigator';
import { CheckCircleSolid } from '../../shared/assets/icons';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../shared/components/Button';
import { Layout } from '../../shared/components/layout/Layout';
import { Colors } from '../../shared/styles/colors';
import { HEADER_HEIGHT } from '../../shared/styles/css-values';
import { doctrineStencilStyle } from '../../shared/styles/text';
import { convertEURToHRK } from '../../shared/utils/currency-conversion';

const SuccessPaymentSection: React.FC<{ balance: string }> = ({ balance }) => {
  return (
    <section className={successfulSectionStyle}>
      You successfully added
      <span className={currencyAmountStyle}>{` ${balance} `}</span>
      to your account.
    </section>
  );
};

const SuccessSignUpSection: React.FC = () => {
  return (
    <section className={successfulSectionStyle}>
      You successfully created your account.
    </section>
  );
};

export const SuccessPage = (): JSX.Element => {
  const navigator = useNavigator();
  const [urlParams] = useSearchParams();

  const balance = urlParams.get('Amount');

  return (
    <Layout.Content>
      <div className={innerContainerStyle}>
        <CheckCircleSolid className={checkCircleIconStyle} />
        {balance ? (
          <SuccessPaymentSection
            balance={`${balance} EUR (${convertEURToHRK(
              parseFloat(balance),
            )}) HRK`}
          />
        ) : (
          <SuccessSignUpSection />
        )}
      </div>
      <Button
        text={"let's go"}
        size={ButtonSizeEnum.LARGE}
        variant={ButtonVariantEnum.FILLED}
        onClick={navigator.toLanding}
        fullWidth
      />
    </Layout.Content>
  );
};

const innerContainerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5rem;
  min-height: calc(100vh - 4rem - 3.375rem - ${HEADER_HEIGHT});
  gap: 2.5rem;
`;

const successfulSectionStyle = css`
  text-align: center;
  color: ${Colors.CAPRI_BLUE};
  font-weight: bold;
`;

const checkCircleIconStyle = css`
  margin-top: 20vh;
  width: 7rem;
  height: 7rem;
`;

const currencyAmountStyle = css`
  ${doctrineStencilStyle}
  font-size: 1.5rem;
`;
