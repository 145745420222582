import { css } from '@emotion/css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { MarexLogo } from '../../assets/icons';
import { Colors } from '../../styles/colors';

export const LocationLoadingState = (): JSX.Element => {
  return (
    <SkeletonTheme baseColor={Colors.LIGHT_GRAY} highlightColor='#e6e6e6'>

      <Skeleton height={'17.5625rem'} borderRadius={0} />
      <MarexLogo className={logoStyle} fill={Colors.WHITE} />

      <div className={contentStyle}>
        <div className={circleWithLinesStyle}>
          <Skeleton height={'4rem'} width={'4rem'} borderRadius={50} />
          <div className={circleContentStyle}>
            <Skeleton height={'1.8125rem'} borderRadius={0} />
            <Skeleton height={'1.3125rem'} width={'65%'} borderRadius={0} />
          </div>
        </div>

        <div>
          <Skeleton height={'1.5rem'} borderRadius={0} />
          <Skeleton height={'1.5rem'} width={'50%'} borderRadius={0} />
        </div>

        <div className={boldContentStyle}>
          <Skeleton height={'3.125rem'} borderRadius={0} />
        </div>

        <div className={gridContentStyle}>
          <div className={gridSectionStyle}>
            <Skeleton height={'0.75rem'} borderRadius={0} />
            <Skeleton height={'1.375rem'} width={'75%'} borderRadius={0} />
          </div>
          <div className={gridSectionStyle}>
            <Skeleton height={'0.75rem'} borderRadius={0} />
            <Skeleton height={'1.375rem'} width={'75%'} borderRadius={0} />
          </div>
        </div>
        <div className={gridContentStyle}>
          <div className={gridSectionStyle}>
            <Skeleton height={'0.75rem'} borderRadius={0} />
            <Skeleton height={'1.375rem'} width={'75%'} borderRadius={0} />
          </div>
          <div className={gridSectionStyle}>
            <Skeleton height={'0.75rem'} borderRadius={0} />
            <Skeleton height={'1.375rem'} width={'75%'} borderRadius={0} />
          </div>
        </div>
        <div className={gridContentStyle}>
          <div className={gridSectionStyle}>
            <Skeleton height={'0.75rem'} borderRadius={0} />
            <Skeleton height={'1.375rem'} width={'75%'} borderRadius={0} />
          </div>
          <div className={gridSectionStyle}>
            <Skeleton height={'0.75rem'} borderRadius={0} />
            <Skeleton height={'1.375rem'} width={'75%'} borderRadius={0} />
          </div>
        </div>

      </div>

    </SkeletonTheme>
  );
};

const contentStyle = css`
  padding:1.5rem;
  padding-bottom:7rem;
`

const circleWithLinesStyle = css`
  display:flex;
  justify-content: space-between;
  padding-bottom:1.5rem;

`;

const circleContentStyle = css`
  width:75%;
  align-self:center;
`;

const boldContentStyle = css`
  padding-top:1.5rem;
  padding-bottom:1rem;

`;

const gridSectionStyle = css`
  width:40%;
  align-self:center;
`;

const gridContentStyle = css`
  display:flex;
  justify-content: space-between;
  padding-top:1rem;
`;

const logoStyle = css`
  position :absolute;
  z-index:1;
  top:1.25rem;
  left:1.5rem;
`;
