import { css } from '@emotion/css';
import { ChevronLeft } from '../../shared/assets/icons';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../shared/components/Button';
import { useNavigator } from '../../routing/navigator';
import { HEADER_HEIGHT } from '../../shared/styles/css-values';
import { FormProvider, useForm } from 'react-hook-form';
import { Layout } from '../../shared/components/layout/Layout';
import { SearchInput } from '../../shared/components/SearchInput';
import { useLocations } from '../../shared/services/api/location';
import { LocationSearchParams } from '../../shared/types/input-param-types';
import { LocationCard } from '../landing-page/explore-section/LocationCard';
import { NoResultsFound } from '../../shared/components/NoResultsFound';
import {useCallback, useEffect, useMemo, useState} from 'react';
import { LocationsLoadingState } from '../../shared/components/loading-states/LoactionsLoadingState';
import {debounce} from "lodash";

export const LocationsPage = (): JSX.Element => {

  const [term, setTerm] = useState<string>()

  const methods = useForm<LocationSearchParams>({
    defaultValues: {
      term: '',
    },
  });

  const value = methods.watch('term')
  const debouncedSearch = useCallback(debounce((value) => setTerm(value),500), [])

  useEffect(() => {
    debouncedSearch(value)
  }, [value])

  const {
    data: locations,
    isLoading,
    isError,
  } = useLocations({
    term
  });

  const navigator = useNavigator();

  if (isError) {
    return <p>Error</p>;
  }
  const emptyData = useMemo(
    () => locations?.length === 0 && !isLoading,
    [locations],
  );
  const loading = useMemo(() => isLoading || !locations, [locations]);

  return (
    <Layout.Content className={locationsPageStyle}>
      <div className={homeButtonStyle}>
        <Button
          startIcon={<ChevronLeft />}
          variant={ButtonVariantEnum.TEXT}
          size={ButtonSizeEnum.LARGE}
          text={`home`}
          onClick={navigator.toPrevious}
        />
      </div>
      <FormProvider {...methods}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className={formStyle}
        >
          <SearchInput
            name={'term'}
            placeHolder={'search locations'}
            hint={'Our locations'}
          />
        </form>
      </FormProvider>
      {emptyData && <NoResultsFound />}
      {loading && <LocationsLoadingState />}
      {locations?.map((location) => (
        <LocationCard location={location} key={location.id} />
      ))}
    </Layout.Content>
  );
};

const locationsPageStyle = css`
  min-height: calc(100vh - ${HEADER_HEIGHT});
  padding: 0.625rem 1.5rem 2rem 1.5rem;
  display: flex;
  flex-direction: column;
`;

const formStyle = css`
  width: 100%;
`;

const homeButtonStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;
  justify-self: flex-start;
  align-self: flex-start;
  margin-bottom: 0.75rem;
`;
