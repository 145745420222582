import {toast} from "react-toastify";
import {css} from "@emotion/css";

const containerStyle = css`
  clip-path: none;
`

export const showPublicToast = (message: string): void => {
  toast(message, {
    className: containerStyle
  })
}
