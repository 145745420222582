import React, { ReactElement } from 'react';
import { ClassNamesArg, css, cx } from '@emotion/css';
import { Colors } from '../styles/colors';
import { Oval } from 'react-loader-spinner';

export enum ButtonVariantEnum {
  FILLED = 'filled',
  OUTLINE = 'outline',
  TEXT = 'text',
}

export enum ButtonSizeEnum {
  LARGE = 'large',
  MEDIUM = 'medium',
}

interface IButtonProps {
  disabled?: boolean;
  type?: any;
  variant: ButtonVariantEnum;
  size: ButtonSizeEnum;
  text: string;
  startIcon?: ReactElement<any, any>;
  endIcon?: ReactElement<any, any>;
  color?: Colors;
  onClick?(): void;
  className?: ClassNamesArg;
  fullWidth?: boolean;
  gutterbottom?: boolean;
  loading?: boolean;
}

export const Button = (props: IButtonProps): JSX.Element => {
  const {
    disabled = false,
    fullWidth = false,
    gutterbottom = false,
    type = 'button',
    variant = ButtonVariantEnum.FILLED,
    size = ButtonSizeEnum.LARGE,
    text,
    startIcon,
    endIcon,
    onClick,
    color,
    className,
    loading = false,
  } = props;

  return (
    <button
      className={cx(
        buttonStyle(variant, size, disabled, color, fullWidth, gutterbottom),
        className,
      )}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {startIcon && startIcon}
      {loading ? (
        <Oval
          arialLabel="loading-indicator"
          color={
            variant === ButtonVariantEnum.FILLED
              ? Colors.WHITE
              : Colors.CAPRI_BLUE
          }
          height={32}
          width={32}
        />
      ) : (
        text
      )}
      {endIcon && endIcon}
    </button>
  );
};

const buttonStyle = (
  variant: ButtonVariantEnum,
  size: ButtonSizeEnum,
  disabled: boolean,
  color: Colors | undefined,
  fullWidth: boolean,
  gutterbottom: boolean,
) => css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  border: none;
  background: none;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 100%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  vertical-align: middle;
  color: ${color};
  cursor: pointer;

  ${gutterbottom &&
  css`
    margin-bottom: 1rem;
  `}

  ${fullWidth &&
  css`
    width: 100%;
  `}

  ${size === ButtonSizeEnum.LARGE &&
  css`
    height: 3.375rem;
    padding: 1rem 2rem;
  `}

  ${size === ButtonSizeEnum.MEDIUM &&
  css`
    height: 2.6875rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
  `}

  ${variant === ButtonVariantEnum.FILLED &&
  css`
    color: ${color ? color : Colors.WHITE};
    background: ${Colors.CAPRI_BLUE};
    border: 2px solid ${Colors.CAPRI_BLUE};
  `}

  ${variant === ButtonVariantEnum.OUTLINE &&
  css`
    color: ${Colors.CAPRI_BLUE};
    border: 2px solid ${Colors.CAPRI_BLUE};
  `}

  ${variant === ButtonVariantEnum.TEXT &&
  css`
    padding-left: 0px;
    padding-right: 0px;
    color: ${color ? color : Colors.CAPRI_BLUE};
  `}

  ${disabled === true &&
  css`
    color: ${Colors.WINDOW_GRAY};
  `}

  ${variant === ButtonVariantEnum.FILLED &&
  disabled === true &&
  css`
    color: ${Colors.LIGHT_GRAY};
    border: 2px solid ${Colors.WINDOW_GRAY};
    background-color: ${Colors.WINDOW_GRAY};
  `}
`;
