import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import LogRocket from 'logrocket';

LogRocket.init('t4souq/marexhcc');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
