import { Layout } from '../../shared/components/layout/Layout';
import { ExploreSection } from './explore-section/ExploreSection';
import { HeroSection } from './hero-section/HeroSection';

export const LandingPage = (): JSX.Element => {
  return (
    <Layout.Content>
      <HeroSection />
      <ExploreSection />
    </Layout.Content>
  );
};
