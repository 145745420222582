import { css } from '@emotion/css';
import { Colors } from '../../../shared/styles/colors';
import { PowerTypeEnum, SourceTypeEnum } from '../../../shared/types/enums';
import {
  Account,
  Socket,
  Source,
  SourceStatusEnum,
  User,
} from '../../../shared/types/server-types';

export type CardStyle = {
  borderStyle: string;
  backgroundStyle: string;
  colorStyle: string;
  svgStyle: string;
};

//TODO: find a way to globally determine account in use
export const determineIsUsedByCurrentUser = (source: Source, user: User): boolean => {
  if (source.activeSession === null) {
    return false;
  }
  const usedByAccount = source.activeSession.group.account;

  return user.accounts.some(
    (account: Account) => account.id === usedByAccount.id,
  );
};

export const determineCardStyle = (source: Source, User: User): CardStyle => {
  const isUsedByCurrentUser = determineIsUsedByCurrentUser(source, User);

  const defaultStyles: CardStyle = {
    backgroundStyle: `${Colors.WINDOW_GRAY}1A`,
    borderStyle: Colors.WINDOW_GRAY,
    colorStyle: Colors.WINDOW_GRAY,
    svgStyle: Colors.WINDOW_GRAY,
  };

  const sourceStyle = {
    // //
    // VALVE styles
    // //
    [`${SourceTypeEnum.VALVE}${SourceStatusEnum.AVAILABLE}`]: {
      backgroundStyle: '',
      borderStyle: '',
      colorStyle: Colors.CAPRI_BLUE,
      svgStyle: '',
    },
    [`${SourceTypeEnum.VALVE}${SourceStatusEnum.IN_USE}`]: isUsedByCurrentUser
      ? {
          backgroundStyle: `${Colors.CAPRI_BLUE}1A`,
          borderStyle: Colors.CAPRI_BLUE,
          colorStyle: Colors.CAPRI_BLUE,
          svgStyle: Colors.CAPRI_BLUE,
        }
      : {
          backgroundStyle: `${Colors.WINDOW_GRAY}1A`,
          borderStyle: Colors.WINDOW_GRAY,
          colorStyle: Colors.WINDOW_GRAY,
          svgStyle: Colors.WINDOW_GRAY,
        },
    [`${SourceTypeEnum.VALVE}${SourceStatusEnum.OUT_OF_SERVICE}`]: {
      backgroundStyle: `${Colors.WINDOW_GRAY}1A`,
      borderStyle: Colors.WINDOW_GRAY,
      colorStyle: Colors.WINDOW_GRAY,
      svgStyle: Colors.WINDOW_GRAY,
    },
    // //
    // SOCKET SINGLE styles
    // //
    [`${SourceTypeEnum.SOCKET}${PowerTypeEnum.SINGLE}${SourceStatusEnum.AVAILABLE}`]:
      {
        backgroundStyle: '',
        borderStyle: Colors.MONO_PHASE,
        colorStyle: '',
        svgStyle: '',
      },
    [`${SourceTypeEnum.SOCKET}${PowerTypeEnum.SINGLE}${SourceStatusEnum.IN_USE}`]:
      isUsedByCurrentUser
        ? {
            backgroundStyle: `${Colors.MONO_PHASE}1A`,
            borderStyle: Colors.MONO_PHASE,
            colorStyle: '',
            svgStyle: Colors.MONO_PHASE,
          }
        : {
            backgroundStyle: `${Colors.WINDOW_GRAY}1A`,
            borderStyle: Colors.WINDOW_GRAY,
            colorStyle: Colors.WINDOW_GRAY,
            svgStyle: Colors.WINDOW_GRAY,
          },
    [`${SourceTypeEnum.SOCKET}${PowerTypeEnum.SINGLE}${SourceStatusEnum.PLUGGED_IN}`]:
      {
        backgroundStyle: `${Colors.PURPLE}1A`,
        borderStyle: Colors.PURPLE,
        colorStyle: Colors.PURPLE,
        svgStyle: Colors.PURPLE,
      },
    [`${SourceTypeEnum.SOCKET}${PowerTypeEnum.SINGLE}${SourceStatusEnum.OUT_OF_SERVICE}`]:
      {
        backgroundStyle: `${Colors.WINDOW_GRAY}1A`,
        borderStyle: Colors.WINDOW_GRAY,
        colorStyle: Colors.WINDOW_GRAY,
        svgStyle: Colors.WINDOW_GRAY,
      },
    // //
    // SOCKET TRIPLE styles
    // //
    [`${SourceTypeEnum.SOCKET}${PowerTypeEnum.TRIPLE}${SourceStatusEnum.AVAILABLE}`]:
      {
        backgroundStyle: '',
        borderStyle: Colors.THREE_PHASE,
        colorStyle: '',
        svgStyle: '',
      },
    [`${SourceTypeEnum.SOCKET}${PowerTypeEnum.TRIPLE}${SourceStatusEnum.IN_USE}`]:
      isUsedByCurrentUser
        ? {
            backgroundStyle: `${Colors.THREE_PHASE}1A`,
            borderStyle: Colors.THREE_PHASE,
            colorStyle: '',
            svgStyle: Colors.THREE_PHASE,
          }
        : {
            backgroundStyle: `${Colors.WINDOW_GRAY}1A`,
            borderStyle: Colors.WINDOW_GRAY,
            colorStyle: Colors.WINDOW_GRAY,
            svgStyle: Colors.WINDOW_GRAY,
          },
    [`${SourceTypeEnum.SOCKET}${PowerTypeEnum.TRIPLE}${SourceStatusEnum.PLUGGED_IN}`]:
      {
        backgroundStyle: `${Colors.PURPLE}1A`,
        borderStyle: Colors.PURPLE,
        colorStyle: Colors.PURPLE,
        svgStyle: Colors.PURPLE,
      },
    [`${SourceTypeEnum.SOCKET}${PowerTypeEnum.TRIPLE}${SourceStatusEnum.OUT_OF_SERVICE}`]:
      {
        backgroundStyle: `${Colors.WINDOW_GRAY}1A`,
        borderStyle: Colors.WINDOW_GRAY,
        colorStyle: Colors.WINDOW_GRAY,
        svgStyle: Colors.WINDOW_GRAY,
      },
  };

  const phase = (source as Socket).phase ?? '';

  return {
    ...defaultStyles,
    ...sourceStyle[`${source.type}${phase}${source.status}`],
  };
};

export const reactiveCardStyle = (cardStyle: CardStyle): string => css`
  border: 2px solid ${cardStyle.borderStyle};
  border-left: 0.875rem solid ${cardStyle.borderStyle};
  background-color: ${cardStyle.backgroundStyle};
  color: ${cardStyle.colorStyle};

  figure {
    h4 {
      color: ${cardStyle.colorStyle};
    }

    svg {
      path {
        fill: ${cardStyle.svgStyle};
      }
    }
  }

  label {
  }
`;

export const sourceCardStyle = css`
  min-width: 9.125rem;
  min-height: 4rem;
  cursor: pointer;
  border: 2px solid #1684dd;
  border-left: 0.875rem solid #1684dd;
  transition: 0.5s ease-out;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.75rem;

  figure {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;

    h4 {
      text-transform: capitalize;
      transition: 0.5s ease-out;
    }

    svg {
      path {
        transition: 0.5s ease-out;
      }
    }
  }

  label {
    cursor: pointer;
    color: ${Colors.WINDOW_GRAY};
  }
`;
