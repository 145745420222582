import { css, cx } from '@emotion/css';
import { IInfoProps, Info } from './Info';

interface IInfoGroupProps {
  elements: IInfoProps[];
  className?: string;
}

export default function InfoGroup(props: IInfoGroupProps): JSX.Element {
  const { elements, className } = props;
  return (
    <div className={cx(infoGroupStyle, className)}>
      {elements.map((element, index) => (
        <Info key={index} label={element.label} value={element.value} />
      ))}
    </div>
  );
}

const infoGroupStyle = css`
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.25rem;
  width: 100%;
  margin: 1rem 0px;
`;
