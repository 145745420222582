import { css } from '@emotion/css';
import { WarningIcon } from '../assets/icons';
import { Colors } from '../styles/colors';
import { p1Style } from '../styles/text';

export const NoPermission = (): JSX.Element => {
  return (
    <div className={containerStyle}>
      <WarningIcon className={imageStyle} height={80} width={80} />
      <div
        className={textStyle}
      >{`You don't have a permission to view this`}</div>
    </div>
  );
};

const textStyle = css`
  ${p1Style}
  color:${Colors.WINDOW_GRAY};
  padding-top: 2.75rem;
`;

const imageStyle = css`
  align-self: center;
  color: ${Colors.WINDOW_GRAY};
`;

const containerStyle = css`
  display: flex;
  flex-direction: column;
  text-align: center;

  justify-self: center;
  height: 70vh;
  justify-content: center;
`;
