/** @jsxImportSource @emotion/react */
import { ForgotPasswordForm } from './ForgotPasswordForm'
import { Layout } from '../../shared/components/layout/Layout';

export const ForgotPasswordPage = (): JSX.Element => {
  return (
    <Layout.Content>
      <div>
        Forgot password?
      </div>
      <ForgotPasswordForm />
    </Layout.Content>
  )
}
