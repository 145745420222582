import { css } from '@emotion/css';
import { ClockIcon } from '../assets/icons';
import { Colors } from '../styles/colors';
import { p1Style } from '../styles/text';

export const NoActivityYet = (): JSX.Element => {
  return (
    <div className={containerStyle}>
      <ClockIcon className={imageStyle} />
      <div className={textStyle}>No activity yet</div>
    </div>
  );
};

const textStyle = css`
  ${p1Style}
  color:${Colors.WINDOW_GRAY};
  padding-top: 2.75rem;
`;

const imageStyle = css`
  align-self: center;
`;

const containerStyle = css`
  display: flex;
  flex-direction: column;
  text-align: center;

  justify-self: center;
  height: 70vh;
  justify-content: center;
`;
