import { css, cx } from '@emotion/css';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigator } from '../../../routing/navigator';
import { ChevronLeft } from '../../../shared/assets/icons';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../shared/components/Button';
import { Layout } from '../../../shared/components/layout/Layout';
import { TabSwitch } from '../../../shared/components/TabSwitch';
import { useLocation } from '../../../shared/services/api/location';
import { Colors } from '../../../shared/styles/colors';
import {
  doctrineStencilStyle,
  h1Style,
  valueStyle,
} from '../../../shared/styles/text';
import { ChartTabSwitchEnum } from '../../../shared/types/enums';
import { MonthTabView } from './tab-views/MonthTabView';
import { WeekTabView } from './tab-views/WeekTabView';
import { YearTabView } from './tab-views/YearTabView';

export const WalletFundsStatsPage: React.FC = () => {
  const navigator = useNavigator();

  const { locationId } = useParams<{ locationId: string }>();
  const [selectedTab, setSelectedTab] = useState<number>(
    ChartTabSwitchEnum.MONTHLY,
  );

  if (!locationId) throw new Error();

  const {
    data: location,
    isLoading,
    isError,
  } = useLocation({ id: locationId });

  if (isError) {
    return <p>Error</p>;
  }

  if (isLoading || !location) {
    return <p>Loading...</p>;
  }

  return (
    <Layout.Content className={contentStyle}>
      <div className={innerContentStyle}>
        <Button
          size={ButtonSizeEnum.MEDIUM}
          variant={ButtonVariantEnum.TEXT}
          onClick={navigator.toWallet}
          text={`wallet`}
          startIcon={<ChevronLeft />}
        />
        <p className={cx(doctrineStencilStyle, locationTextStyle)}>
          {location.name}
        </p>
        <div className={totalInfoContainerStyle}>
          <p className={valueStyle}>Total spent</p>
          <h1 className={h1Style}>9,999.000,00 EUR</h1>
        </div>
        <TabSwitch
          tabs={[
            ChartTabSwitchEnum[0].toLowerCase(),
            ChartTabSwitchEnum[1].toLowerCase(),
            ChartTabSwitchEnum[2].toLowerCase(),
          ]}
          selectedIndex={selectedTab}
          setIndex={setSelectedTab}
        />
      </div>
      <div className={tabContentStyle}>
        {selectedTab === ChartTabSwitchEnum.MONTHLY && <MonthTabView />}
        {selectedTab === ChartTabSwitchEnum.YEARLY && <YearTabView />}
        {selectedTab === ChartTabSwitchEnum.WEEKLY && <WeekTabView />}
      </div>
    </Layout.Content>
  );
};

const tabContentStyle = css`
  display: flex;
  overflow-y: scroll;
  gap: 0.5rem;
  margin-top: 1rem;
  padding-bottom: 0.6rem;
`;

const totalInfoContainerStyle = css`
  background-color: ${Colors.CAPRI_BLUE};
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-flow: column;
  gap: 0.25rem;
  > h1 {
    color: ${Colors.WHITE};
  }
  > p {
    color: ${Colors.LIGHT_GRAY};
  }
`;
const innerContentStyle = css`
  padding-right: 1.5rem;
  display: flex;
  flex-flow: column;
  align-items: start;
  gap: 2rem;
`;

const contentStyle = css`
  padding-top: 0rem;
  padding-right: 0rem;
`;

const locationTextStyle = css`
  color: ${Colors.CAPRI_BLUE};
`;
