import React from 'react';
import { css } from '@emotion/css';
import { Colors } from '../styles/colors';

interface IStepIndicator {
  totalSteps: number;
  currentStep: number;
}

export const StepIndicator = (props: IStepIndicator): JSX.Element => {
  const calculateWidth = () => {
    const width = Math.round((100 / props.totalSteps) * props.currentStep);
    return `${width}%`;
  };

  return <div className={stepIndicatorStyle(calculateWidth())}></div>;
};

const stepIndicatorStyle = (width: string) => css`
  position: absolute;
  top: 0px;
  left: 0px;
  width: ${width};
  transition: 0.5s ease-out;
  border: 2px solid ${Colors.CAPRI_BLUE};
`;
