import { css } from '@emotion/css';
import { ChevronLeft } from '../../shared/assets/icons';
import { useParams } from 'react-router-dom';

import {
  Button,
  ButtonVariantEnum,
  ButtonSizeEnum,
} from '../../shared/components/Button';
import { HEADER_HEIGHT } from '../../shared/styles/css-values';
import { SessionTotalOverview } from './SessionTotalOverview';
import { useGetSessionGroup } from '../../shared/services/api/session-group';
import { SessionCard } from './SessionCard';
import { Session } from '../../shared/types/server-types';
import { ActivateMore } from './ActivateMore';
import { Info } from '../../shared/components/Info';
import { Layout } from '../../shared/components/layout/Layout';
import { useNavigator } from '../../routing/navigator';
import { SourceTypeEnum } from '../../shared/types/enums';
import { useEffect, useMemo, useState } from 'react';
import { http } from '../../shared/services/http';
import { List, sum } from 'lodash';
import { roundToFloat } from '../../shared/utils/roundToFloat';
import { showPrivateToast } from '../../shared/utils/show-private-toast';
import { Loader } from '../../shared/components/Loader';

export const SessionPage = (): JSX.Element => {
  const navigator = useNavigator();
  const { sessionId = '' } = useParams();
  const {
    data: sessionGroup,
    isLoading,
    refetch,
  } = useGetSessionGroup(sessionId);

  const [showActivateMore, setShowActivateMore] = useState<boolean>(false);
  const [sessionSourceType, setSessionSourceType] = useState<SourceTypeEnum>();

  useEffect(() => {
    //TODO: refactor this. Activate more should show available sources that user doesnt have in sesisonGroup
    if (sessionGroup) {
      const { sessions } = sessionGroup;
      if (sessions.length === 1) {
        setShowActivateMore(true);
        setSessionSourceType(sessions[0].source.type);
      } else {
        if (
          sessions.some(
            (session) => session.source.type === SourceTypeEnum.SOCKET,
          ) &&
          sessions.some(
            (session) => session.source.type === SourceTypeEnum.VALVE,
          )
        ) {
          setShowActivateMore(false);
        } else {
          setShowActivateMore(true);
          sessions.some((session) => {
            session.source.type === SourceTypeEnum.SOCKET;
          })
            ? setSessionSourceType(SourceTypeEnum.SOCKET)
            : setSessionSourceType(SourceTypeEnum.VALVE);
        }
      }
    } else setShowActivateMore(false);
  }, [sessionGroup]);

  const sessionGroupTotal = useMemo((): string => {
    let sessionTotals: List<number> = [];
    if (sessionGroup) {
      sessionTotals = sessionGroup.sessions.map((session) => {
        return (
          (session.source.state.value - session.initialMeterState) *
          session.tariff
        );
      });
    }
    return roundToFloat(sum(sessionTotals));
  }, [sessionGroup]);

  if (isLoading) {
    return <Loader />;
  }

  if (!sessionGroup) {
    return <div>No data</div>;
  }

  if (sessionGroup.sessions.length === 0) {
    navigator.toLanding();
  }

  const getVehicleName = (sessions: Session[]) => {
    if (!sessions.length) {
      return null;
    }
    for (const session of sessions) {
      if (session.vehicle.id !== sessions[0].vehicle.id) {
        return null;
      }
    }
    return sessions[0].vehicle.registration;
  };

  const pedestal = sessionGroup?.sessions[0]?.source?.pedestal ?? null;
  const vehicle = getVehicleName(sessionGroup.sessions);

  const handleStopSession = async (session: Session) => {
    try {
      const sessionId = session.id!;
      await http.stopSession({ sessionId });
      navigator.toPedestal(pedestal.identifier);
      showPrivateToast('Stopping the session.');
    } catch (e: any) {
      console.error(e);
    }
  };

  return (
    <Layout.Content className={sessionPageStyle}>
      <Button
        startIcon={<ChevronLeft />}
        variant={ButtonVariantEnum.TEXT}
        size={ButtonSizeEnum.LARGE}
        text={`back`}
        className={backButtonStyle}
        onClick={navigator.toPrevious}
      />
      <div className={sessionInfoGroupStyle}>
        <Info
          label="location"
          value={sessionGroup.location.name}
          className={sessionInfoStyle}
        />
        {vehicle && (
          <Info label="vehicle" value={vehicle} className={sessionInfoStyle} />
        )}
      </div>
      {sessionGroup.sessions.map((session) => (
        <SessionCard
          key={session.id}
          refetchSessions={refetch}
          session={session}
          sessionGroup={sessionGroup}
          showVehicle={!vehicle}
          handleStopSession={handleStopSession}
        />
      ))}
      <div className={SessionContentStyle}></div>
      {showActivateMore && pedestal && (
        <ActivateMore
          pedestal={pedestal}
          sessionSourceType={sessionSourceType}
        />
      )}
      <SessionTotalOverview
        currency="eur"
        groupId={sessionGroup.id}
        sessionTotalCost={sessionGroupTotal}
        sessionStartTimeStamp={new Date(sessionGroup.createdAt)}
      />
    </Layout.Content>
  );
};

const sessionPageStyle = css`
  height: calc(100vh - ${HEADER_HEIGHT} - 12.125rem);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0rem 1.5rem 2rem 1.5rem;
  scrollbar-width: none;
  overflow-y: scroll;
  overflow-x: hidden;
  &&::-webkit-scrollbar {
    display: none;
  }
`;

const backButtonStyle = css`
  margin-left: -0.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
`;

const SessionContentStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% + 3rem);
  align-self: center;
`;

const sessionInfoGroupStyle = css`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 1rem;
`;

const sessionInfoStyle = css`
  margin: 0px;
`;
