import { css } from '@emotion/css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Colors } from '../../styles/colors';

export const LocationsLoadingState = (): JSX.Element => {
  return (
    <SkeletonTheme baseColor={Colors.LIGHT_GRAY} highlightColor='#e6e6e6'>

      <Skeleton height={'11rem'} borderRadius={0} />
      <Skeleton className={marginStyle} height={'1rem'} borderRadius={0} />
      <Skeleton height={'0.5rem'} width={'80%'} borderRadius={0} />

      <Skeleton className={sectionMarginStyle} height={'11rem'} borderRadius={0} />
      <Skeleton className={marginStyle} height={'1rem'} borderRadius={0} />
      <Skeleton height={'0.5rem'} width={'80%'} borderRadius={0} />

      <Skeleton className={sectionMarginStyle} height={'11rem'} borderRadius={0} />
      <Skeleton className={marginStyle} height={'1rem'} borderRadius={0} />
      <Skeleton height={'0.5rem'} width={'80%'} borderRadius={0} />

    </SkeletonTheme>
  );
};

const marginStyle = css`
  margin-top:1rem;
  margin-bottom:0.5rem;
`;

const sectionMarginStyle = css`

  margin-top:2rem;
`;
