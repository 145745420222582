import { makeAutoObservable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { Location } from '../types/server-types';

class LocationStore {
  location: Location | null = null;

  constructor() {
    makeAutoObservable(this);

    makePersistable(this, {
      name: 'LocationStore',
      properties: ['location'],
      storage: window.localStorage,
    });
  }

  setLocation(location: Location) {
    this.location = location;
  }
  getLocation() {
    return this.location;
  }

  reset() {
    this.location = null;
  }
}

export const locationStore = new LocationStore();
