import { css } from '@emotion/css';
import { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../shared/components/Button';
import {
  Divider,
  DividerVariantEnum,
} from '../../../shared/components/Divider';
import { SelectInput } from '../../../shared/components/SelectInput';
import { TextInput } from '../../../shared/components/TextInput';
import { useAddVehicle } from '../../../shared/services/api/vehicle';
import { h3Style } from '../../../shared/styles/text';
import {
  vehicleTypeLabels,
  VehicleTypeEnum,
} from '../../../shared/types/enums';
import { AddVehicleParams } from '../../../shared/types/input-param-types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {showPrivateToast} from "../../../shared/utils/show-private-toast";

const options = [
  {
    label: vehicleTypeLabels.CAMPING_VAN,
    value: VehicleTypeEnum.CAMPING_VAN,
  },
  {
    label: vehicleTypeLabels.BOAT,
    value: VehicleTypeEnum.BOAT,
  },
];

const validationSchema = yup.object().shape({
  type: yup.string().required(),
  registration: yup.string().required(),
  length: yup.number().when('type', {
    is: VehicleTypeEnum.BOAT,
    then: yup.number().required(),
  }),
});

interface INewVehicleFormProps {
  setModalVisible: (arg: boolean) => void;
}

export const NewVehicleForm = (props: INewVehicleFormProps): JSX.Element => {
  const { setModalVisible } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const methods = useForm({
    defaultValues: {
      type: '',
      registration: '',
    },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const addVehicle = useAddVehicle();

  const vehicleType = methods.watch('type');

  const handler: SubmitHandler<AddVehicleParams> = async (
    data: AddVehicleParams,
  ): Promise<void> => {
    const { registration, type, length } = data;
    try {
      setLoading(true);
      const vehicleResponse = await addVehicle({
        registration: registration,
        type: type,
        length: length,
      });
      if (vehicleResponse) {
        showPrivateToast(`Vehicle ${registration} added`);
      }
    } catch (err: any) {
      showPrivateToast('Something went wrong!');
    } finally {
      setLoading(false);
      setModalVisible(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handler)} className={formStyle}>
        <h3 className={formHeaderStyle}>New Vehicle</h3>
        <SelectInput
          name="type"
          placeHolder={'Vehicle Type'}
          options={options}
        />
        <TextInput name="registration" placeHolder="vehicle registration" />
        {vehicleType === VehicleTypeEnum.BOAT && (
          <TextInput
            name="length"
            placeHolder="boat length (m)"
            type="number"
          />
        )}
        <Divider variant={DividerVariantEnum.FULL_WIDTH_DIVIDER} />
        <div className={buttonContainerStyle}>
          <Button
            variant={ButtonVariantEnum.TEXT}
            size={ButtonSizeEnum.MEDIUM}
            text="cancel"
            className={buttonStyle}
            onClick={() => setModalVisible(false)}
          />
          <Button
            variant={ButtonVariantEnum.TEXT}
            size={ButtonSizeEnum.MEDIUM}
            text="save"
            type={'submit'}
            className={buttonStyle}
            loading={loading}
          />
        </div>
      </form>
    </FormProvider>
  );
};

const formStyle = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const formHeaderStyle = css`
  ${h3Style}
  margin-bottom: 1.5rem;
`;

const buttonContainerStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const buttonStyle = css`
  margin-right: 1rem;
`;
