import { css, cx } from '@emotion/css';
import { useMemo } from 'react';
import { Colors } from '../../../../shared/styles/colors';
import { h1Style, h4Style, valueStyle } from '../../../../shared/styles/text';
import { ChartTabSwitchEnum } from '../../../../shared/types/enums';
import { ChartDataParams, StatsBarChart } from '../StatsBarChart';
import { getTotalAmount } from '../utils/ChartDataUtils';

const dataMonth: ChartDataParams[] = [
  {
    label: '1-6',
    amount: 1000,
  },
  {
    label: '7-12',
    amount: 1000,
  },
  {
    label: '13-18',
    amount: 500,
  },
  {
    label: '19-24',
    amount: 500,
  },
  {
    label: '25-30',
    amount: 500,
  },
];

const months = ['April', 'March', 'February'];

export const MonthTabView: React.FC = () => {
  const totalAmount = useMemo(() => getTotalAmount(dataMonth), [dataMonth]);

  return (
    <>
      {months.map((month) => (
        <div className={chartContainerStyle} key={month}>
          <h4 className={h4Style}>{month} 2021</h4>
          <StatsBarChart
            data={dataMonth}
            viewType={ChartTabSwitchEnum.MONTHLY}
          />
          <div className={totalInfoChartContainerStyle}>
            <p className={cx(valueStyle, valueTextStyle)}>
              Total for {month} 2021
            </p>
            <h1 className={h1Style}>{totalAmount} EUR</h1>
          </div>
        </div>
      ))}
    </>
  );
};

const chartContainerStyle = css`
  display: flex;
  flex-flow: column;
  align-items: start;
  min-width: calc(100% - 1.5rem);
  height: 300px;
  background-color: ${Colors.LIGHT_GRAY};
  padding: 1rem;
  gap: 1rem;
`;

const valueTextStyle = css`
  color: ${Colors.WINDOW_GRAY};
`;

const totalInfoChartContainerStyle = css`
  display: flex;
  flex-flow: column;
  align-items: start;
  gap: 0.25rem;
`;
