import { useEffect, useRef, useState } from 'react';
import { css, cx } from '@emotion/css';
import ReactCodeInput from 'react-verification-code-input';

import {
  Button,
  ButtonVariantEnum,
  ButtonSizeEnum,
} from '../../../shared/components/Button';
import { Layout } from '../../../shared/components/layout/Layout';
import { h1Style, p2Style } from '../../../shared/styles/text';
import { Colors } from '../../../shared/styles/colors';
import { ChevronLeft } from '../../../shared/assets/icons';
import { useNavigator } from '../../../routing/navigator';
import { useVerifyNewEmail } from '../../../shared/services/api/user';
import { VerifyNewEmailParams } from '../../../shared/types/input-param-types';
import {showPrivateToast} from "../../../shared/utils/show-private-toast";

export const VerifyEmailPage = (): JSX.Element => {
  const resendTimerValue = 10;
  const navigator = useNavigator();
  const [errorState, setErrorState] = useState(false);
  const [resendEmailState, setResendEmailState] = useState(false);
  const [resendTimer, setResendTimer] = useState(resendTimerValue);
  const [verificationCode, setVerificationCode] = useState<number>(0);
  const intervalRef: any = useRef(null);
  const verifyNewEmail = useVerifyNewEmail();

  const onSubmit = async (data: VerifyNewEmailParams) => {
    try {
      await verifyNewEmail(data);
      showPrivateToast('Changes saved');
    } catch {
      setErrorState(true);
      showPrivateToast('Wrong verification code');
    }
  };

  const resendEmailInterval = () => {
    intervalRef.current = setInterval(() => {
      setResendTimer((resendTimer) => resendTimer - 1);
    }, 1000);
  };

  const resendEmail = () => {
    setResendEmailState(true);
    resendEmailInterval();
  };

  useEffect(() => {
    return () => {
      if (resendTimer <= 1) {
        clearInterval(intervalRef.current);
        setResendTimer(resendTimerValue);
        setResendEmailState(false);
      }
    };
  }, [resendTimer]);

  return (
    <Layout.Content className={verifyEmailPageStyle}>
      <Button
        startIcon={<ChevronLeft />}
        variant={ButtonVariantEnum.TEXT}
        size={ButtonSizeEnum.LARGE}
        text={`back`}
        className={backButtonStyle}
        onClick={navigator.toPrevious}
      />
      <div className={verificationContentStyle}>
        <h1 className={cx(h1Style, headerStyle)}>Verify your email.</h1>
        <p className={cx(p2Style, paragraphStyle)}>
          We have sent a code to your email.
        </p>
        <ReactCodeInput
          fields={4}
          className={verificationInputStye(errorState)}
          fieldHeight={56}
          fieldWidth={52}
          onComplete={(value: string) => setVerificationCode(parseInt(value))}
        />
        <div className={infoTextStyle}>
          <p className={cx(p2Style, inputErrorStyle(errorState))}>
            Wrong code entered. Please try again.
          </p>
          <p className={cx(p2Style, resendTextStyle(resendEmailState))}>
            Email sent. You can resend it in {resendTimer} seconds.
          </p>
        </div>
        <Button
          text={'resend email'}
          type={'button'}
          variant={ButtonVariantEnum.TEXT}
          size={ButtonSizeEnum.LARGE}
          onClick={resendEmail}
          disabled={resendEmailState}
        />
      </div>

      <Button
        variant={ButtonVariantEnum.FILLED}
        className={buttonStyle}
        size={ButtonSizeEnum.LARGE}
        fullWidth={true}
        text={'verify'}
        type={'submit'}
        onClick={() => onSubmit({ code: verificationCode })}
      />
    </Layout.Content>
  );
};

const verifyEmailPageStyle = css`
  padding-top: 0rem;
`;

const backButtonStyle = css`
  padding-top: 0rem;
  margin-bottom: 4rem;
`;

const verificationContentStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const headerStyle = css`
  margin-bottom: 1rem;
  align-self: flex-start;
`;

const paragraphStyle = css`
  margin-bottom: 4rem;
  align-self: flex-start;
  color: ${Colors.WINDOW_GRAY};
`;
// TODO: wiggle animation on error state event
const verificationInputStye = (errorState: any) => css`
  display: flex;

  width: 100% !important;

  div {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  input {
    margin: 0px 1.0625rem;
    border: 2px solid ${Colors.LIGHT_GRAY} !important;
    border-radius: 0px !important;
    font: normal normal bold 2rem Doctrine !important;
    color: ${Colors.BLACK} !important;

    ${errorState === true &&
    `
      border-color: ${Colors.ERROR} !important;
    `}
  }

  input:focus {
    border: 2px solid ${Colors.CAPRI_BLUE} !important;
  }
`;

const infoTextStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 7.25rem;
`;

const inputErrorStyle = (errorState: any) => css`
  color: ${Colors.ERROR};
  margin-top: 1rem;
  ${errorState === false &&
  `
    visibility: hidden;
  `}
`;

const resendTextStyle = (resendEmail: any) => css`
  color: ${Colors.WINDOW_GRAY};
  margin-top: calc(4rem - 1.5rem - 1rem);
  ${resendEmail === false &&
  `
    visibility: hidden;
  `}
`;
const buttonStyle = css`
  margin-top: 3rem;
  margin-bottom: 8rem;
  align-self: center;
`;
