import React from 'react';
import { useCurrentUser } from '../services/api/user';
import { User } from '../types/server-types';
import LogRocket from 'logrocket';

export const Context = React.createContext<User | undefined>(undefined);

export const useUser = (): User | undefined => {
  return React.useContext(Context)!;
};

interface UserContextProps {
  children: JSX.Element[] | JSX.Element;
}

export const UserContext = (props: UserContextProps): JSX.Element | null => {
  const { data, isLoading } = useCurrentUser();

  if (isLoading) {
    return null;
  }

  if(LogRocket && data && data.id) {
    LogRocket.identify(Number(data.id).toString(), {
      name: data.name,
      email: data.email || data.username || '',
      country: data.country || '',
      role: data.role,
    });
  }

  return <Context.Provider value={data}>{props.children}</Context.Provider>;
};
