import { css } from '@emotion/css';
import { h2Style } from '../../../shared/styles/text';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../shared/components/Button';
import { ChevronRight } from '../../../shared/assets/icons';
import { useLocations } from '../../../shared/services/api/location';
import { LocationCard } from './LocationCard';
import { Location } from '../../../shared/types/server-types';
import { useNavigator } from '../../../routing/navigator';
import { Colors } from '../../../shared/styles/colors';
import { LocationsLoadingState } from '../../../shared/components/loading-states/LoactionsLoadingState';
import { useMemo } from 'react';

export const ExploreSection = (): JSX.Element => {
  const { data: locations, isLoading, isError } = useLocations({ limit: 2 });
  const navigator = useNavigator();

  if (isError) {
    return <p>Error</p>;
  }
  const loading = useMemo(() => isLoading || !locations, [locations]);


  return (
    <section className={containerStyle}>
      <div className={titleContainer}>
        <h2 className={titleStyle}>Explore locations</h2>
        <div className={titleButtonContainerStyle}>
          <Button
            variant={ButtonVariantEnum.TEXT}
            size={ButtonSizeEnum.MEDIUM}
            text="See All"
            onClick={navigator.toLocations}
            endIcon={<ChevronRight className={chevronStyle} />}
          />
        </div>
      </div>
      {loading && <LocationsLoadingState />}
      {locations?.map((location: Location, index: number) => {
        return <LocationCard location={location} key={index} />;
      })}
    </section>
  );
};

const containerStyle = css`
  width: 100%;
`;

const titleContainer = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

const titleStyle = css`
  ${h2Style}
`;

const titleButtonContainerStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const chevronStyle = css`
  path {
    stroke: ${Colors.CAPRI_BLUE};
  }
`;
