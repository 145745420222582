import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { Colors } from '../../../shared/styles/colors';
import { ChartTabSwitchEnum } from '../../../shared/types/enums';

export type ChartDataParams = {
  label: string;
  amount: number;
};

type StatsBarChartProps = {
  data: ChartDataParams[];
  viewType: ChartTabSwitchEnum;
};

export const StatsBarChart: React.FC<StatsBarChartProps> = (
  props: StatsBarChartProps,
) => {
  const { data, viewType } = props;

  return (
    <ResponsiveContainer width="100%" height={152}>
      <BarChart
        data={data}
        barSize={viewType === ChartTabSwitchEnum.YEARLY ? 12 : 24}
      >
        <CartesianGrid vertical={false} />
        <Bar dataKey="amount" fill={Colors.CAPRI_BLUE} />
        <XAxis
          dataKey="label"
          axisLine={false}
          tickLine={false}
          fontSize={12}
          height={14}
        />
        <YAxis
          dataKey="amount"
          axisLine={false}
          tickLine={false}
          orientation="right"
          fontSize={10}
          width={28}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
