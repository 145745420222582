export enum SourceTypeEnum {
  SOCKET = 'SOCKET',
  VALVE = 'VALVE',
}

export enum PowerTypeEnum {
  SINGLE = 'MONO_PHASE',
  TRIPLE = 'THREE_PHASE',
}

export enum LocationType {
  MARINA = 'MARINA',
  HARBOR = 'HARBOR',
  CAMP = 'CAMP',
  OTHER = 'OTHER',
}

export enum VehicleTypeEnum {
  BOAT = 'BOAT',
  CAMPING_VAN = 'CAMPING_VAN',
}

enum SourceLabelEnum {
  POWER = 'POWER',
  WATER = 'WATER',
}

enum PowerLabelEnum {
  SINGLE = 'mono-phase',
  TRIPLE = 'three-phase',
}

enum VehicleTypeLabelEnum {
  BOAT = 'Boat',
  CAMPING_VAN = 'Camper van',
}

export enum ChartTabSwitchEnum {
  WEEKLY = 0,
  MONTHLY = 1,
  YEARLY = 2,
}

export const sourceLabels = {
  [SourceTypeEnum.SOCKET]: SourceLabelEnum.POWER,
  [SourceTypeEnum.VALVE]: SourceLabelEnum.WATER,
};

export const powerLabels = {
  [PowerTypeEnum.SINGLE]: PowerLabelEnum.SINGLE,
  [PowerTypeEnum.TRIPLE]: PowerLabelEnum.TRIPLE,
};

export const vehicleTypeLabels = {
  [VehicleTypeEnum.BOAT]: VehicleTypeLabelEnum.BOAT,
  [VehicleTypeEnum.CAMPING_VAN]: VehicleTypeLabelEnum.CAMPING_VAN,
};

export enum AccountTypeEnum {
  STANDARD = 'STANDARD',
  CHARTER = 'CHARTER',
}

export enum UserRole {
  CLIENT = 'CLIENT',
  CHARTER_OWNER = 'CHARTER_OWNER',
  CHARTER_EMPLOYEE = 'CHARTER_EMPLOYEE',
}

export enum PaymentTypeEnum {
  PREPAID = 'PREPAID',
  POSTPAID = 'POSTPAID',
  PERIODICAL = 'PERIODICAL',
}

export enum SupportedCountries {
  CROATIA = 'CROATIA',
}

export enum ReceiptStatusEnum {
  UNPAID = 'UNPAID',
  PAID = 'PAID',
}

export enum ReceiptDurationEnum {
  LESS_THAN_24_HOURS = 'Less than 24 h',
  LESS_THAN_A_WEEK = 'Less than a week',
  MORE_THAN_A_WEEK = 'More than a week',
}
