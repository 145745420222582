import { Socket, SourceStatusEnum, Valve } from '../types/server-types';

type AvailableSourcesCount = {
  availablePower: number,
  availableWater: number
}

export const filterSources = (valves: Valve[], sockets: Socket[]): AvailableSourcesCount => {
  const availablePower = sockets.filter(
    (x) => x.status === SourceStatusEnum.AVAILABLE,
  ).length;
  const availableWater = valves.filter(
    (x) => x.status === SourceStatusEnum.AVAILABLE,
  ).length;
  return { availablePower, availableWater };
};
