import { css } from '@emotion/css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Colors } from '../../styles/colors';

export const HistoryLoadingState = (): JSX.Element => {
  return (


    <SkeletonTheme baseColor={Colors.LIGHT_GRAY} highlightColor='#e6e6e6'>

      <div className={paddingStyle}>
        <Skeleton className={leadingSectionMarginStyle} height={'1.1875rem'} borderRadius={0} />
        <Skeleton className={middleSectionMarginStyle} height={'1.1875rem'} width={'65%'} borderRadius={0} />
      </div>
      <Skeleton className={lastSectionMarginStyle} height={'0.0625rem'} borderRadius={0} />

      <div className={paddingStyle}>
        <Skeleton className={leadingSectionMarginStyle} height={'1.1875rem'} borderRadius={0} />
        <Skeleton className={middleSectionMarginStyle} height={'1.1875rem'} width={'65%'} borderRadius={0} />
      </div>
      <Skeleton className={lastSectionMarginStyle} height={'0.0625rem'} borderRadius={0} />

      <div className={paddingStyle}>
        <Skeleton className={leadingSectionMarginStyle} height={'1.1875rem'} borderRadius={0} />
        <Skeleton className={middleSectionMarginStyle} height={'1.1875rem'} width={'65%'} borderRadius={0} />
      </div>
      <Skeleton className={lastSectionMarginStyle} height={'0.0625rem'} borderRadius={0} />

      <div className={paddingStyle}>
        <Skeleton className={leadingSectionMarginStyle} height={'1.1875rem'} borderRadius={0} />
        <Skeleton className={middleSectionMarginStyle} height={'1.1875rem'} width={'65%'} borderRadius={0} />
      </div>
      <Skeleton className={lastSectionMarginStyle} height={'0.0625rem'} borderRadius={0} />

      <div className={paddingStyle}>
        <Skeleton className={leadingSectionMarginStyle} height={'1.1875rem'} borderRadius={0} />
        <Skeleton className={middleSectionMarginStyle} height={'1.1875rem'} width={'65%'} borderRadius={0} />
      </div>
      <Skeleton className={lastSectionMarginStyle} height={'0.0625rem'} borderRadius={0} />

      <div className={paddingStyle}>
        <Skeleton className={leadingSectionMarginStyle} height={'1.1875rem'} borderRadius={0} />
        <Skeleton className={middleSectionMarginStyle} height={'1.1875rem'} width={'65%'} borderRadius={0} />
      </div>
      <Skeleton className={lastSectionMarginStyle} height={'0.0625rem'} borderRadius={0} />

      <div className={paddingStyle}>
        <Skeleton className={leadingSectionMarginStyle} height={'1.1875rem'} borderRadius={0} />
        <Skeleton className={middleSectionMarginStyle} height={'1.1875rem'} width={'65%'} borderRadius={0} />
      </div>
      <Skeleton className={lastSectionMarginStyle} height={'0.0625rem'} borderRadius={0} />

      <div className={paddingStyle}>
        <Skeleton className={leadingSectionMarginStyle} height={'1.1875rem'} borderRadius={0} />
        <Skeleton className={middleSectionMarginStyle} height={'1.1875rem'} width={'65%'} borderRadius={0} />
      </div>
      <Skeleton className={lastSectionMarginStyle} height={'0.0625rem'} borderRadius={0} />

      <div className={paddingStyle}>
        <Skeleton className={leadingSectionMarginStyle} height={'1.1875rem'} borderRadius={0} />
        <Skeleton className={middleSectionMarginStyle} height={'1.1875rem'} width={'65%'} borderRadius={0} />
      </div>
      <Skeleton className={lastSectionMarginStyle} height={'0.0625rem'} borderRadius={0} />

      <div className={paddingStyle}>
        <Skeleton className={leadingSectionMarginStyle} height={'1.1875rem'} borderRadius={0} />
        <Skeleton className={middleSectionMarginStyle} height={'1.1875rem'} width={'65%'} borderRadius={0} />
      </div>
      <Skeleton className={lastSectionMarginStyle} height={'0.0625rem'} borderRadius={0} />

      <div className={paddingStyle}>
        <Skeleton className={leadingSectionMarginStyle} height={'1.1875rem'} borderRadius={0} />
        <Skeleton className={middleSectionMarginStyle} height={'1.1875rem'} width={'65%'} borderRadius={0} />
      </div>
      <Skeleton className={lastSectionMarginStyle} height={'0.0625rem'} borderRadius={0} />

      <div className={paddingStyle}>
        <Skeleton className={leadingSectionMarginStyle} height={'1.1875rem'} borderRadius={0} />
        <Skeleton className={middleSectionMarginStyle} height={'1.1875rem'} width={'65%'} borderRadius={0} />
      </div>
      <Skeleton className={lastSectionMarginStyle} height={'0.0625rem'} borderRadius={0} />

      <div className={paddingStyle}>
        <Skeleton className={leadingSectionMarginStyle} height={'1.1875rem'} borderRadius={0} />
        <Skeleton className={middleSectionMarginStyle} height={'1.1875rem'} width={'65%'} borderRadius={0} />
      </div>
      <Skeleton className={lastSectionMarginStyle} height={'0.0625rem'} borderRadius={0} />

      <div className={paddingStyle}>
        <Skeleton className={leadingSectionMarginStyle} height={'1.1875rem'} borderRadius={0} />
        <Skeleton className={middleSectionMarginStyle} height={'1.1875rem'} width={'65%'} borderRadius={0} />
      </div>
      <Skeleton className={lastSectionMarginStyle} height={'0.0625rem'} borderRadius={0} />

    </SkeletonTheme>


  );
};

const leadingSectionMarginStyle = css`
  margin-top:0.5rem;

`;

const middleSectionMarginStyle = css`
  margin-top:0.25rem;

`;

const lastSectionMarginStyle = css`
  margin-top:0.5rem;

`;


const paddingStyle = css`
  padding-right:1.5rem;
`;
