import React, { useState } from 'react';
import { css, cx } from '@emotion/css';
import { TextInput } from '../../shared/components/TextInput';
import { FormProvider, useForm } from 'react-hook-form';
import {
  CurrencyAmountCard,
  selectedCurrencyCardStyle,
  unselectedCurrencyCardStyle,
} from './CurrencyAmountCard';
import {
  CheckCircleOutline,
  CheckCircleSolidInverted,
} from '../../shared/assets/icons';
import { Colors } from '../../shared/styles/colors';

enum AmountOptionsEnum {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
  OTHER_AMOUNT = 4,
}

interface ICurrencyAmountSelectionProps {
  setAmount: (amount: string) => void;
}

export const CurrencyAmountSelection = (
  props: ICurrencyAmountSelectionProps,
): JSX.Element => {
  const { setAmount } = props;
  const [selectedOption, setSelectedOption] = useState<AmountOptionsEnum>();
  const methods = useForm({
    defaultValues: {
      amount: '',
    },
  });

  return (
    <FormProvider {...methods}>
      <form
        className={customAmountInputStyle}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className={currencyAmountSelectionStyle}>
          <CurrencyAmountCard
            onClick={() => {
              setSelectedOption(AmountOptionsEnum.FIRST), setAmount('20');
            }}
            amount={20}
            estimatedTime={'≈ 12 hours'}
            isSelected={selectedOption === AmountOptionsEnum.FIRST}
          />
          <CurrencyAmountCard
            onClick={() => {
              setSelectedOption(AmountOptionsEnum.SECOND), setAmount('50');
            }}
            amount={50}
            estimatedTime={'≈ 1 day'}
            isSelected={selectedOption === AmountOptionsEnum.SECOND}
          />
          <CurrencyAmountCard
            onClick={() => {
              setSelectedOption(AmountOptionsEnum.THIRD), setAmount('100');
            }}
            amount={100}
            estimatedTime={'≈ 2 days'}
            isSelected={selectedOption === AmountOptionsEnum.THIRD}
          />
          {selectedOption === AmountOptionsEnum.OTHER_AMOUNT ? (
            <div
              className={cx(
                unselectedCurrencyCardStyle,
                selectedCurrencyCardStyle,
              )}
              onClick={() => setSelectedOption(AmountOptionsEnum.OTHER_AMOUNT)}
            >
              <div>
                <span>Other amount</span>
                <CheckCircleSolidInverted
                  className={cx(iconStyle, circleSolidInvertedStyle)}
                />
              </div>
            </div>
          ) : (
            <div
              className={unselectedCurrencyCardStyle}
              onClick={() => {
                setSelectedOption(AmountOptionsEnum.OTHER_AMOUNT);
                setAmount(methods.getValues('amount'));
              }}
            >
              <div>
                <span>Other amount</span>
                <CheckCircleOutline className={iconStyle} />
              </div>
            </div>
          )}
          {selectedOption === AmountOptionsEnum.OTHER_AMOUNT && (
            <TextInput
              name={'amount'}
              placeHolder={'enter an amount'}
              type={'text'}
              className={customAmountInputStyle}
              onChange={setAmount}
            />
          )}
        </div>
      </form>
    </FormProvider>
  );
};

const currencyAmountSelectionStyle = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  width: 100%;
`;

const customAmountInputStyle = css`
  grid-column: span 2;
`;

const iconStyle = css`
  min-width: 1.5rem;
  min-height: 1.5rem;
  align-self: flex-start;
`;

const circleSolidInvertedStyle = css`
  fill: ${Colors.WHITE};
`;
