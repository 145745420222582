import { css } from '@emotion/css';
import { Colors } from '../../shared/styles/colors';
import { h3Style } from '../../shared/styles/text';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../shared/components/Button';
import { useNavigator } from '../../routing/navigator';

interface QRScannerMaskProps {
  error: boolean;
  setCodeModalVisible(value: boolean): void;
}

export const QRScannerMask = (props: QRScannerMaskProps): JSX.Element => {
  const { error, setCodeModalVisible } = props;
  const navigator = useNavigator();

  return (
    <div className={maskContainerStyle}>
      <div className={viewFinderContainerStyle}>
        <h3 className={titleStyle}>Scan the QR code</h3>
        <div className={viewFinderStyle}>
          <hr className={viewFinderLine} />
        </div>
        <h3 className={errorStyle(error)}>Invalid Code</h3>
      </div>
      <div className={buttonsContainerStyle}>
        <Button
          variant={ButtonVariantEnum.OUTLINE}
          size={ButtonSizeEnum.MEDIUM}
          className={buttonStyle}
          text="enter id"
          onClick={() => setCodeModalVisible(true)}
        />
        <Button
          variant={ButtonVariantEnum.TEXT}
          size={ButtonSizeEnum.MEDIUM}
          className={buttonStyle}
          text="go back"
          onClick={navigator.toPrevious}
        />
      </div>
    </div>
  );
};

const maskContainerStyle = css`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const titleStyle = css`
  ${h3Style}
  color: ${Colors.WHITE};
  margin-bottom: 1.5rem;
`;

const errorStyle = (error: boolean) => css`
  ${h3Style}
  color: ${Colors.ERROR};
  margin-top: 1.5rem;
  visibility: ${error ? 'visible' : 'hidden'};
`;

const viewFinderContainerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const viewFinderStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 12.5rem;
  height: 12.5rem;
  background: rgba(255, 255, 255, 0.25);
  position: relative;
`;

const viewFinderLine = css`
  color: red;
  margin: 0px;
  width: 150%;

  position: absolute;
  top: 0px;
  animation: move 3s infinite;

  @keyframes move {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(12.5rem);
    }
  }
`;

const buttonStyle = css`
  color: ${Colors.WHITE};
  border-color: ${Colors.WHITE};
  margin-bottom: 1rem;
`;

const buttonsContainerStyle = css`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 6vh;
`;
