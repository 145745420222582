import {UseMutateAsyncFunction, useMutation, useQuery, UseQueryResult} from 'react-query';
import { http } from '../http';
import { CacheKeys } from './cache-configuration';
import {SessionGroup, SessionRequest} from "../../types/server-types";

export const useStartSession = (): UseMutateAsyncFunction => {
  const { mutateAsync } = useMutation<any, any, any>(http.startSession, {
    onError: (error) => {
      throw error;
    },
  });
  return mutateAsync;
};

export const useStopSession = (): UseMutateAsyncFunction => {
  const { mutateAsync } = useMutation<any, any, any>(http.stopSession, {
    onError: (error) => {
      throw error;
    },
  });
  return mutateAsync;
};

export const useSessionRequest = (requestId: number | undefined):  UseQueryResult<SessionRequest> => {
  return useQuery(
    CacheKeys.SESSION_REQUEST,
    () => http.getSessionRequest(requestId),
    { enabled: !!requestId, refetchInterval: 1000 },
  );
};

export const useActiveSessionGroup = (accountId: number | undefined): UseQueryResult<SessionGroup> => {
  return useQuery(
    CacheKeys.SESSION_GROUP,
    () => http.getActiveSessionGroup(accountId),
    { enabled: !!accountId },
  );
};
