import { css, cx } from '@emotion/css';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { PowerIcon, WaterIcon } from '../../../shared/assets/icons';
import InfoGroup from '../../../shared/components/InfoGroup';
import { Colors } from '../../../shared/styles/colors';
import { h2Style, labelStyle, p2Style } from '../../../shared/styles/text';
import { PowerTypeEnum, SourceTypeEnum } from '../../../shared/types/enums';
import { ReceiptItem } from '../../../shared/types/server-types';
import { getTimeDiffFormated } from '../../../shared/utils/timeDiffFormated';
import { convertEURToHRK } from '../../../shared/utils/currency-conversion';

interface IReceiptItemOverviewCardProps {
  item: ReceiptItem;
  last: boolean;
  showVehicle: boolean;
}

export const ReceiptItemOverviewCard = (
  props: IReceiptItemOverviewCardProps,
): JSX.Element => {
  const { item, last, showVehicle } = props;

  const formatedDuration = getTimeDiffFormated(item.startDate, item.endDate);

  const infoGroupElements = [
    item.sourceType === SourceTypeEnum.VALVE
      ? {
          label: 'consumption',
          value: `${item.consumption.toFixed(3)} m\u00B3`,
          Icon: InformationCircleIcon,
          iconClassName: infoIconStyle,
        }
      : {
          label: 'consumption',
          value: `${item.consumption.toFixed(3)} kWh`,
          Icon: InformationCircleIcon,
          iconClassName: infoIconStyle,
        },
    { label: 'duration', value: formatedDuration },
    {
      label: 'total',
      value: `${(item.consumption * item.tariff).toFixed(2)} EUR (${convertEURToHRK(item.consumption * item.tariff)} HRK)`,
    },
  ];

  return (
    <div className={cx(itemCardStyle, last && lastItemCardStyle)}>
      <div className={itemCardHeaderStyle}>
        <div>
          <p className={p2Style}>ID {item.pedestalIdentifier}</p>
          <h2 className={h2Style}>{item.service}</h2>
        </div>
        {item.sourceType === SourceTypeEnum.SOCKET ? (
          item.phaseType === PowerTypeEnum.SINGLE ? (
            <PowerIcon className={iconStyle(Colors.MONO_PHASE)} />
          ) : (
            <PowerIcon className={iconStyle(Colors.THREE_PHASE)} />
          )
        ) : (
          <WaterIcon className={iconStyle(Colors.CAPRI_BLUE)} />
        )}
      </div>
      <InfoGroup
        elements={
          showVehicle
            ? [
                ...infoGroupElements,
                { label: 'vehicle', value: item.vehicleRegistration },
              ]
            : infoGroupElements
        }
      />
    </div>
  );
};

const itemCardStyle = css`
  position: relative;
  background-color: ${Colors.LIGHT_GRAY};
  width: calc(100% + 3rem);
  align-self: center;
  padding: 1rem 1.5rem 0.5rem 1.5rem;
  margin-bottom: 2rem;
  > label {
    ${labelStyle.toString()}
    color: ${Colors.GRAY};
    text-transform: capitalize;
  }
`;

const lastItemCardStyle = css`
  margin-bottom: 4rem;
`;

const itemCardHeaderStyle = css`
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  margin-bottom: 1rem;

  > div > p {
    color: ${Colors.GRAY};
  }

  > svg {
  }
`;

const iconStyle = (color: string) => css`
  width: 3rem;
  height: 3rem;
  align-self: center;
  > path {
    fill: ${color};
  }
`;

const infoIconStyle = css`
  width: 24px;
  color: ${Colors.CAPRI_BLUE};
`;
