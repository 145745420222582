import { css } from '@emotion/css';
import { Colors } from '../../../shared/styles/colors';
import { PowerTypeEnum, SourceTypeEnum } from '../../../shared/types/enums';
import { Socket, Source, Valve } from '../../../shared/types/server-types';

export type CardStyle = {
  borderStyle: string;
  backgroundStyle: string;
  colorStyle: string;
  svgStyle: string;
};

//TODO: DEPRECATED: remove replace/unify with pedestal page styles
export const determineCardStyle = (source: Source): CardStyle => {
  let style: CardStyle;

  if (source.state.activated) {
    style = {
      backgroundStyle: `${Colors.WINDOW_GRAY}1A`,
      borderStyle: Colors.WINDOW_GRAY,
      colorStyle: Colors.WINDOW_GRAY,
      svgStyle: Colors.WINDOW_GRAY,
    };
  } else {
    if ((source as Valve).type === SourceTypeEnum.VALVE) {
      style = {
        backgroundStyle: '',
        borderStyle: Colors.CAPRI_BLUE,
        colorStyle: Colors.CAPRI_BLUE,
        svgStyle: '',
      };
    } else {
      if ((source as Socket).phase === PowerTypeEnum.SINGLE) {
        style = {
          backgroundStyle: '',
          borderStyle: Colors.MONO_PHASE,
          colorStyle: '',
          svgStyle: '',
        };
      } else {
        style = {
          backgroundStyle: '',
          borderStyle: Colors.THREE_PHASE,
          colorStyle: '',
          svgStyle: '',
        };
      }
    }
  }
  return style;
};

export const reactiveCardStyle = (cardStyle: CardStyle): string => css`
  border: 2px solid ${cardStyle.borderStyle};
  border-left: 0.875rem solid ${cardStyle.borderStyle};
  background-color: ${cardStyle.backgroundStyle};
  color: ${cardStyle.colorStyle};
  figure {
    h4 {
      color: ${cardStyle.colorStyle};
    }
    svg {
      path {
        fill: ${cardStyle.svgStyle};
      }
    }
  }
  label {
  }
`;

export const sourceCardStyle = css`
  min-width: 9.125rem;
  min-height: 4rem;
  cursor: pointer;
  border: 2px solid #1684dd;
  border-left: 0.875rem solid #1684dd;
  transition: 0.5s ease-out;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.75rem;
  figure {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;

    h4 {
      text-transform: capitalize;
      transition: 0.5s ease-out;
    }
    svg {
      path {
        transition: 0.5s ease-out;
      }
    }
  }
  label {
    cursor: pointer;
    color: ${Colors.WINDOW_GRAY};
  }
`;
