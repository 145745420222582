import { css } from '@emotion/css';
import { Layout } from '../../../shared/components/layout/Layout';
import { h1Style } from '../../../shared/styles/text';
import { TextInput } from '../../../shared/components/TextInput';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../shared/components/Button';
import { ChevronLeft } from '../../../shared/assets/icons';
import { useNavigator } from '../../../routing/navigator';
import { useUpdateMyPassword } from '../../../shared/services/api/user';
import { UpdateMyPasswordParams } from '../../../shared/types/input-param-types';
import { useState } from 'react';
import {showPrivateToast} from "../../../shared/utils/show-private-toast";

const validationSchema: yup.SchemaOf<UpdateMyPasswordParams> = yup
  .object()
  .shape({
    currentPassword: yup.string().required(),
    newPassword: yup.string().required(),
  })
  .defined();

export const PasswordPage = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigator = useNavigator();
  const updatePassword = useUpdateMyPassword();
  const methods = useForm<UpdateMyPasswordParams>({
    defaultValues: {
      newPassword: '',
      currentPassword: '',
    },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const handlePersonalInformationUpdate: SubmitHandler<
    UpdateMyPasswordParams
  > = async (data: UpdateMyPasswordParams) => {
    try {
      setIsLoading(true);
      await updatePassword(data);
      showPrivateToast('Password changed');
      navigator.toAccount();
    } catch (err: any) {
      showPrivateToast('Current password incorrect');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout.Content className={passwordPageStyle}>
      <Button
        startIcon={<ChevronLeft />}
        variant={ButtonVariantEnum.TEXT}
        size={ButtonSizeEnum.LARGE}
        text={`account`}
        className={backButtonStyle}
        onClick={navigator.toPrevious}
      />
      <div className={passwordPageHeaderStyle}>
        <h1>Change Password</h1>
      </div>
      <FormProvider {...methods}>
        <form
          className={formStyle}
          onSubmit={methods.handleSubmit(handlePersonalInformationUpdate)}
        >
          <TextInput
            placeHolder={'Current password'}
            name="currentPassword"
            markAsRequired={true}
            type="password"
          />
          <TextInput
            placeHolder={'New password'}
            name="newPassword"
            markAsRequired={true}
            type="password"
          />
          <Button
            disabled={isLoading}
            variant={ButtonVariantEnum.FILLED}
            size={ButtonSizeEnum.LARGE}
            fullWidth={true}
            text="save"
            type={'submit'}
            loading={isLoading}
            className={submitButtonStyle}
          />
        </form>
      </FormProvider>
    </Layout.Content>
  );
};

const formStyle = css`
  padding-top: 3.5rem;
`;

const passwordPageStyle = css`
  padding-top: 0rem;
`;

const backButtonStyle = css`
  padding-top: 0rem;
  margin-bottom: 4rem;
`;

const submitButtonStyle = css`
  margin-top: 4rem;
`;

const passwordPageHeaderStyle = css`
  h1 {
    ${h1Style}
    margin-bottom: 1rem;
  }
`;
