import { useState } from 'react';
import { css } from '@emotion/css';
import { QrReader } from '@blackbox-vision/react-qr-reader';
import { QRScannerMask } from './QRScannerMask';
import { http } from '../../shared/services/http';
import { EnterCodeModal } from './EnterCodeModal';
import { locationStore } from '../../shared/stores/location-store';
import { useNavigator } from '../../routing/navigator';
import { useUser } from '../../shared/providers/user-provider';
import { useQueryClient } from 'react-query';
import { CacheKeys } from '../../shared/services/api/cache-configuration';
import {Loader} from "../../shared/components/Loader";

enum Constraints {
  USER = 'user',
  ENVIRONMENT = 'environment',
}

export const ScanPage = (): JSX.Element => {
  const navigator = useNavigator();
  const [error, setError] = useState<boolean>(false);
  const [codeModalVisible, setCodeModalVisible] = useState<boolean>(false);
  const user = useUser();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState<boolean>(false)

  if(loading){
    return <Loader/>
  }

  const checkIfEnteredCodeValid = async (scannedData: string) => {
    if (scannedData) {
      let pedestalId = scannedData;
      if (scannedData.includes('/')) {
        pedestalId = scannedData.substring(scannedData.lastIndexOf('/') + 1);
      }
      pedestalId = pedestalId.toLowerCase();
      try {
        setLoading(true)
        const pedestal = await http.getPedestal({
          identifier: pedestalId,
        });
        if (pedestal) {
          setError(false);
          setCodeModalVisible(false);
          locationStore.setLocation(pedestal.location);
          if (
            user &&
            user.accounts.some((a) => a.location.id === pedestal.location.id)
          ) {
            navigator.toPedestal(pedestalId);
          } else {
            await http.createAccount(pedestal.location.id);
            await queryClient.refetchQueries([CacheKeys.ME]);
            navigator.toPedestal(pedestalId);
          }
        } else {
          setError(true);
          setCodeModalVisible(false);
        }
      } catch (error) {
        setError(true);
        setCodeModalVisible(false);
      } finally {
        setLoading(false)
      }
    }
  };

  return (
    <>
      <QrReader
        constraints={{ facingMode: Constraints.ENVIRONMENT }}
        className={qrReaderStyle}
        ViewFinder={() => (
          <QRScannerMask
            error={error}
            setCodeModalVisible={setCodeModalVisible}
          />
        )}
        containerStyle={containerStyle}
        videoContainerStyle={videoContainerStyle}
        videoStyle={videoStyle}
        onResult={async (result: any) => {
          const pedestalId = result?.text;
          await checkIfEnteredCodeValid(pedestalId);
        }}
      />
      <EnterCodeModal
        visible={codeModalVisible}
        setVisible={setCodeModalVisible}
        onSubmit={checkIfEnteredCodeValid}
      />
    </>
  );
};

const containerStyle = {
  height: '100vh',
  overflow: 'hidden',
};

const videoContainerStyle = {
  height: '100vh',
  overflow: 'hidden',
};

const videoStyle = {
  objectFit: 'cover',
  height: '100vh',
  overflow: 'hidden',
};

const qrReaderStyle = css`
  width: 100%;
`;
