import React from 'react';
import { BottomNavigationBar } from '../shared/components/BottomNavigationBar';
import { Layout } from '../shared/components/layout/Layout';
interface IPublicRouteProps {
  component: React.FC;
  showHeader?: boolean;
  showFooter?: boolean;
  showBottomNavigationBar?: boolean;
  isAuthenticated: boolean;
}

export const PublicRoute = (props: IPublicRouteProps): JSX.Element => {
  const {
    component: Component,
    showHeader = true,
    showFooter = false,
    showBottomNavigationBar = false,
    isAuthenticated,
  } = props;

  return (
    <>
      {showHeader && <Layout.Header />}
      <Component />
      {showFooter && <Layout.Footer />}
      {isAuthenticated && showBottomNavigationBar && <BottomNavigationBar />}
    </>
  );
};
