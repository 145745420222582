import { css, cx } from '@emotion/css';
import { useNavigator } from '../../routing/navigator';
import {
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../shared/components/Button';
import { Colors } from '../../shared/styles/colors';
import { h1Style, h3Style, valueStyle } from '../../shared/styles/text';
import { PaymentTypeEnum } from '../../shared/types/enums';
import { Account } from '../../shared/types/server-types';
import { convertEURToHRK } from '../../shared/utils/currency-conversion';

interface AccountCardProps {
  account: Account;
}

export const AccountCard = (props: AccountCardProps): JSX.Element => {
  const navigator = useNavigator();
  const { account } = props;

  return (
    <div>
      <div className={cardOuterRootStyle(account.paymentType)}>
        <div className={cardToolbarStyle}>
          <h3 className={cx(h3Style, locationTextStyle, whiteTextStyle)}>
            {account.location.name}
          </h3>
        </div>
        {account.paymentType === PaymentTypeEnum.PREPAID ? (
          <div>
            <p className={cx(valueStyle, whiteTextStyle)}>Available funds</p>
            <h1 className={cx(h1Style, whiteTextStyle)}>
              {account.balance.toFixed(2)} EUR
            </h1>
            <h1 className={cx(h1Style, whiteTextStyle)}>
              ({convertEURToHRK(account.balance)} HRK)
            </h1>
          </div>
        ) : (
          <h1 className={accountTypeTextStyle}>{account.paymentType}</h1>
        )}
      </div>
      <div className={cardActionRootStyle}>
        {account.paymentType === PaymentTypeEnum.PREPAID && (
          <>
            <Button
              variant={ButtonVariantEnum.FILLED}
              size={ButtonSizeEnum.MEDIUM}
              text={`add funds`}
              fullWidth
              onClick={() =>
                navigator.toWalletAddFundsToLocation(account.location.id)
              }
            />
            {/*<Button*/}
            {/*  variant={ButtonVariantEnum.OUTLINE}*/}
            {/*  size={ButtonSizeEnum.MEDIUM}*/}
            {/*  text={`refund`}*/}
            {/*/>*/}
          </>
        )}
      </div>
    </div>
  );
};

const cardActionRootStyle = css`
  display: flex;
  width: 100%;
`;

const cardToolbarStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 1.18rem;
`;

const whiteTextStyle = css`
  color: ${Colors.WHITE};
`;
const locationTextStyle = css`
  max-width: 50%;
`;

const accountTypeTextStyle = css`
  ${h1Style}
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase;
  }
  color: ${Colors.WHITE};
  margin-top: 3rem;
`;

const cardOuterRootStyle = (paymentType: PaymentTypeEnum) => css`
  margin-top: 2rem;
  padding: 1rem;
  background-color: ${paymentType === PaymentTypeEnum.PREPAID
    ? Colors.WINDOW_GRAY
    : Colors.CAPRI_BLUE};
`;
