import {css} from '@emotion/css';
import {Link} from 'react-router-dom';
import {appRoutes} from '../../../routing/app-routes';
import {
  AmericanExpressIcon,
  DinersIcon,
  DiscoverIcon, IDCheck,
  MaestroIcon,
  MarexLogo,
  MasterCardIcon,
  VisaIcon, VisaSecure,
  WSPayIcon
} from '../../assets/icons';
import {Colors} from '../../styles/colors';
import {p2Style, valueStyle} from '../../styles/text';
import {Layout} from './Layout';

export const Footer = (): JSX.Element => {

  return (
    <footer className={containerStyle}>
      <Layout.Content>
        <div className={contentContainerStyle}>
          <ul className={listStyle}>
            <li className={listItemStyle}>
              <Link to={appRoutes.privacyPolicy}>Privacy Policy</Link>
            </li>
            <li className={listItemStyle}>
              <Link to={appRoutes.termsOfUse}>Terms of Use</Link>
            </li>
            <li className={listItemStyle}>
              <Link to={appRoutes.termsOfSale}>Terms of Sale</Link>
            </li>
          </ul>
          <hr className={separatorStyle} />
            <div className={cardIssuersContainerStyle}>
              <p>We accept following card issuers:</p>
              <div className={cardIconstContainerStyle}>
                <a target="_blank" href="https://www.mastercard.com/hr/" rel="noreferrer">  <img src={IDCheck} alt="" height={24}/> </a>
                <a target="_blank" href="https://www.visa.co.uk/products/visa-secure.html" rel="noreferrer">  <img src={VisaSecure} alt="" height={24}/> </a>
                <a target="_blank" href="https://www.mastercard.com/hr/" rel="noreferrer">  <img src={MaestroIcon} alt="" height={24}/> </a>
                <a target="_blank" href="https://www.mastercard.hr/hr-hr.html" rel="noreferrer"> <img src={MasterCardIcon} alt="" height={24}/> </a>
              </div>
              <div className={cardIconstContainerStyle}>
                <a target="_blank" href="https://www.visa.com.hr/" rel="noreferrer"><img src={VisaIcon} alt="" height={24}/> </a>
                <a target="_blank" href="https://www.wspay.info/" rel="noreferrer"> <img src={AmericanExpressIcon} alt="" height={24}/> </a>
                <a target="_blank" href="https://www.diners.com.hr/" rel="noreferrer"> <DinersIcon /> </a>
                <a target="_blank" href="https://www.discover.com/" rel="noreferrer"> <DiscoverIcon /> </a>
              </div>
              <p>We use WSPay for online payments.</p>
              <div className={wspayIconContainerStyle}>
                <a target="_blank" href="https://www.wspay.info/" rel="noreferrer"> <WSPayIcon /> </a>
              </div>
              <hr className={separatorStyle} />
            </div>
          <MarexLogo fill={Colors.WINDOW_GRAY} className={logoStyle} />
          <p className={copyrightStyle}>© {new Date().getFullYear()} Marex Elektrostroj d.o.o.</p>
        </div>
      </Layout.Content>
    </footer>
  );
};

const containerStyle = css`
  width: 100%;
  background-color: ${Colors.LIGHT_GRAY};
  margin-top: auto;
  padding-bottom: 6rem;
`;

const contentContainerStyle = css`
  margin-top: 1rem;
`;


const cardIssuersContainerStyle = css`
  > p {
    ${p2Style};
    color: ${Colors.WINDOW_GRAY};
    margin-bottom: 1rem;
  }
`;

const cardIconstContainerStyle = css`
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

const wspayIconContainerStyle = css`
  display: flex;
`;

const listStyle = css`
  list-style-type: none;
`;

const listItemStyle = css`
  ${valueStyle}
  margin-bottom: 1rem;
  list-decoration: none;
  cursor: pointer;
`;

const separatorStyle = css`
  border: 1px solid ${Colors.WINDOW_GRAY};
  margin: 2rem 0;
`;

const logoStyle = css`
  margin-bottom: 1rem;
`;

const copyrightStyle = css`
  ${valueStyle}
  color: ${Colors.WINDOW_GRAY};
`;
