import { useNavigate } from 'react-router-dom';
import { appRoutes } from './app-routes';

type NavigatorMethods = {
  toPrevious(): void;
  toNext(): void;
  toLanding(): void;
  toCheckEmail(): void;
  toEmailSignIn(email?: string): void;
  toUsernameSignIn(username?: string): void;
  toSignUp(email?: string): void;
  toForgotPassword(): void;
  toPayment(): void;
  toScan(): void;
  toPedestal(pedestalIdentifier: string): void;
  toSession(sessionId: number): void;
  toVehicles(): void;
  toHistory(): void;
  toWallet(): void;
  toAccount(): void;
  toWalletAddFunds(): void;
  toWalletAddFundsToLocation(locationId: number): void;
  toWalletFundsStats(locationId: number): void;
  toLocation(locationId: number): void;
  toSuccessful(balance?: number): void;
  toLocations(): void;
  toPrivacyPolicy(): void;
  toTermsOfUse(): void;
  toTermsOfSale(): void;
  toReceiptDetails(receiptId: number): void;
  toPersonalInformation(): void;
  toChangeEmail(): void;
  toChangeUsername(): void;
  toPersonnelPage(): void;
  toVerifyEmailPage(): void;
  toPasswordPage(): void;
};

export const useNavigator = (): NavigatorMethods => {
  const navigate = useNavigate();

  const toPrevious = () => {
    navigate(-1);
  };

  const toNext = () => {
    navigate(1);
  };

  const toLanding = () => {
    navigate(appRoutes.landing);
  };

  const toCheckEmail = () => {
    navigate(appRoutes.checkEmail);
  };

  const toEmailSignIn = () => {
    navigate(appRoutes.emailSignIn);
  };

  const toUsernameSignIn = () => {
    navigate(appRoutes.usernameSignIn);
  };

  const toSignUp = () => {
    navigate(appRoutes.signUp);
  };

  const toForgotPassword = () => {
    navigate(appRoutes.forgotPassword);
  };

  const toPayment = () => {
    navigate(appRoutes.payment);
  };

  const toSuccessful = (balance?: number) => {
    navigate(appRoutes.success, { state: { balance: balance } });
  };

  const toScan = () => {
    navigate(appRoutes.scan);
  };

  const toWallet = () => {
    navigate(appRoutes.wallet);
  };

  const toWalletAddFunds = () => {
    navigate(appRoutes.walletAddFunds);
  };

  const toWalletAddFundsToLocation = (locationId: number) => {
    navigate(`${appRoutes.walletAddFunds}/${locationId}`);
  };

  const toWalletFundsStats = (locationId: number) => {
    navigate(`${appRoutes.walletFundsStats}/${locationId}`);
  };

  const toPedestal = (pedestalIdentifier: string) => {
    navigate(`${appRoutes.pedestal}/${pedestalIdentifier}`);
  };

  const toSession = (sessionId: number) => {
    navigate(`${appRoutes.session}/${sessionId}`);
  };

  const toHistory = () => {
    navigate(appRoutes.history);
  };

  const toVehicles = () => {
    navigate(appRoutes.vehicles);
  };

  const toAccount = () => {
    navigate(appRoutes.account);
  };

  const toLocation = (locationId: number) => {
    navigate(`${appRoutes.location}/${locationId}`);
  };

  const toLocations = () => {
    navigate(appRoutes.locations);
  };

  const toPrivacyPolicy = () => {
    navigate(appRoutes.privacyPolicy);
  };

  const toTermsOfUse = () => {
    navigate(appRoutes.termsOfUse);
  };

  const toTermsOfSale = () => {
    navigate(appRoutes.termsOfSale);
  };

  const toReceiptDetails = (receiptId: number) => {
    navigate(`${appRoutes.receipt}/${receiptId}`);
  };

  const toPersonalInformation = () => {
    navigate(appRoutes.personalInformation);
  };

  const toPersonnelPage = () => {
    navigate(appRoutes.personnelPage);
  };

  const toChangeEmail= () => {
    navigate(appRoutes.changeEmailPage);
  };

  const toChangeUsername = () => {
    navigate(appRoutes.changeUsernamePage);
  };

  const toVerifyEmailPage = () => {
    navigate(appRoutes.verifyEmailPage);
  };

  const toPasswordPage = () => {
    navigate(appRoutes.passwordPage);
  };

  return {
    toPrevious,
    toNext,
    toLanding,
    toCheckEmail,
    toEmailSignIn,
    toUsernameSignIn,
    toSignUp,
    toForgotPassword,
    toPayment,
    toSuccessful,
    toScan,
    toWallet,
    toAccount,
    toWalletAddFunds,
    toWalletAddFundsToLocation,
    toWalletFundsStats,
    toPedestal,
    toSession,
    toHistory,
    toVehicles,
    toLocation,
    toLocations,
    toPrivacyPolicy,
    toTermsOfUse,
    toTermsOfSale,
    toReceiptDetails,
    toPersonalInformation,
    toPersonnelPage,
    toChangeEmail,
    toChangeUsername,
    toVerifyEmailPage,
    toPasswordPage,
  };
};
